import type React from "react";
import { useState } from "react";

import {
	type DtoCustomFieldFormV01,
	type DtoParticipantGet,
	useCustomFieldContext,
} from "@key4-front-library/core";
import { Stack, Tab, Tabs } from "@mui/material";

import ParticipationForm from "./ParticipationForm";

const formKeyProfilAndRole = "profileRoles";

interface Props {
	participant: DtoParticipantGet;
	activeTabKey?: string;
}

const Participation = (props: Props) => {
	const { participant, activeTabKey } = props;

	const { customForms } = useCustomFieldContext();

	const displayedForms: Array<DtoCustomFieldFormV01> | undefined =
		customForms?.filter((form) => form.key !== formKeyProfilAndRole);
	const [currentTabKey, setCurrentTabKey] = useState<string | undefined>(
		activeTabKey ?? displayedForms?.[0]?.id,
	);

	const handleTabSectionChangeClick = (
		_: React.SyntheticEvent,
		key: string,
	) => {
		setCurrentTabKey(key);
	};

	if (displayedForms) {
		return (
			<Stack>
				<Stack direction={"row"} alignItems={"center"}>
					<Tabs
						variant="scrollable"
						scrollButtons="auto"
						role="tabpanel"
						aria-label={"tabs"}
						value={currentTabKey}
						onChange={handleTabSectionChangeClick}
					>
						{currentTabKey &&
							displayedForms.map((form) => {
								return (
									<Tab
										key={form.id}
										id={form.id}
										aria-controls={form.id}
										value={form.id}
										label={form.label}
									/>
								);
							})}
					</Tabs>
				</Stack>
				<Stack py={3} direction={"column"}>
					{currentTabKey &&
						displayedForms
							.filter((form) => form.id === currentTabKey)
							.map((form) => {
								const formOrder = customForms?.indexOf(form);
								return (
									formOrder !== undefined && (
										<ParticipationForm
											key={form.id}
											form={form}
											formOrder={formOrder}
											participant={participant}
										/>
									)
								);
							})}
				</Stack>
			</Stack>
		);
	}
};

export default Participation;
