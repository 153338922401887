import { t } from "i18next";

import { type DtoMailMessage, EmailStatusChip } from "@key4-front-library/core";
import {
	GetDateFromIsoString,
	ShortDateTimeInLocalTimeZone,
} from "@key4-front-library/core/Helpers";
import { alpha, Chip, Stack, Typography, useTheme } from "@mui/material";

import { ParticipantMailTarget } from "./ParticipantMailTarget";

interface Props {
	mail: DtoMailMessage;
}
export const ParticipantMailDetailHeader = (props: Props) => {
	const { mail } = props;

	const formatedSendingDate = ShortDateTimeInLocalTimeZone({
		date: GetDateFromIsoString(mail.sendingDate),
	});

	const theme = useTheme();

	return (
		<Stack width="100%" direction="row" justifyContent="space-between">
			<Stack height="100%" direction="row" spacing={2}>
				<Stack>
					<Stack direction={"row"} spacing={1}>
						<Typography variant={"caption"} fontWeight={900}>
							{t("old.common.emails.from")} :
						</Typography>
						<Typography variant={"caption"}>{`<${mail.from}>`}</Typography>
					</Stack>
					<Stack direction={"row"} spacing={1}>
						<Typography
							sx={{ minWidth: "fit-content" }}
							variant={"caption"}
							fontWeight={900}
						>
							{t("old.common.emails.to")} :
						</Typography>
						<Typography variant={"caption"}>{`<${mail.to}>`}</Typography>
					</Stack>
				</Stack>
			</Stack>

			<Stack direction="row" alignItems={"baseline"} spacing={1}>
				<ParticipantMailTarget target={mail.target} />
				<Chip
					sx={{
						backgroundColor: alpha(theme.palette.secondary.light, 0.3),
					}}
					label={
						<Typography variant={"caption"} color={"secondary.dark"}>
							{formatedSendingDate}
						</Typography>
					}
				/>
				<EmailStatusChip status={mail.status} />
			</Stack>
		</Stack>
	);
};
