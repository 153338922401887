import type { SimplePaletteColorOptions, TypeText } from "@mui/material";

export interface IApplicationColors {
	red: SimplePaletteColorOptions;
	orange: SimplePaletteColorOptions;
	blue: SimplePaletteColorOptions;
	blueLight: SimplePaletteColorOptions;
	darkBlue: SimplePaletteColorOptions;
	veryDarkBlue: SimplePaletteColorOptions;
	green: SimplePaletteColorOptions;
	grey: SimplePaletteColorOptions;
	darkGrey: SimplePaletteColorOptions;
	greyBlue: SimplePaletteColorOptions;
	greyBlueLight: SimplePaletteColorOptions;
	greyBlueVeryLight: SimplePaletteColorOptions;
	greyViolet: SimplePaletteColorOptions;
	greyStone: SimplePaletteColorOptions;
	greyStoneLight: SimplePaletteColorOptions;
	greyLight: SimplePaletteColorOptions;
	black: SimplePaletteColorOptions;
	blackLight: SimplePaletteColorOptions;
	white: SimplePaletteColorOptions;
	border: SimplePaletteColorOptions;
	transparent: SimplePaletteColorOptions;
	tone: string;
}

export const ApplicationColors: IApplicationColors = {
	red: {
		light: "#ff5751",
		main: "#FF2D26",
		dark: "#b21f1a",
		background: "#591A18",
		contrastText: "#FFEDEC",
	},
	orange: {
		light: "#ffaf47",
		main: "#FF9B19",
		dark: "#b26c11",
		background: "#594122",
		contrastText: "#FEF1E1",
	},
	blue: {
		light: "#57cfee",
		main: "#2EC3EA",
		dark: "#2088a3",
		background: "#17363F",
		contrastText: "#fff",
	},
	blueLight: {
		light: "#fff",
		main: "#e6e6ff",
		dark: "#b4b4cc",
		background: "#17363F",
		contrastText: "#000",
	},
	darkBlue: {
		light: "#432f95",
		main: "#000666",
		dark: "#00003b",
		background: "#17363F",
		contrastText: "#fff",
	},
	veryDarkBlue: {
		light: "#2a2d5d",
		main: "#000333",
		dark: "#000033",
		background: "#17363F",
		contrastText: "#fff",
	},
	green: {
		light: "#44de44",
		main: "#16D616",
		dark: "#0f950f",
		background: "#225022",
		contrastText: "#ddfedc",
	},
	grey: {
		light: "#93a6af",
		main: "#78909c",
		dark: "#54646d",
		background: "#000000DE",
		contrastText: "#fff",
	},
	darkGrey: {
		light: "#5F6167",
		main: "#4C4F55",
		dark: "#33373E",
		background: "#000000DE",
		contrastText: "#fff",
	},
	greyBlue: {
		light: "#888dae",
		main: "#5A607F",
		dark: "#2f3653",
		background: "#000000DE",
		contrastText: "#fff",
	},
	greyBlueLight: {
		light: "#b8c9dc",
		main: "#8898aa",
		dark: "#5b6a7b",
		background: "#000000DE",
		contrastText: "#fff",
	},
	greyBlueVeryLight: {
		light: "#ffffff",
		main: "#cdf4ff",
		dark: "#9bc1cc",
		background: "#000000DE",
		contrastText: "#000",
	},
	greyViolet: {
		light: "#8ba6fa",
		main: "#5877c7",
		dark: "#1e4c96",
		background: "#000000DE",
		contrastText: "#000",
	},
	greyStone: {
		light: "#8e8e8e",
		main: "#616161",
		dark: "#373737",
		background: "#000000DE",
		contrastText: "#EEE",
	},
	greyStoneLight: {
		light: "#bebebe",
		main: "#E8E8E8",
		dark: "#616161",
		background: "#000000DE",
		contrastText: "#000",
	},
	greyLight: {
		light: "#F6F6FF",
		main: "#E8E8F0",
		dark: "#C2C2CE",
		background: "#000000DE",
		contrastText: "#000000",
	},
	black: {
		light: "#2c2c2c",
		main: "#000000",
		dark: "#000000",
		background: "#000000DE",
		contrastText: "#ffffff",
	},
	blackLight: {
		light: "#373737",
		main: "#111111",
		dark: "#000000",
		background: "#000000DE",
		contrastText: "#ffffff",
	},
	white: {
		light: "#ffffff",
		main: "#ffffff",
		dark: "#cccccc",
		background: "#000000DE",
		contrastText: "#000000",
	},
	border: {
		light: "#ffffff",
		main: "#ffffff",
		dark: "#000000",
		background: "#000000DE",
		contrastText: "#000",
	},
	transparent: {
		light: "#00000000",
		main: "#00000000",
		dark: "#00000000",
		background: "#000000DE",
		contrastText: "#00000000",
	},
	tone: "#F5F5FF",
};
interface ITextColors {
	light: TypeText;
	dark: TypeText;
}

export const TextColors: ITextColors = {
	light: {
		primary: "#000333",
		secondary: "#757690",
		disabled: "#9E9FB1",
	},
	dark: {
		primary: "#FFFFFF",
		secondary: "#C1C1C1",
		disabled: "#989898",
	},
};
