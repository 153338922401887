import { t } from "i18next";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import ParticipantList from "@application/Components/participants/ParticipantList";
import ParticipantListCard from "@application/Components/participants/ParticipantListCard";
import { invitationStatus } from "@application/Configurations/status.configuration";
import ContainerModalParticipant from "@application/Containers/ContainerModalParticipant";
import ParticipantController from "@application/Controllers/ParticipantController";
import RoleController from "@application/Controllers/RoleController";
import SearchFilterController from "@application/Controllers/SearchFilterController";
import SnackbarHelper from "@application/Helpers/SnackbarHelper";
import { ErrorAPI } from "@domain/interfaces/error.interface";
import type { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import AppBox from "@infrastructure/components/interface/box/AppBox";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import { SearchList } from "@infrastructure/components/interface/search/SearchList";
import Title from "@infrastructure/components/interface/titles/Title";
import SearchListHelper from "@infrastructure/helpers/searchList.helper";
import useSearchList from "@infrastructure/hooks/searchList.hook";
import { getToken } from "@infrastructure/services/api/apiUtils";
import {
	type AdvancedSearchMailTemplateFetch,
	type AdvancedSearchMailTemplateSubstitute,
	customFieldParticipantsIgnoreScope,
	DebugConfig,
	DefaultRowsPerPageOptions,
	dtoDefinitionListToTAdvancedSearchFieldDefinitionList,
	type DtoParticipantGet,
	type DtoSearchFilterGet,
	type DtoSearchFilterPost,
	EBulkActionEntity,
	EnumRoleScope,
	EnumRoleType,
	EnumSearchFilterScope,
	ESelect,
	ESieveOperator,
	getAdvancedSearchMailTemplateSubstitute,
	getFormattedCustomFields,
	getListDtoMailTemplateGetToFields,
	getQueryBuilderDefinition,
	metaEnv,
	participantAuthorizedForeignKeys,
	participantDisabledKeys,
	PeriodContainer,
	QueryBuilderHelper,
	queryFilters,
	Services,
	substituteQueryString,
	useContextModule,
	useCustomFieldContext,
	usePaginationOld,
	useSnackBarHook,
} from "@key4-front-library/core";
import ButtonBulkAction from "@key4-front-library/core/Bo/Components/Button/ButtonBulkAction";
import DebugQueryFilter from "@key4-front-library/core/Bo/Components/DebugQueryFilter";
import { useBulkActionStore } from "@key4-front-library/core/Bo/Stores";
import {
	Grid,
	Stack,
	ToggleButton,
	ToggleButtonGroup,
	useTheme,
} from "@mui/material";
import type { GridSelectionModel, GridSortModel } from "@mui/x-data-grid-pro";

const debugQueryFilter = DebugConfig.QueryFilter;

const PageParticipants = () => {
	const DEFAULT_PAGE = 0;

	const {
		page,
		pageSize,
		totalRowCount,
		handleChangePage,
		handleChangePageSize,
		setTotalRowCount,
	} = usePaginationOld();
	const theme = useTheme();
	const { enqueueSnackbar } = useSnackbar();

	const navigate = useNavigate();

	const { client, event } = useContextModule();
	const { customForms } = useCustomFieldContext();
	const { sendError, sendSuccess } = useSnackBarHook();

	enum templateModeEnum {
		GRID = "grid",
		CARD = "card",
	}

	const defaultSortModel: GridSortModel = [];

	const [isLoading, setIsLoading] = useState(false);
	const [gridSortModelList, setGridSortModelList] =
		useState<GridSortModel>(defaultSortModel);
	const selectionModel: GridSelectionModel = [];

	const [isFieldsLoaded, setIsFieldsLoaded] = useState<boolean>(false);

	const [participantId, setParticipantId] = useState<string | undefined>();
	const [participants, setParticipants] = useState<Array<DtoParticipantGet>>(
		[],
	);
	const [apiReloading, setApiReloading] = useState<boolean>(false);
	const [isOpenParticipantModal, setIsOpenParticipantModal] = useState(false);

	const [templateMode, setTemplateMode] = useState<templateModeEnum>(
		templateModeEnum.GRID,
	);
	const [
		advancedSearchMailTemplateSubstitutes,
		setAdvancedSearchMailTemplateSubstitutes,
	] = useState<Array<AdvancedSearchMailTemplateSubstitute>>();

	const bulkActionStore = useBulkActionStore();

	const templateModes = [
		{
			value: templateModeEnum.GRID,
			icon: "table-list",
			prefix: "far",
		},
		{
			value: templateModeEnum.CARD,
			icon: "grid",
			prefix: "fal",
		},
	];

	const {
		searchListTranslation,
		search,
		fields,
		setFields,
		queryBuilderString,
		handleSearchChange,
		handleQueryBuilderChange,
		openSaveNewQueryModale,
		setOpenSaveNewQueryModale,
		newQueryFilterId,
		setNewQueryFilterId,
		handleOpenSaveNewQueryModaleClick,
		searchFilterQueriesList,
		setSearchFilterQueriesList,
	} = useSearchList();

	const translations = {
		title: t("old.registration.participants.index.title"),
		buttons: {
			add: t("old.form.buttons.add"),
		},
		mode: {
			title: t("old.registration.participants.template-mode.title"),
		},
		searchList: {
			...SearchListHelper.getTranslationsSearchList(),
		},
	};

	const getParticipants = useCallback(
		async (isReload = false) => {
			const sorts: Array<string> = [];
			gridSortModelList.forEach((criteria) => {
				sorts.push(
					[criteria.sort === "asc" ? "" : "-", criteria.field].join(""),
				);
				return true;
			});
			const response = await ParticipantController.getList(
				client.id,
				event.id,
				search,
				sorts ?? [],
				{ page, pageSize },
				substituteQueryString(
					queryBuilderString,
					advancedSearchMailTemplateSubstitutes,
				),
				true,
			);
			const { data, pagination } = response;
			setTotalRowCount(pagination.totalCount ?? 0);
			setParticipants(data);
			setIsLoading(false);
			isReload && setApiReloading(false);
		},
		[event, page, pageSize, gridSortModelList, search, queryBuilderString],
	);

	const getSearchFilterQueriesList = useCallback(
		async (clientId: string, eventId: string) => {
			const rolesScopeFilter = [
				"scope",
				ESieveOperator.CI_EQUALS,
				"participant",
			].join("");
			const rolesTypeFilter = ["type", ESieveOperator.CI_EQUALS, "query"].join(
				"",
			);
			const rolesFilters = [rolesScopeFilter, rolesTypeFilter].join(
				ESieveOperator.AND,
			);
			const eventRolesQueries = await RoleController.getList(
				clientId,
				eventId,
				rolesFilters,
			);

			const searchFilterQueriesList = await SearchFilterController.getList(
				clientId,
				eventId,
			);
			setNewQueryFilterId(undefined);
			setSearchFilterQueriesList([
				...[
					{
						id: "",
						scope: EnumSearchFilterScope.PARTICIPANT,
						name: translations.searchList.searchFilter.autoComplete
							.newQueryOption,
						value: "",
					},
				],
				...searchFilterQueriesList.map((query) => {
					return {
						...query,
						isRoleQuery: !!eventRolesQueries.find(
							(role) => role.name === query.name,
						),
					};
					// TODO: K4REG-708 - Mécanique basée sur le matching des noms de requête et de rôle en attendant une mécanique back
				}),
			]);
		},
		[
			translations.searchList.searchFilter.autoComplete.newQueryOption,
			setNewQueryFilterId,
			setSearchFilterQueriesList,
		],
	);

	useEffect(() => {
		getSearchFilterQueriesList(client.id, event.id);
	}, [event, getSearchFilterQueriesList]);

	useEffect(() => {
		setIsLoading(true);
		getParticipants();
	}, [
		event,
		page,
		pageSize,
		gridSortModelList,
		search,
		queryBuilderString,
		getParticipants,
	]);

	useEffect(() => {
		const fetchData = async (): Promise<AdvancedSearchMailTemplateFetch> => {
			const definition = await SearchFilterController.getListDefinitions();
			const { data: mails } =
				await Services.Operations.MailTemplatesService.getListPagined(
					client.id,
					event.id,
					queryFilters(`document.target${ESieveOperator.EQUALS}participant`),
				);
			return {
				queryBuilder: getQueryBuilderDefinition({
					definition:
						dtoDefinitionListToTAdvancedSearchFieldDefinitionList(definition),
					fieldName: "Participant",
					authorizedForeignKeys: participantAuthorizedForeignKeys,
					disabledKeys: participantDisabledKeys,
					selectList: [
						{
							resource: ESelect.INVITATION_STATUS,
							listValues: invitationStatus.map((status) => ({
								value: status.value,
								title: t(status.label).toString(),
							})),
						},
					],
					apiUrl: metaEnv.baseUrl,
					token: getToken(),
				}),
				mails,
			};
		};

		fetchData()
			.then(async (response) => {
				setAdvancedSearchMailTemplateSubstitutes(
					getAdvancedSearchMailTemplateSubstitute(response.mails),
				);
				setFields({
					...QueryBuilderHelper.getTranslatedFields({
						...getListDtoMailTemplateGetToFields(response.mails, t),
						...response.queryBuilder,
					}),
				});
			})
			.catch(() => {
				console.error("fetchData getQueryBuilderDefinition ERROR");
			});
	}, [event]);

	useEffect(() => {
		if (!isFieldsLoaded && fields) {
			setFields({
				...fields,
				...getMappedCustomFields,
			});
			setIsFieldsLoaded(true);
		}
	}, [fields, isFieldsLoaded]);

	useEffect(() => {
		handleChangePage(DEFAULT_PAGE);
	}, [search]);

	const handleChangeSortCriteria = (gridSortModelList: GridSortModel) => {
		setGridSortModelList(gridSortModelList);
	};

	const handleTemplateModeChange = (
		_: React.MouseEvent<HTMLElement>,
		newTemplateMode: templateModeEnum,
	) => {
		if (newTemplateMode != null) {
			setTemplateMode(newTemplateMode);
		}
	};

	const handleCreateQuerySearchFilterClick = async (
		titleQuery: string,
		isRoleQuery: boolean,
		query: string,
	) => {
		const messageSuccess = t(
			translations.searchList.searchFilter.snackbar.createSuccess,
		);
		const messageCreateEmpty = t(
			translations.searchList.searchFilter.snackbar.createEmpty,
		);

		if (!query.length) {
			sendError(messageCreateEmpty);
			return;
		}

		let searchQueryFilterId: string | undefined;

		const callbackCreateSearchFilter = (searchQueryFilterId?: string) => {
			getSearchFilterQueriesList(client.id, event.id);
			setOpenSaveNewQueryModale(false);
			setNewQueryFilterId(searchQueryFilterId);
		};

		await SearchFilterController.create(client.id, event.id, {
			name: titleQuery,
			scope: EnumSearchFilterScope.PARTICIPANT,
			value: query,
		} as DtoSearchFilterPost)
			.then(async (searchFilterQueryCreationResponse) => {
				try {
					searchQueryFilterId = searchFilterQueryCreationResponse.id;

					if (!isRoleQuery) {
						sendSuccess(messageSuccess);
					} else {
						await RoleController.create(client.id, event.id, {
							id: "",
							color: null,
							icon: "file-lines",
							name: titleQuery,
							query: substituteQueryString(
								queryBuilderString,
								advancedSearchMailTemplateSubstitutes,
							),
							scope: EnumRoleScope.PARTICIPANT,
							type: EnumRoleType.QUERY,
						})
							.then(async () => {
								sendSuccess(messageSuccess);
							})
							.catch((responseError) => {
								if (responseError.message?.length) {
									sendError(responseError.message[0].technicalMessage);
								} else {
									sendError(responseError);
								}
							})
							.finally(() => {
								callbackCreateSearchFilter(searchQueryFilterId);
							});
					}
				} catch (error) {
					if (typeof error === "string") {
						sendError(error);
					}
				} finally {
					callbackCreateSearchFilter(searchQueryFilterId);
				}
			})
			.catch((error) => {
				if (error.message?.length) {
					sendError(error.message[0].technicalMessage);
				} else {
					sendError(error);
				}
			})
			.finally(() => {
				callbackCreateSearchFilter(searchQueryFilterId);
			});
	};

	let timer: NodeJS.Timeout;
	const delay = 200;
	let prevent = false;

	const doRowClickAction = async (_participant: DtoParticipantGet) => {
		navigate(_participant.id);
	};

	const handleRowClick = (_participant: DtoParticipantGet) => {
		timer = setTimeout(() => {
			if (!prevent) {
				doRowClickAction(_participant);
			}
			prevent = false;
		}, delay);
	};

	const handleRowDoubleClick = (participant: DtoParticipantGet) => {
		clearTimeout(timer);
		prevent = true;
		setParticipantId(participant.id);
		setIsOpenParticipantModal(true);
	};

	const handleUpdateQueryFilterClick = async (
		querySearchFilter: DtoSearchFilterGet,
	) => {
		await SearchFilterController.update(
			client.id,
			event.id,
			querySearchFilter.id,
			querySearchFilter,
		).then(async (response: boolean | ErrorAPI) => {
			if (response instanceof ErrorAPI) {
				enqueueSnackbar(response.message, SnackbarHelper.error);
			}
			if (response) {
				enqueueSnackbar(
					translations.searchList.searchFilter.snackbar.modifySuccess,
					SnackbarHelper.success,
				);
			}
			await getSearchFilterQueriesList(client.id, event.id);
		});
	};

	const getMappedCustomFields = useMemo(
		() =>
			getFormattedCustomFields(
				customForms,
				fields ?? {},
				customFieldParticipantsIgnoreScope,
				{
					definition: [],
					fieldName: "",
					disabledKeys: participantDisabledKeys,
					authorizedForeignKeys: [],
				},
			),
		[customForms, fields],
	);

	const handleDeleteQueryFilterClick = async (filterQueryId: string) => {
		await SearchFilterController.deleteEntity(
			client.id,
			event.id,
			filterQueryId,
		).then(async (response: boolean | ErrorAPI) => {
			if (response instanceof ErrorAPI) {
				enqueueSnackbar(response.message, SnackbarHelper.error);
			}

			if (response) {
				enqueueSnackbar(
					translations.searchList.searchFilter.snackbar.deleteSuccess,
					SnackbarHelper.success,
				);
			}
			await getSearchFilterQueriesList(client.id, event.id);
		});
	};

	const handleParticipantSubmit = async () => {
		await getParticipants();
	};

	const mainContentRendering = () => {
		switch (templateMode) {
			case templateModeEnum.GRID:
				return (
					<Stack spacing={1}>
						<ParticipantList
							isLoading={isLoading}
							isReloading={apiReloading}
							page={page}
							pageSize={pageSize}
							totalRowCount={totalRowCount}
							handleChangePage={handleChangePage}
							handleChangePageSize={handleChangePageSize}
							handleChangeSortCriteria={handleChangeSortCriteria}
							handleRowClick={handleRowClick}
							handleRowDoubleClick={handleRowDoubleClick}
							sorts={gridSortModelList}
							rowsPerPageOptions={DefaultRowsPerPageOptions}
							selection={selectionModel}
							participants={participants}
						/>
					</Stack>
				);
			case templateModeEnum.CARD:
				return (
					<Stack>
						<ParticipantListCard
							isLoading={isLoading}
							page={page}
							pageSize={pageSize}
							totalRowCount={totalRowCount}
							handleChangePage={handleChangePage}
							handleChangePageSize={handleChangePageSize}
							rowsPerPageOptions={DefaultRowsPerPageOptions}
							handleParticipantClick={handleRowClick}
							handleParticipantDoubleClick={handleRowDoubleClick}
							participants={participants}
						/>
					</Stack>
				);
			default:
				return <></>;
		}
	};

	return (
		<>
			<Title
				title={translations.title}
				buttons={[
					{
						label: translations.buttons.add,
						startIcon: "fa-plus",
						handleClick: () => {
							setParticipantId(undefined);
							setIsOpenParticipantModal(true);
						},
					},
				]}
				reference={
					<PeriodContainer
						dateStartIso={event.startDate}
						dateEndIso={event.endDate}
					/>
				}
			/>
			<Grid item xs={12}>
				<AppBox>
					<Stack>
						<Stack py="1rem">
							{debugQueryFilter && (
								<DebugQueryFilter
									queryBuilderString={substituteQueryString(
										queryBuilderString,
										advancedSearchMailTemplateSubstitutes,
									)}
									onChangeQueryBuilder={handleQueryBuilderChange}
								/>
							)}

							<Stack>
								{fields && searchFilterQueriesList && (
									<SearchList
										onSearchChange={handleSearchChange}
										translation={searchListTranslation}
										onQueryBuilderChange={handleQueryBuilderChange}
										fields={fields}
										searchFilterQueriesList={searchFilterQueriesList}
										openSaveNewQueryModale={openSaveNewQueryModale}
										onOpenSaveNewQueryModaleClick={
											handleOpenSaveNewQueryModaleClick
										}
										newQueryFilterId={newQueryFilterId ?? null}
										onCreateQuerySearchFilterClick={
											handleCreateQuerySearchFilterClick
										}
										onUpdateQueryFilterClick={handleUpdateQueryFilterClick}
										onDeleteQueryFilterClick={handleDeleteQueryFilterClick}
									/>
								)}
							</Stack>
						</Stack>

						<Grid py="1rem" container item justifyContent={"space-between"}>
							<ButtonBulkAction
								isDisabled={totalRowCount == 0}
								onClick={() => {
									localStorage.removeItem("bulkActionStore");
									bulkActionStore.init(EBulkActionEntity.PARTICIPANT, {
										search,
										queryBuilderString: substituteQueryString(
											queryBuilderString,
											advancedSearchMailTemplateSubstitutes,
										),
									});
									bulkActionStore.setActiveStep(1);
									navigate(`../${EBulkActionEntity.PARTICIPANT}/bulkaction/1`);
								}}
							/>

							<ToggleButtonGroup
								value={templateMode}
								exclusive
								onChange={handleTemplateModeChange}
								aria-label="Change template mode"
							>
								{templateModes.map((item, key) => {
									return (
										<ToggleButton
											key={key}
											sx={{
												backgroundColor:
													templateMode === item.value
														? `${theme.palette.primary.main} !important`
														: "",
												paddingTop: 0,
												paddingBottom: 0,
											}}
											value={item.value}
										>
											<Icon
												color={
													templateMode === item.value
														? `${theme.palette.common.white} !important`
														: ""
												}
												prefix={item.prefix as IconPrefix}
												iconName={item.icon as IconName}
											/>
										</ToggleButton>
									);
								})}
							</ToggleButtonGroup>
						</Grid>
						<Stack>{mainContentRendering()}</Stack>
					</Stack>
				</AppBox>
			</Grid>

			<ContainerModalParticipant
				participantId={participantId}
				isOpen={isOpenParticipantModal}
				changeIsOpen={setIsOpenParticipantModal}
				callbackSubmit={handleParticipantSubmit}
			/>
		</>
	);
};

export default PageParticipants;
