export enum EnumParticipantFormKey {
	PROFILE_ROLES = "profileRoles",
	// TODO : Renommer la clef participation dans le frontOffice en "invitation" --> Tâche K4REG-732
	INVITATION = "participation",
	GENERAL = "general",
}

export enum EnumParticipantSectionKey {
	MAIN = "main",
}

export enum EnumParticipantFieldKey {
	EMAIL = "email",
	FIRSTNAME = "firstname",
	INVITATION_STATUS = "invitationStatus",
	LASTNAME = "lastname",
	CONTACT_CONTAINER = "contactContainer", // contains 'email', 'firstname', 'lastname'
	PROFILE_ID = "profileId",
	ROLES_IDS = "rolesIds",
	PARTICIPANT_OR_CONTACT = "participantOrContact",
}
