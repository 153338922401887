import "@application/Configurations/icons.configuration";

import { Settings } from "luxon";

import HelpConfiguration from "@application/Configurations/help.configuration";
import { routes } from "@application/routes/Router";
import { Licence } from "@key4-front-library/core";
import Help from "@key4-front-library/core/Bo/Layout/components/Help/Help";
import Router from "@key4-front-library/core/Bo/Layout/Router";
import { DEFAULT_TIMEZONE } from "@key4-front-library/core/Constants/Date";
import DevToolsHelpers from "@key4-front-library/core/DevToolsHelpers";
import { LicenseInfo } from "@mui/x-license-pro";

import { version } from "../package.json";

LicenseInfo.setLicenseKey(Licence.mui);

const App = () => {
	// TODO: Remove when using the events' timezones
	Settings.defaultZone = DEFAULT_TIMEZONE;

	return (
		<>
			<DevToolsHelpers.VersionDevTools version={version} />
			<>
				<Router routes={routes} />
				<Help routes={routes} configuration={HelpConfiguration} />
			</>
		</>
	);
};

export default App;
