import {
	type DtoMailMessage,
	Services,
	type TypeApiQueryString,
	type TypeApiResponsePagined,
} from "@key4-front-library/core";

const getEmails = async (
	clientId: string,
	eventId: string,
	participantId: string,
	queryStrings?: Array<TypeApiQueryString>,
): Promise<TypeApiResponsePagined<Array<DtoMailMessage>>> => {
	return await Services.Events.ParticipantsService.getMailMessages(
		clientId,
		eventId,
		participantId,
		queryStrings,
	);
};

const EmailController = {
	getEmails,
};

export default EmailController;
