export interface IErrorAPI {
	status: EApiErrorStatus;
	message: any;
}

export enum EApiErrorStatus {
	E400 = 400,
	E404 = 404,
	E500 = 500,
}

export class ErrorAPI implements IErrorAPI {
	status: EApiErrorStatus;
	message: any;

	constructor(status: EApiErrorStatus, message: any) {
		this.status = status;
		this.message = message;
	}
}
