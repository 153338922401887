import { User } from "oidc-client-ts";

import { AuthenticationConfig } from "@key4-front-library/core";

const getToken = (): string => {
	const autority = AuthenticationConfig.authority;
	const clientId = AuthenticationConfig.client_id;
	const storageKey = `oidc.user:${autority}:${clientId}`;
	const oidcStorageData = sessionStorage.getItem(storageKey);
	if (!oidcStorageData) {
		return "";
	}
	const user = User.fromStorageString(oidcStorageData);
	return user.access_token;
};

export { getToken };
