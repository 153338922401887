import { Services } from "@key4-front-library/core";
import type {
	DtoBenefit,
	DtoBenefitGet,
	DtoBenefitParticipantMatrix,
	DtoReOrder,
} from "@key4-front-library/core/Dto";
import type {
	TypeApiQueryString,
	TypeApiResponsePagined,
} from "@key4-front-library/core/Types";

/**
 * Get Benefit by ID
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param id Current benefit ID
 * @returns Benefit
 */
const read = async (
	clientId: string,
	eventId: string,
	id: string,
): Promise<DtoBenefitGet> =>
	await Services.Events.Registration.BenefitsService.get(clientId, eventId, id);

/**
 * Get List Pagined of Benefits
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param queryStrings QueryStrings
 * @typedef TypeApiQueryString Type of QueryString
 * @returns List of Benefit
 */
const readList = async (
	clientId: string,
	eventId: string,
	queryStrings: Array<TypeApiQueryString> = [],
): Promise<TypeApiResponsePagined<Array<DtoBenefitGet>>> =>
	await Services.Events.Registration.BenefitsService.getListPagined(
		clientId,
		eventId,
		queryStrings,
	);

/**
 * Create new Benefit
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param benefit Benefit to create
 * @typedef DtoBenefit Dto of Benefit for writing
 * @returns ID of Benefit created
 * @typedef TypeApiResponsePost Type of Post API Response
 */
const create = async (clientId: string, eventId: string, benefit: DtoBenefit) =>
	await Services.Events.Registration.BenefitsService.post(
		clientId,
		eventId,
		benefit,
	);

/**
 * Update a Benefit
 * @param clientId Current client ID
 * @param eventId ID of event
 * @param id Current Benefit ID
 * @param benefit Benefit to update
 * @typedef DtoBenefit Dto of Benefit for writing
 * @returns Success boolean
 */
const update = async (
	clientId: string,
	eventId: string,
	id: string,
	benefit: DtoBenefit,
) =>
	await Services.Events.Registration.BenefitsService.put(
		clientId,
		eventId,
		id,
		benefit,
	);

/**
 * Delete a Benefit
 * @param clientId Current client ID
 * @param eventId  Current event ID
 * @param id Current Benefit ID
 * @returns Success boolean
 */
const deleteEntity = async (clientId: string, eventId: string, id: string) =>
	await Services.Events.Registration.BenefitsService.deleteEntity(
		clientId,
		eventId,
		id,
	);

/**
 * Update Benefits Order
 * @param clientId Current client Id
 * @param eventId Current Event Id
 * @param ids List sorted of Benefits's ID
 * @returns Success boolean
 */
const updateReOrder = async (
	clientId: string,
	eventId: string,
	body: DtoReOrder,
) =>
	await Services.Events.Registration.BenefitsService.putReOrder(
		clientId,
		eventId,
		body,
	);

/**
 * Get the Privileges of a Participant
 * @param clientId Current client Id
 * @param eventId Current Event Id
 * @param participantId Current Participant ID
 * @typedef DtoBenefitParticipantMatrix Dto of Participant matrix privileges
 * @returns List of privileges
 */
const readParticipantPrivilegesMatrix = async (
	clientId: string,
	eventId: string,
	participantId: string,
): Promise<DtoBenefitParticipantMatrix> =>
	await Services.Events.Registration.BenefitsService.getParticipantPrivilegesMatrix(
		clientId,
		eventId,
		participantId,
	);

/**
 * Benefit Controller
 */
const BenefitsController = {
	read,
	readList,
	create,
	update,
	deleteEntity,
	updateReOrder,
	readParticipantPrivilegesMatrix,
};

export default BenefitsController;
