import { useState } from "react";

import ParticipantDetailsPrivileges from "@application/Components/participants/details/tabsView/ParticipantDetailsPrivileges";
import { Box, Tab, Tabs } from "@mui/material";

import { TabParticipantMails } from "./mails/TabParticipantMails";
import ParticipantDetailInvitations from "./ParticipantsDetailInvitations";

interface Props {
	participantId: string;
	translations: any;
}

const TabsView = (props: Props) => {
	const { participantId, translations } = props;
	const [tabIndex, setTabIndex] = useState(0);

	interface TabPanelProps {
		children?: React.ReactNode;
		index: number;
		value: number;
	}

	const a11yProps = (index: number) => {
		return {
			id: `simple-tab-${index}`,
			"aria-controls": `simple-tabpanel-${index}`,
		};
	};

	const TabPanel = (props: TabPanelProps) => {
		const { children, value, index, ...other } = props;
		return (
			<Box
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && <Box sx={{ mt: 3 }}>{children}</Box>}
			</Box>
		);
	};

	const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
		setTabIndex(newValue);
	};

	return (
		<>
			<Tabs value={tabIndex} onChange={handleChangeTab}>
				<Tab
					label={translations.tabs2.mails.label.toUpperCase()}
					{...a11yProps(0)}
				/>
				<Tab
					label={translations.tabs2.privileges.label.toUpperCase()}
					{...a11yProps(1)}
				/>
				<Tab
					label={translations.tabs2.faculty.label.toUpperCase()}
					{...a11yProps(2)}
				/>
			</Tabs>
			<TabPanel value={tabIndex} index={0}>
				<TabParticipantMails />
			</TabPanel>
			<TabPanel value={tabIndex} index={1}>
				<ParticipantDetailsPrivileges
					participantId={participantId}
					translations={translations}
				/>
			</TabPanel>
			<TabPanel value={tabIndex} index={2}>
				<ParticipantDetailInvitations participantId={participantId} />
			</TabPanel>
		</>
	);
};

export default TabsView;
