import { t } from "i18next";
import { uniqueId } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

import K4SplitButtonExportDecorated from "@application/Components/button/K4SplitButtonExportDecorated";
import MatrixSkeleton from "@application/Components/privileges/MatrixSkeleton";
import CostCenterController from "@application/Controllers/CostCenterController";
import ParticipantController from "@application/Controllers/ParticipantController";
import AppBox from "@infrastructure/components/interface/box/AppBox";
import K4Matrix from "@infrastructure/components/interface/matrix/K4Matrix";
import K4MatrixCustomCellListOfCosts from "@infrastructure/components/interface/matrix/K4MatrixCustomCellListOfCosts";
import {
	type DtoCostCentersMatrix,
	NoData,
	Services,
	useContextModule,
} from "@key4-front-library/core";
import { Alert, Box, Stack, Typography } from "@mui/material";
import type {
	GridAlignment,
	GridColumns,
	GridRenderCellParams,
	GridRowsProp,
} from "@mui/x-data-grid-pro";

const GetTranslations = () => {
	const translations = {
		costCentersMatrix: {
			label: t("old.registration.privileges.costCenter.label"),
			privilegeCurrentCosts: {
				label: t(
					"old.registration.privileges.costCentersMatrix.privilegeCurrentCosts",
				),
			},
			privilegePotentialCosts: {
				label: t(
					"old.registration.privileges.costCentersMatrix.privilegePotentialCosts",
				),
			},
		},
		buttons: {
			privileged: t("old.registration.privileges.buttons.privileged"),
			analytics: t("old.registration.privileges.tabs.analytics"),
			globalByCostCenter: t(
				"old.registration.privileges.buttons.globalByCostCenter",
			),
			detailedByParticipant: t(
				"old.registration.privileges.buttons.detailedByParticipant",
			),
		},
		emptyMatrix: {
			totalCost: t("old.registration.privileges.emptyMatrix.totalCost"),
			warningAlert: t("old.registration.privileges.emptyMatrix.warningAlert"),
		},
	};
	return translations;
};

const PagePrivilegesAnalytics = () => {
	const translations = GetTranslations();
	const { client, event } = useContextModule();
	const { enqueueSnackbar } = useSnackbar();

	const [costCenterMatrixRows, setCostCenterMatrixRows] = useState<
		GridRowsProp | undefined
	>();
	const [costCenterMatrixColumns, setCostCenterMatrixColumns] =
		useState<Array<any>>();
	const [isRefreshCostCentersMatrix, setIsRefreshCostCentersMatrix] =
		useState(true);
	const [isLoading, setIsLoading] = useState(false);

	const defaultStyleColumnsParams = {
		flex: 1,
		disableReorder: true,
		headerAlign: "center" as GridAlignment,
		minWidth: 200,
	};

	useEffect(() => {
		const fetchCostCentersMatrixAndFormatData = async () => {
			if (isRefreshCostCentersMatrix) {
				setIsRefreshCostCentersMatrix(false);
				setIsLoading(true);
				const res =
					await Services.Events.Registration.CostCentersService.getMatrix(
						client.id,
						event.id,
					).finally(() => {
						setIsLoading(false);
					});

				res.forEach((result) => {
					if (!result.id) {
						result.id = uniqueId();
					}
				});

				const customCellPrivilegeCurrentCosts = {
					renderCell(params: GridRenderCellParams) {
						const costCenterData: DtoCostCentersMatrix | undefined = res.find(
							(el: DtoCostCentersMatrix) => el.id === params.id,
						);
						const costCenterKey = costCenterData?.privilegeCurrentCosts;

						return (
							<Box sx={{ overflow: "hidden" }}>
								<K4MatrixCustomCellListOfCosts
									totalCosts={costCenterData?.totalCurrentCosts ?? 0}
									costCenterKey={costCenterKey}
								/>
							</Box>
						);
					},
				};
				const customCellPrivilegePotentialCosts = {
					renderCell(params: GridRenderCellParams) {
						const costCenterData: DtoCostCentersMatrix | undefined = res.find(
							(el: DtoCostCentersMatrix) => el.id === params.id,
						);
						const costCenterKey = costCenterData?.privilegePotentialCosts;
						return (
							<Box sx={{ overflow: "hidden" }}>
								<K4MatrixCustomCellListOfCosts
									totalCosts={costCenterData?.totalPotentialCosts ?? 0}
									costCenterKey={costCenterKey}
								/>
							</Box>
						);
					},
				};
				const formatedColumns = [
					{
						...defaultStyleColumnsParams,
						...customCellPrivilegeCurrentCosts,
						field: "privilegeCurrentCosts",
						headerName:
							translations.costCentersMatrix.privilegeCurrentCosts.label,
						align: "right",
						sortable: false,
					},
					{
						...defaultStyleColumnsParams,
						...customCellPrivilegePotentialCosts,
						field: "privilegePotentialCosts",
						headerName:
							translations.costCentersMatrix.privilegePotentialCosts.label,
						align: "right",
						sortable: false,
						cellClassName: "column-background-light",
					},
				];

				const pinnedToLeftColumns: GridColumns = [
					{
						field: "costCenter",
						width: 300,
						headerName: translations.costCentersMatrix.label,
						headerAlign: "center",
						cellClassName: "column-background-dark",
					},
				];

				const formatedRows = res.map((el: DtoCostCentersMatrix) => {
					return {
						id: el.id ?? uniqueId(),
						costCenter: el.name,
					};
				});
				setCostCenterMatrixRows(formatedRows);
				setCostCenterMatrixColumns([
					...pinnedToLeftColumns,
					...formatedColumns,
				]);
			}
		};

		fetchCostCentersMatrixAndFormatData();
	}, [event, isRefreshCostCentersMatrix]);

	const handleExportPrivileged = () => {
		ParticipantController.getExport(
			client.id,
			event.id,
			event.key,
			translations.buttons.detailedByParticipant,
		).catch((error) => {
			enqueueSnackbar(error.message, { variant: "error" });
		});
	};

	const handleExportAnalytics = () => {
		CostCenterController.getExport(
			client.id,
			event.id,
			event.key,
			translations.buttons.globalByCostCenter,
		).catch((error) => {
			enqueueSnackbar(error.message, { variant: "error" });
		});
	};

	if (isLoading) {
		return <MatrixSkeleton />;
	}
	if (!costCenterMatrixRows || !costCenterMatrixColumns) {
		return <NoData />;
	}

	return (
		<>
			<AppBox>
				<Stack spacing={2}>
					<Stack direction={"row"} spacing={2}></Stack>
					{(!costCenterMatrixRows || costCenterMatrixRows.length === 0) && (
						<Stack my={2}>
							<Typography variant="h5">
								{translations.emptyMatrix.totalCost} 0€
							</Typography>
							<Stack mt={2}>
								<Alert severity="warning">
									{translations.emptyMatrix.warningAlert}
								</Alert>
							</Stack>
						</Stack>
					)}
					<K4Matrix
						rows={costCenterMatrixRows}
						columns={costCenterMatrixColumns}
					/>
				</Stack>
			</AppBox>
			<Stack mt={2} direction={"row"}>
				<K4SplitButtonExportDecorated
					items={[
						{
							id: "global_by_cost_center",
							label: translations.buttons.globalByCostCenter,
							onClick: handleExportAnalytics,
						},
						{
							id: "detailed_by_participant",
							label: translations.buttons.detailedByParticipant,
							onClick: handleExportPrivileged,
						},
					]}
				/>
			</Stack>
		</>
	);
};

export default PagePrivilegesAnalytics;
