import {
	EMenuPath,
	ExternalRouting,
	type IMenu,
} from "@key4-front-library/core";

const tNameSpace = "old.registration.navigation";

const Menu: IMenu = {
	items: [
		{
			translation: [tNameSpace, EMenuPath.DASHBOARD].join("."),
			path: EMenuPath.DASHBOARD,
			icon: {
				prefix: "far",
				iconName: "chart-line-up",
			},
		},
		{
			translation: [tNameSpace, EMenuPath.PARTICIPANTS].join("."),
			path: EMenuPath.PARTICIPANTS,
			icon: {
				prefix: "far",
				iconName: "user",
			},
		},
		{
			translation: [tNameSpace, EMenuPath.PRIVILEGES].join("."),
			path: EMenuPath.PRIVILEGES,
			icon: {
				prefix: "far",
				iconName: "gift",
			},
		},
		{
			translation: [tNameSpace, EMenuPath.SETTINGS].join("."),
			path: EMenuPath.SETTINGS,
			icon: {
				prefix: "far",
				iconName: "gear",
			},
		},
		{
			translation: [tNameSpace, EMenuPath.USERS].join("."),
			path: EMenuPath.USERS,
			icon: {
				prefix: "far",
				iconName: "users",
			},
		},
		{ type: "divider" },
		{
			translation: [tNameSpace, EMenuPath.SUPPORT].join("."),
			path: EMenuPath.SUPPORT,
			icon: {
				prefix: "far",
				iconName: "headset",
			},
			externalLink: ExternalRouting.Support,
			target: "_blank",
		},
		{ type: "divider" },
	],
};

export default Menu;
