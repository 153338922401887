import { t } from "i18next";
import { useEffect, useState } from "react";

import {
	ButtonCancel,
	ButtonSave,
} from "@infrastructure/components/interface/uikit/K4Button";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import { getDataCypressID } from "@key4-front-library/core";
import {
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Stack,
	TextField,
} from "@mui/material";

interface Props {
	isOpen: boolean;
	onCloseClick: () => void;
	onSubmitClick: (titleQuery: string, isRoleQuery: boolean) => void;
	translation: any;
}

const SaveNewQueryModal = (props: Props & dataCypressProp) => {
	const {
		isOpen = false,
		onCloseClick,
		onSubmitClick,
		translation,
		dataCypressID,
	} = props;

	const translations = {
		buttons: {
			save: t("old.form.buttons.save"),
			cancel: t("old.form.buttons.cancel"),
		},
		checkbox: {
			saveAsRoleQuery: t("old.form.checkbox.save-as-role-query"),
		},
	};

	useEffect(() => {
		if (!isOpen) {
			setTitleQuery(undefined);
		}
	}, [isOpen]);

	const [titleQuery, setTitleQuery] = useState<string>();
	const [isRoleQuery, setIsRoleQuery] = useState<boolean>(false);

	return (
		<Dialog open={isOpen} maxWidth={"md"} fullWidth>
			<DialogTitle
				data-cy={dataCypressID ? `${dataCypressID}-title` : undefined}
			>
				{translation.searchFilter.modalNewQuery.title}
			</DialogTitle>
			<DialogContent>
				<Stack mt={".5em"}>
					<TextField
						fullWidth
						error={titleQuery === ""}
						id="outlined-error-helper-text"
						defaultValue={titleQuery}
						helperText={
							titleQuery === ""
								? translation.searchFilter.modalNewQuery.emptyField
								: ""
						}
						placeholder={translation.searchFilter.modalNewQuery.placeholder}
						onChange={(event) => {
							setTitleQuery(event.target.value);
						}}
						inputProps={{
							"data-cy": dataCypressID
								? `${dataCypressID}-query_name_field`
								: undefined,
						}}
					/>
				</Stack>
			</DialogContent>
			<DialogActions
				sx={{
					padding: "24px",
				}}
			>
				<Stack
					direction={"row"}
					spacing=".8rem"
					width="100%"
					justifyContent="space-between"
				>
					<FormControlLabel
						control={
							<Checkbox
								checked={isRoleQuery}
								onChange={() => {
									setIsRoleQuery(!isRoleQuery);
								}}
								inputProps={
									{
										"aria-label": "role-query",
										"data-cy": getDataCypressID(dataCypressID, [
											"input",
											"role",
											"query",
										]),
									} as any
								}
							/>
						}
						label={translations.checkbox.saveAsRoleQuery}
						data-cy={getDataCypressID(dataCypressID, ["role", "query"])}
					/>
					<Stack direction={"row"} spacing=".8rem">
						<ButtonCancel
							label={translations.buttons.cancel}
							onClick={onCloseClick}
							dataCypressID={
								dataCypressID ? `${dataCypressID}-cancel_button` : undefined
							}
						/>
						<ButtonSave
							disabled={titleQuery === "" || !titleQuery}
							label={translations.buttons.save}
							onClick={() => {
								onSubmitClick(titleQuery ?? "", isRoleQuery);
							}}
							dataCypressID={
								dataCypressID ? `${dataCypressID}-save_button` : undefined
							}
						/>
					</Stack>
				</Stack>
			</DialogActions>
		</Dialog>
	);
};
export default SaveNewQueryModal;
