import { invitationStatus } from "@application/Configurations/status.configuration";
import type { IChartSeriesItem } from "@infrastructure/model/interfaces/chart/chart.series.item.interface";
import type { DtoDashboardInvitationsItem } from "@key4-front-library/core";

interface TransformDonutChartSeriesProps {
	serie: Array<DtoDashboardInvitationsItem>;
}
const TransformDonutChartSeries = (props: TransformDonutChartSeriesProps) => {
	const { serie } = props;

	const data: Array<IChartSeriesItem> = [];

	serie.forEach((currentStatus: DtoDashboardInvitationsItem) => {
		data.push({
			name: currentStatus.name,
			value: currentStatus.value,
			color: invitationStatus.filter(
				(invitationStatus) => invitationStatus.value === currentStatus.name,
			)[0].bgColor,
		});
	});

	return data;
};

const DashboardFactory = {
	TransformDonutChartSeries,
};

export default DashboardFactory;
