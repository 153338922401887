import {
	faAd,
	faBadgeCheck,
	faBarcodeRead,
	faBed,
	faBooks,
	faCalendarLinesPen,
	faCirclePlay,
	faCommentsAlt,
	faCommentsQuestionCheck,
	faDisplay,
	faFileChartColumn,
	faFileInvoiceDollar,
	faFileLines,
	faGlobePointer,
	faHandshake,
	faIdBadge,
	faIdCard,
	faIdCardClip,
	faLandmark,
	faMessages,
	faMobileButton,
	faPersonCircleCheck,
	faPhotoFilmMusic,
	faPodium,
	faPresentationScreen,
	faScreenUsers,
	faSignalStream,
	faSpinnerThird,
	faStore,
	faUserCheck,
	faUserTie,
} from "@fortawesome/pro-duotone-svg-icons";

const DualToneSet = {
	faAd,
	faBadgeCheck,
	faBarcodeRead,
	faBed,
	faBooks,
	faCalendarLinesPen,
	faCirclePlay,
	faCommentsAlt,
	faCommentsQuestionCheck,
	faDisplay,
	faFileChartColumn,
	faFileInvoiceDollar,
	faFileLines,
	faGlobePointer,
	faHandshake,
	faIdBadge,
	faIdCard,
	faIdCardClip,
	faLandmark,
	faMessages,
	faMobileButton,
	faPersonCircleCheck,
	faPhotoFilmMusic,
	faPodium,
	faPresentationScreen,
	faScreenUsers,
	faSignalStream,
	faSpinnerThird,
	faStore,
	faUserCheck,
	faUserTie,
};

export default DualToneSet;
