export enum HelpContentID {
	PAR_LPCSH_NoLPCSH = 1000,
	PAR_LPCSH_Dashboard = 1001,
	PAR_LPCSH_ParticipantList = 1005,
	PAR_LPCSH_ParticipantDetails = 1004,
	PAR_LPCSH_PrivilegeMatrix = 1007,
	PAR_LPCSH_PrivilegeDetails = 1006,
	PAR_LPCSH_Settings_Roles = 1002,
	PAR_LPCSH_Settings_Profiles = 1003,
	PAR_LPCSH_Settings_CostCentre = 1008,
}

type HelpDefinition = {
	route: string;
	contentID: HelpContentID;
};
const HelpConfiguration: Array<HelpDefinition> = [
	{
		route: "/:client/:event/dashboard",
		contentID: HelpContentID.PAR_LPCSH_Dashboard,
	},
	{
		route: "/:client/:event/participants",
		contentID: HelpContentID.PAR_LPCSH_ParticipantList,
	},
	{
		route: "/:client/:event/participants/:participantId",
		contentID: HelpContentID.PAR_LPCSH_ParticipantDetails,
	},
	{
		route: "/:client/:event/privileges/analytics",
		contentID: HelpContentID.PAR_LPCSH_PrivilegeMatrix,
	},
	{
		route: "/:client/:event/privileges/matrix",
		contentID: HelpContentID.PAR_LPCSH_PrivilegeMatrix,
	},
	{
		route: "/:client/:event/privilege/:privilegeId",
		contentID: HelpContentID.PAR_LPCSH_PrivilegeDetails,
	},
	{
		route: "/:client/:event/settings",
		contentID: HelpContentID.PAR_LPCSH_Settings_Roles,
	},
	{
		route: "/:client/:event/settings/roles",
		contentID: HelpContentID.PAR_LPCSH_Settings_Roles,
	},
	{
		route: "/:client/:event/settings/profiles",
		contentID: HelpContentID.PAR_LPCSH_Settings_Profiles,
	},
	{
		route: "/:client/:event/settings/costcenters",
		contentID: HelpContentID.PAR_LPCSH_Settings_CostCentre,
	},
];

export default HelpConfiguration;
