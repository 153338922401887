import {
	type DtoMailMessage,
	EmailStatusChip,
	GetDateFromIsoString,
	ShortDateTimeInLocalTimeZone,
} from "@key4-front-library/core";
import {
	alpha,
	ButtonBase,
	Divider,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";

import { ParticipantMailTarget } from "./ParticipantMailTarget";

interface Props {
	data: Array<DtoMailMessage>;
	onClickMail: (id: string) => void;
}
export const ParticipantMailsList = (props: Props) => {
	const { data: mails, onClickMail } = props;

	const theme = useTheme();

	const handleClick = (id: string) => {
		onClickMail(id);
	};

	return (
		<Stack
			divider={<Divider orientation="horizontal" flexItem />}
			direction={"column"}
			spacing={1}
		>
			{mails.map((mail) => (
				<Stack
					key={mail.id}
					borderRadius={2}
					sx={{
						"&:hover": {
							backgroundColor: alpha(theme.palette.primary.light, 0.3),
						},
					}}
					p={1}
				>
					<ButtonBase
						id={mail.id}
						sx={{ width: "100%" }}
						onClick={() => {
							handleClick(mail.id);
						}}
					>
						<Stack
							width="100%"
							height="100%"
							gap="5px"
							alignItems={"flex-start"}
						>
							<Stack
								direction={"row"}
								justifyContent="space-between"
								width="100%"
								spacing={1}
							>
								<Typography variant={"caption"} fontWeight={900}>
									{mail.from}
								</Typography>
								<EmailStatusChip status={mail.status} />
							</Stack>
							<Typography variant={"caption"}>{mail.subject}</Typography>
							<Stack
								direction="row"
								width={"100%"}
								justifyContent={"space-between"}
							>
								<ParticipantMailTarget target={mail.target} />
								<Stack alignItems="flex-end">
									<Typography variant={"caption"} noWrap={true}>
										{ShortDateTimeInLocalTimeZone({
											date: GetDateFromIsoString(mail.sendingDate),
										})}
									</Typography>
								</Stack>
							</Stack>
						</Stack>
					</ButtonBase>
				</Stack>
			))}
		</Stack>
	);
};
