import { t } from "i18next";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useParams } from "react-router";

import ParticipantController from "@application/Controllers/ParticipantController";
import DateService from "@infrastructure/services/dates/date.service";
import {
	DatePicker,
	DateTimeToyyyyMMdd,
	useContextModule,
} from "@key4-front-library/core";
import type { DtoCostCenterGet } from "@key4-front-library/core/Dto";
import { EnumBenefitValueType } from "@key4-front-library/core/Enums";
import {
	Checkbox,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

interface Props {
	benefitId: string;
	benefitType?: EnumBenefitValueType;
	benefitValue: string;
	costCenter: DtoCostCenterGet | null;
	costCentersList: Array<DtoCostCenterGet>;
	displayCellBorder: boolean;
}
const CustomCellTotalBenefits = (props: Props) => {
	const {
		benefitId,
		costCentersList,
		benefitValue,
		benefitType,
		costCenter,
		displayCellBorder,
	} = props;

	const { client, event } = useContextModule();
	const { participantId } = useParams();
	const { enqueueSnackbar } = useSnackbar();

	const [textFieldValue, setTextFieldValue] = useState<string>(benefitValue);
	const [costCenterId, setCostCenterId] = useState<string>(
		costCenter?.id ?? "",
	);
	const [dateValue, setDateValue] = useState<DateTime | null>(
		textFieldValue
			? DateService.Convert.GetDateFromIsoString(textFieldValue)
			: null,
	);
	const [booleanValue, setBooleanValue] = useState<boolean>(
		textFieldValue.toLowerCase() === "true",
	);
	const [, setPreviousValueBeforeUpdate] = useState<string>(benefitValue);
	const [isDisplayCellBorder, setIsDisplayCellBorder] =
		useState<boolean>(displayCellBorder);

	const translations = {
		costCenter: t("old.registration.privileges.costCenter.label"),
		value: t("old.form.placeholders.value"),
		returnMessages: {
			success_modification: t(
				"old.registration.privileges.benefitsMatrix.returnMessages.success_modification",
			),
			add_a_cost_center_first: t(
				"old.registration.privileges.benefitsMatrix.returnMessages.add_a_cost_center_first",
			),
		},
	};

	const handleTextChange = (_textFieldValue: string) => {
		setTextFieldValue(_textFieldValue);
	};

	const handleCostCenterSelect = (costCenterSelected: any) => {
		setCostCenterId(costCenterSelected.target.value);
		setIsDisplayCellBorder(false);
	};

	const handleUpdateOverridedBenefit = (
		benefiteOverridedType?: EnumBenefitValueType,
	) => {
		const isoDate =
			dateValue &&
			DateService.Format.IsoStringDate({
				date: dateValue,
			});
		const stringBoolean = booleanValue ? "True" : "False";
		let value = textFieldValue;
		if (benefiteOverridedType === EnumBenefitValueType.DATE) {
			value = isoDate ?? textFieldValue;
		} else if (benefiteOverridedType === EnumBenefitValueType.BIT) {
			value = stringBoolean;
		} else if (benefiteOverridedType === EnumBenefitValueType.MONEY) {
			if (!value) {
				value = "0";
			}
		}

		try {
			participantId &&
				ParticipantController.updateOverridedBenefit(
					client.id,
					event.id,
					participantId,
					benefitId,
					{
						value,
						costCenterId: costCenterId ? costCenterId : null,
					},
				);
			enqueueSnackbar(translations.returnMessages.success_modification, {
				variant: "success",
			});
			setPreviousValueBeforeUpdate(value);
			setIsDisplayCellBorder(false);
		} catch (_error: any) {
			enqueueSnackbar(_error.message, { variant: "error" });
		}
	};

	let textFieldInput = <></>;
	switch (benefitType) {
		case EnumBenefitValueType.BIT:
			textFieldInput = (
				<Checkbox
					checked={booleanValue}
					onChange={(e) => {
						setBooleanValue(e.target.checked);
					}}
					onBlur={() => {
						handleUpdateOverridedBenefit(EnumBenefitValueType.BIT);
					}}
					inputProps={{
						"aria-label": "checkbox-override-benefit",
					}}
					sx={{ mt: 1.6 }}
				/>
			);
			break;
		case EnumBenefitValueType.NUMERIC:
		case EnumBenefitValueType.MONEY:
		case EnumBenefitValueType.PERCENT:
		case EnumBenefitValueType.REAL:
			textFieldInput = (
				<TextField
					id="value-custom-benefit"
					label={translations.value}
					variant="outlined"
					value={textFieldValue || 0}
					onChange={(e) => {
						handleTextChange(e.target.value);
					}}
					onBlur={() => {
						handleUpdateOverridedBenefit();
					}}
					InputProps={{
						inputProps: { min: 0 },
						startAdornment: (
							<InputAdornment position="start">
								{benefitType === EnumBenefitValueType.MONEY && <>€</>}
								{benefitType === EnumBenefitValueType.PERCENT && <>%</>}
							</InputAdornment>
						),
					}}
					size="small"
					type={"number"}
					fullWidth
					sx={{ mt: 2 }}
				/>
			);
			break;
		case EnumBenefitValueType.DATE:
			textFieldInput = (
				<DatePicker
					onChange={(value: string | null) => {
						setDateValue(DateTime.fromISO(value!));
					}}
					label={translations.value}
					value={DateTimeToyyyyMMdd(dateValue!)}
				/>
			);
			break;
		case EnumBenefitValueType.STRING:
			textFieldInput = (
				<TextField
					id="value-custom-benefit"
					label={translations.value}
					variant="outlined"
					value={textFieldValue}
					onChange={(e) => {
						handleTextChange(e.target.value);
					}}
					onBlur={() => {
						handleUpdateOverridedBenefit(EnumBenefitValueType.STRING);
					}}
					size="small"
					fullWidth
					sx={{ mt: 2 }}
				/>
			);
			break;
		default:
			break;
	}
	return (
		<Stack
			direction={"column"}
			spacing={1}
			width={"100%"}
			height={"100%"}
			justifyContent={"space-between"}
			sx={{ border: isDisplayCellBorder ? "4px solid green" : "none" }}
		>
			{benefitType && (
				<>
					<Stack justifyContent={"center"} alignItems={"center"}>
						{textFieldInput}
					</Stack>
					<Stack>
						<FormControl size="small" sx={{ my: 2 }}>
							<InputLabel id="select-cost-center-value">
								{translations.costCenter}
							</InputLabel>
							<Select
								placeholder={translations.costCenter}
								labelId="select-cost-center-value"
								id="select-cost-center"
								value={costCenterId}
								label={translations.costCenter}
								onChange={handleCostCenterSelect}
								onBlur={() => {
									handleUpdateOverridedBenefit(benefitType);
								}}
							>
								{<MenuItem value="">&nbsp;</MenuItem>}
								{costCentersList.map((_costCenter) => (
									<MenuItem key={_costCenter.id} value={_costCenter.id}>
										{_costCenter.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Stack>
				</>
			)}
		</Stack>
	);
};

export default CustomCellTotalBenefits;
