import DashboardFactory from "@application/factory/privileges/DashboardFactory";
import DonutChart from "@infrastructure/components/charts/Donut/DonutChart";
import Card from "@infrastructure/components/interface/card/Card";
import type { DtoDashboardInvitationsItem } from "@key4-front-library/core";
import { Grid } from "@mui/material";

interface Props {
	title: string;
	serie: Array<DtoDashboardInvitationsItem>;
}

const DashboardInvitations = (props: Props) => {
	const { title, serie: invitationsStatus } = props;

	return (
		<>
			{invitationsStatus && (
				<Grid
					item
					xs={12}
					md={6}
					lg={3}
					sx={{
						display: "flex",
					}}
				>
					<Card title={<span>{title}</span>}>
						<DonutChart
							statistics={DashboardFactory.TransformDonutChartSeries({
								serie: invitationsStatus,
							})}
						/>
					</Card>
				</Grid>
			)}
		</>
	);
};

export default DashboardInvitations;
