import { ApplicationColors } from "@application/Configurations/color.configuration";

export const getContrastedColor = (colorHexCode: string) => {
	const luminanceCoefficient = (color: number) => {
		const c = color / 255.0;
		return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
	};

	const luminance =
		luminanceCoefficient(
			Number.parseInt(`0x${colorHexCode.substring(1, 3)}`, 16),
		) *
			0.2126 +
		luminanceCoefficient(
			Number.parseInt(`0x${colorHexCode.substring(3, 5)}`, 16),
		) *
			0.7152 +
		luminanceCoefficient(
			Number.parseInt(`0x${colorHexCode.substring(5, 7)}`, 16),
		) *
			0.0722;

	return luminance > 0.179
		? ApplicationColors.black.main
		: ApplicationColors.white.main;
};

export const getColorFromHash = (hash: string) => `#${hash.substring(19, 25)}`;

// https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color/3943023#3943023
export const pickTextColorBasedOnBgColor = (
	bgColor: string,
	lightColor = "#FFFFFF",
	darkColor = "#000333",
) => {
	if (!bgColor) {
		return { textColor: darkColor, isBackgroundDark: false };
	}
	const color = bgColor.startsWith("#") ? bgColor.substring(1, 7) : bgColor;
	const r = Number.parseInt(color.substring(0, 2), 16); // hexToR
	const g = Number.parseInt(color.substring(2, 4), 16); // hexToG
	const b = Number.parseInt(color.substring(4, 6), 16); // hexToB
	const uicolors = [r / 255, g / 255, b / 255];
	const c = uicolors.map((col) => {
		if (col <= 0.03928) {
			return col / 12.92;
		}
		return Math.pow((col + 0.055) / 1.055, 2.4);
	});
	const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
	if (L > 0.57) {
		return { textColor: darkColor, isBackgroundDark: false };
	}
	return { textColor: lightColor, isBackgroundDark: true }; // L > 0.179 recommanded
};

export const presetColorPickerColors = [
	ApplicationColors.red.main,
	ApplicationColors.orange.main,
	ApplicationColors.blue.main,
	ApplicationColors.darkBlue.main,
	ApplicationColors.veryDarkBlue.main,
	ApplicationColors.green.main,
	ApplicationColors.grey.main,
	ApplicationColors.greyBlue.main,
	ApplicationColors.greyBlueLight.main,
	ApplicationColors.black.main,
];
