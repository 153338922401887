import { t } from "i18next";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import BenefitEditionForm from "@application/Components/privileges/BenefitEditionForm";
import MatrixSkeleton from "@application/Components/privileges/MatrixSkeleton";
import PrivilegeEditionForm from "@application/Components/privileges/PrivilegeEditionForm";
import BenefitsController from "@application/Controllers/BenefitsController";
import PrivilegesController from "@application/Controllers/PrivilegesController";
import { MenuItemReference } from "@application/Enums/MenuItemReference.enum";
import { EPrivilegesMenuItemReference } from "@application/Enums/PrivilegesMenuItemReference.enum";
import PrivilegesMatrixFactory from "@application/factory/privileges/PrivilegesMatrixFactory";
import { EFormAction } from "@domain/interfaces/form.interface";
import {
	DataGridHeaderCustomSperator,
	defaultDataGridStyle,
} from "@infrastructure/components/interface/matrix/K4Matrix";
import ConfirmationModal from "@infrastructure/components/interface/modals/ConfirmationModal";
import DefaultModal from "@infrastructure/components/interface/modals/DefaultModal";
import DateService from "@infrastructure/services/dates/date.service";
import { arrayChangeOrder } from "@infrastructure/services/helpers/arrayFunctions";
import useDebounce from "@infrastructure/services/hooks/useDebounce";
import {
	EnumBenefitCalculationType,
	EnumBenefitValueType,
	ErrorAPI,
	ErrorTemplateOld,
	ESieveOperator,
	queryFilters,
	queryStringPagination,
	Services,
	useContextModule,
	useSnackBarHook,
} from "@key4-front-library/core";
import type {
	DtoBenefit,
	DtoBenefitGet,
	DtoCostCenterGet,
	DtoParticipantGet,
	DtoPrivilegeList,
	DtoPrivilegeWrite,
	DtoRole,
} from "@key4-front-library/core/Dto";
import { Box, Menu, MenuItem } from "@mui/material";
import {
	DataGridPro,
	GridColumnMenuContainer,
	type GridColumnOrderChangeParams,
	type GridColumns,
	type GridRowModel,
	type GridRowOrderChangeParams,
	type GridRowsProp,
} from "@mui/x-data-grid-pro";

interface Props {
	costCenters: Array<DtoCostCenterGet>;
	pinnedToLeftColumns: GridColumns;
	isOpenModalPrivilegeFormCreation: boolean;
	setIsOpenModalPrivilegeFormCreation: (state: boolean) => void;
	isOpenModalBenefitFormCreation?: boolean;
	setIsOpenModalBenefitFormCreation?: (state: boolean) => void;
	anchorRowMenu?: null | HTMLElement;
	handleCloseRowMenu?: () => void;
	dataOfEditedRowFromParent: any;
	setIsRefreshRelatedMatrix?: (state: boolean) => void;
	isRowOrdering?: boolean;
	isCreationMode: boolean;
	privilegeDetailsId?: string;
	isDisableColumnMenu?: boolean;
}

export const PrivilegesMatrix = (props: Props) => {
	const {
		costCenters,
		pinnedToLeftColumns,
		isOpenModalPrivilegeFormCreation,
		setIsOpenModalPrivilegeFormCreation,
		isOpenModalBenefitFormCreation,
		setIsOpenModalBenefitFormCreation,
		anchorRowMenu,
		handleCloseRowMenu,
		dataOfEditedRowFromParent,
		setIsRefreshRelatedMatrix,
		isRowOrdering = false,
		isCreationMode,
		privilegeDetailsId,
		isDisableColumnMenu,
	} = props;

	const navigate = useNavigate();

	const { event, client } = useContextModule();
	const { sendSuccess, sendError } = useSnackBarHook();

	const [loading, setLoading] = useState<boolean>(false);

	const baseUrl = [
		"",
		client.key,
		event.key,
		MenuItemReference.PRIVILEGES,
		EPrivilegesMenuItemReference.MATRIX,
	].join("/");

	const [benefit, setBenefit] = useState<
		DtoBenefit | DtoBenefitGet | undefined
	>();
	const [privilegeId, setPrivilegeId] = useState<string | undefined>();
	const [privilege, setPrivilege] = useState<DtoPrivilegeWrite | undefined>();

	const [privileges, setPrivileges] = useState<Array<DtoPrivilegeList>>([]);

	const [roles, setRoles] = useState<Array<DtoRole>>([]);

	const [promiseArguments, setPromiseArguments] = useState<any>(null);

	const [rows, setRows] = useState<GridRowsProp | undefined>();
	const [columns, setColumns] = useState<GridColumns | undefined>();

	const [openBenefitModal, setOpenBenefitModal] = useState(false);
	const [openPrivilegeModal, setOpenPrivilegeModal] = useState(false);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const confirmationModalProps = useRef({
		action: EFormAction.DELETE,
		handleAction: () => {},
	});

	const [isNew, setIsNew] = useState(false);
	const openRowMenu = Boolean(anchorRowMenu);

	const dataOfEditedRow = useRef<any>({});
	const dataOfEditedColumn = useRef<any>({});

	const [rowIds, setRowIds] = useState<Array<string>>([]);
	const debouncedValueRow = useDebounce<Array<string> | undefined>(rowIds, 500);
	const isMountedRows = useRef(false);

	const [columnIds, setColumnIds] = useState<Array<string>>();
	const debouncedValueColumn = useDebounce<Array<string> | undefined>(
		columnIds,
		500,
	);
	const isMountedColumns = useRef(false);

	// open modal depending on clicking button in parent component
	useEffect(() => {
		if (isOpenModalPrivilegeFormCreation) {
			handleOpenModalPrivilegeForm(isCreationMode);
			setIsOpenModalPrivilegeFormCreation(false);
		}
	}, [isOpenModalPrivilegeFormCreation]);

	useEffect(() => {
		if (isOpenModalBenefitFormCreation) {
			handleOpenModalBenefitForm(true);
			setIsOpenModalBenefitFormCreation?.(false);
		}
	}, [isOpenModalBenefitFormCreation]);

	// TODO persist order columns data this way : 'columnHeaderDragEnd' from https://mui.com/x/react-data-grid/column-ordering/
	// here the problem with this useEffect is that the updateBenefitsOrder function
	// is called on the drag of column and not on the drop. That's why the debounced hook is implemented
	// but it is only a temporary solution.
	useEffect(() => {
		if (debouncedValueColumn) {
			if (isMountedColumns.current) {
				BenefitsController.updateReOrder(client.id, event.id, {
					ids: columnIds!,
				});
			} else {
				isMountedColumns.current = true;
			}
		}
	}, [debouncedValueColumn, columnIds]);

	useEffect(() => {
		if (debouncedValueRow) {
			if (isMountedRows.current) {
				PrivilegesController.updateOrder(client.id, event.id, { ids: rowIds });
			} else {
				isMountedRows.current = true;
			}
		}
	}, [debouncedValueRow, rowIds]);

	const fetchPrivileges = useCallback(
		async (eventId: string) => {
			setLoading(true);
			try {
				const response = await PrivilegesController.readList(
					client.id,
					eventId,
				);
				const { data } = response;

				setPrivileges(data);

				if (data.length > 0 && costCenters.length > 0) {
					let privilegesTmp = data;
					if (privilegeDetailsId) {
						// only one privilege in matrix in privilegeDetail page
						privilegesTmp = data.filter(
							(privilegeTmp: DtoPrivilegeList) =>
								privilegeTmp.id === privilegeDetailsId,
						);
					}

					const initialRows = PrivilegesMatrixFactory.formatRows(privilegesTmp);
					const initialColumns = PrivilegesMatrixFactory.formatColumns(
						data[0].benefits,
						true,
					);

					setRows(initialRows);
					setColumns([...pinnedToLeftColumns, ...initialColumns]);

					// to reorder columns, do not include pinnedToLeftColumns which aren't from API
					setColumnIds(initialColumns.map((column) => column.id));
					setRowIds(initialRows.map((row) => row.id));
				} else {
					setRows([]);
					setColumns(undefined);
				}
			} catch (error: any) {
				sendError(error.message);
			} finally {
				setLoading(false);
			}
		},
		[costCenters.length, pinnedToLeftColumns, privilegeDetailsId],
	);

	useEffect(() => {
		fetchPrivileges(event.id);
	}, [event, fetchPrivileges]);

	const translations = {
		buttons: {
			edit: t("old.form.buttons.edit"),
			details: t("old.form.buttons.details"),
			delete: t("old.form.buttons.delete"),
		},
		benefits: {
			create: t("old.registration.privileges.benefit.create"),
			edit: t("old.registration.privileges.benefit.edit"),
			returnMessages: {
				success_creation: t(
					"old.registration.privileges.benefit.returnMessages.success_creation",
				),
				success_modification: t(
					"old.registration.privileges.benefit.returnMessages.success_modification",
				),
				success_deletion: t(
					"old.registration.privileges.benefit.returnMessages.success_deletion",
				),
			},
		},
		privileges: {
			create: t("old.registration.privileges.privilege.create"),
			edit: t("old.registration.privileges.privilege.edit"),
			returnMessages: {
				success_creation: t(
					"old.registration.privileges.privilege.returnMessages.success_creation",
				),
				success_modification: t(
					"old.registration.privileges.privilege.returnMessages.success_modification",
				),
				success_deletion: t(
					"old.registration.privileges.privilege.returnMessages.success_deletion",
				),
			},
		},
		returnMessages: {
			success_modification: t(
				"old.registration.privileges.returnMessages.success_modification",
			),
		},
		confirmationModal: {
			title: t("old.form.confirmationModal.title"),
			save: t("old.form.buttons.save"),
			cancel: t("old.form.buttons.cancel"),
		},
	};

	const findDataOfEditedColumnAndSetRef = (nameOfColumn: string) => {
		// can only access to the name of the column with MUI datagrid
		// access to data with dataOfEditedColumn ref
		dataOfEditedColumn.current = columns?.find((column) => {
			return column.field === nameOfColumn;
		});
	};

	const processRowUpdate = useCallback(
		(newRow: GridRowModel, oldRow: GridRowModel) =>
			new Promise<GridRowModel>((resolve, reject) => {
				const mutation = computeMutation(newRow, oldRow);
				if (mutation) {
					// Save the arguments to resolve or reject the promise later
					setPromiseArguments({ resolve, reject, newRow, oldRow });
				} else {
					resolve(oldRow); // Nothing was changed
				}
			}),
		[],
	);

	const handleError = (error: any) => {
		let message = error;
		if (error instanceof ErrorAPI) {
			message = error.message;
			if (Array.isArray(message)) {
				message = message[0].technicalMessage;
			}
		}
		sendError(message || error);
	};

	const handleNoCellUpdate = () => {
		const { oldRow, resolve } = promiseArguments;
		resolve(oldRow); // Resolve with the old row to not update the internal state
		setPromiseArguments(null);
	};

	const handleCellUpdate = async () => {
		const { newRow, oldRow, reject, resolve } = promiseArguments;
		const isoDate = DateService.Convert.GetDateFormatedToISO({
			date: newRow[dataOfEditedColumn.current.field],
		});
		try {
			if (dataOfEditedColumn.current.field === "costCenter") {
				// warning: do not allow two costCenter with the same name
				const editedCostCenter = costCenters.find(
					(costCenter: DtoCostCenterGet) => {
						return costCenter.name === newRow.costCenter;
					},
				);
				await PrivilegesController.updateCostCenterInPrivilege(
					client.id,
					event.id,
					newRow.id,
					{
						costCenterId: editedCostCenter!.id,
					},
				);
			} else {
				const benefitValue = PrivilegesMatrixFactory.isDateType(
					dataOfEditedColumn.current?.type,
				)
					? isoDate
					: newRow[dataOfEditedColumn.current.field];
				await PrivilegesController.updateBenefitInPrivilege(
					client.id,
					event.id,
					newRow.id,
					dataOfEditedColumn.current.id,
					{
						value: benefitValue,
					},
				);
			}
			sendSuccess(translations.returnMessages.success_modification);
			resolve(newRow);
			setPromiseArguments(null);
			// refresh costCentersMatrix when a cell value has been modified
			// because modification doesn't reload PrivilegesMatrix but only update API
			setIsRefreshRelatedMatrix?.(true);
			return newRow;
		} catch (error) {
			// TODO show error returned from API
			sendError(t("old.common.errors.generic"));
			reject(oldRow);
			setPromiseArguments(null);
		}
		dataOfEditedColumn.current = {};
	};

	const computeMutation = (newRow: GridRowModel, oldRow: GridRowModel) => {
		// get the value which has been changed
		// to display confirmation message
		const differenceBetweenObjects = Object.keys(newRow).reduce((diff, key) => {
			if (oldRow[key] === newRow[key]) {
				return diff;
			}
			return {
				...diff,
				[key]: newRow[key],
			};
		}, {});
		const keyWhichHasChanged = Object.keys(differenceBetweenObjects)[0];
		// get benefit id
		// warning: do not allow two benefits with the same name
		findDataOfEditedColumnAndSetRef(keyWhichHasChanged);
		if (JSON.stringify(newRow) !== JSON.stringify(oldRow)) {
			let newBenefitValue = newRow[keyWhichHasChanged];
			if (
				PrivilegesMatrixFactory.isDateType(dataOfEditedColumn.current?.type)
			) {
				const dateObject = newRow[keyWhichHasChanged];
				newBenefitValue = DateService.Format.GetDateFormatedFromDateObject({
					date: dateObject,
				});
			}
			if (oldRow[keyWhichHasChanged]) {
				return t("old.form.confirmationModal.modify", {
					oldValue: oldRow[keyWhichHasChanged],
					newValue: newBenefitValue,
					itemToModify: newRow.Privilege,
				});
			}

			return t("old.form.confirmationModal.save", {
				newValue: newBenefitValue,
				itemToModify: newRow.Privilege,
			});
		}
		return null;
	};

	const handleSavePrivilege = async (
		isNewPrivilege: boolean,
		privilegeToSave: DtoPrivilegeWrite,
		id?: string,
	) => {
		const privilegeDto: DtoPrivilegeWrite = {
			key: privilegeToSave.key,
			name: privilegeToSave.name,
			costCenterId:
				privilegeToSave.costCenterId && privilegeToSave.costCenterId.length > 0
					? privilegeToSave.costCenterId
					: undefined,
			currentRoleIds: privilegeToSave.currentRoleIds || [],
			potentialRoleIds: privilegeToSave.potentialRoleIds || [],
		};
		if (isNewPrivilege) {
			await PrivilegesController.create(client.id, event.id, privilegeDto)
				.then(() => {
					sendSuccess(translations.privileges.returnMessages.success_creation);
					fetchPrivileges(event.id);
				})
				.catch((error) => {
					handleError(error);
				})
				.finally(() => {
					handleModaleClose();
				});
		} else {
			if (!id) {
				sendError("ID of Privilege is not provided");
				return;
			}
			await PrivilegesController.update(client.id, event.id, id, privilegeDto)
				.then(() => {
					sendSuccess(
						translations.privileges.returnMessages.success_modification,
					);
					fetchPrivileges(event.id);
				})
				.catch((error) => {
					handleError(error);
				})
				.finally(() => {
					handleModaleClose();
				});
		}
	};

	const handleSaveBenefit = async (
		isNewBenefit: boolean,
		benefitToSave: DtoBenefitGet,
	) => {
		if (isNewBenefit) {
			await BenefitsController.create(client.id, event.id, benefitToSave)
				.then(() => {
					sendSuccess(translations.benefits.returnMessages.success_creation);
					fetchPrivileges(event.id);
				})
				.catch((error) => {
					let message = error;
					if (error instanceof ErrorAPI) {
						message = error.message;
						if (Array.isArray(message)) {
							message = message[0].technicalMessage;
						}
					}
					sendError(message || error);
				})
				.finally(() => {
					handleModaleClose();
				});
		} else {
			await BenefitsController.update(
				client.id,
				event.id,
				benefitToSave.id,
				benefitToSave,
			)
				.then(() => {
					sendSuccess(
						translations.benefits.returnMessages.success_modification,
					);
					fetchPrivileges(event.id);
				})
				.catch((error) => {
					handleError(error);
				})
				.finally(() => {
					handleModaleClose();
				});
		}
	};

	const handleDeletePrivilege = async (id: string) => {
		await PrivilegesController.deleteEntity(client.id, event.id, id)
			.then(() => {
				sendSuccess(translations.privileges.returnMessages.success_deletion);
				fetchPrivileges(event.id);
			})
			.catch((error) => {
				handleError(error);
			})
			.finally(() => {
				handleModaleClose();
			});
	};

	const handleDeleteBenefit = async (id: string) => {
		await BenefitsController.deleteEntity(client.id, event.id, id)
			.then(() => {
				sendSuccess(translations.benefits.returnMessages.success_deletion);
				fetchPrivileges(event.id);
			})
			.catch((error) => {
				handleError(error);
			})
			.finally(() => {
				handleModaleClose();
			});
	};

	const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
		if (columnIds) {
			const columnIdsTmp = [...columnIds];
			const orderedColumns = arrayChangeOrder(
				columnIdsTmp,
				params.oldIndex - pinnedToLeftColumns.length,
				params.targetIndex - pinnedToLeftColumns.length,
			);
			setColumnIds(orderedColumns);
		}
	};

	const handleRowOrderChange = (params: GridRowOrderChangeParams) => {
		if (rowIds) {
			const rowIdsTmp = [...rowIds];
			const orderedRows = arrayChangeOrder(
				rowIdsTmp,
				params.oldIndex,
				params.targetIndex,
			);
			setRowIds(orderedRows);
		}
	};

	const updateDatagrid = () => {
		if (!promiseArguments) {
			return null;
		}
		const { newRow, oldRow } = promiseArguments;
		const mutation = computeMutation(newRow, oldRow);
		mutation ? handleCellUpdate() : handleNoCellUpdate();
	};

	const handleOpenModalPrivilegeForm = async (isNew: boolean) => {
		dataOfEditedRow.current = dataOfEditedRowFromParent.current;
		setIsNew(isNew);
		setPrivilege(undefined);
		if (!isNew) {
			// need to fetch all privilege's data by id
			// because we don't have all data with getPrivileges function
			await PrivilegesController.read(
				client.id,
				event.id,
				dataOfEditedRow.current.id,
			).then((data) => {
				setPrivilegeId(dataOfEditedRow.current.id);
				setPrivilege({
					...data,
					costCenterId:
						data.costCenter?.id && data.costCenter.id.length > 0
							? data.costCenter.id
							: undefined,
					currentRoleIds: data.currentRoles.map(
						(currentRole) => currentRole.id,
					),
					potentialRoleIds: data.potentialRoles.map(
						(currentRole) => currentRole.id,
					),
				});
			});
		} else {
			const defaultValuesPrivilegeForm: DtoPrivilegeWrite = {
				key: "",
				name: "",
				costCenterId: "",
				currentRoleIds: [],
				potentialRoleIds: [],
			};
			setPrivilegeId(undefined);
			setPrivilege(defaultValuesPrivilegeForm);
		}
		setOpenPrivilegeModal(true);
	};

	const handleOpenModalBenefitForm = async (isNew: boolean) => {
		setBenefit(undefined);
		setIsNew(isNew);
		if (!isNew && dataOfEditedColumn.current.id) {
			// need to fetch all benefit's data by id
			// because we don't have all data with getPrivileges function
			const allBenefitData = await BenefitsController.read(
				client.id,
				event.id,
				dataOfEditedColumn.current.id,
			);
			// initialize concatSeparator with '/' like in default values if concatSeparator is empty
			if (!allBenefitData.concatSeparator) {
				allBenefitData.concatSeparator = "/";
			}
			setBenefit(allBenefitData);
		} else {
			const defaultValuesBenefitForm: DtoBenefit = {
				name: "",
				valueType: EnumBenefitValueType.MONEY,
				calculationType: EnumBenefitCalculationType.HIGHEST_RANK,
				concatSeparator: "/",
			};
			setBenefit(defaultValuesBenefitForm);
		}
		setOpenBenefitModal(true);
	};

	const handleModaleClose = () => {
		setOpenBenefitModal(false);
		setOpenPrivilegeModal(false);
		setOpenConfirmationModal(false);
		dataOfEditedColumn.current = {};
	};

	// TODO TM : Temporary while waiting for Backend Update
	const getDistinctRoles = (
		participants: Array<DtoParticipantGet>,
	): Array<DtoRole> => {
		return participants.reduce(
			(accumulatedRoles: Array<DtoRole>, participant) => {
				if (participant.roles) {
					participant.roles.forEach((role) => {
						if (
							!accumulatedRoles.find(
								(accumulatedRole) => accumulatedRole.id === role.id,
							)
						) {
							accumulatedRoles.push(role);
						}
					});
				}
				return accumulatedRoles;
			},
			[],
		);
	};

	const fetchData = async () => {
		let allParticipants: Array<DtoParticipantGet> = [];
		let currentPage = 0;

		while (true) {
			const response =
				await Services.Events.Registration.ParticipantsService.getListPagined(
					client.id,
					event.id,
					[
						...queryFilters(
							["participantroles.name", ESieveOperator.CI_CONTAINS, ""].join(
								"",
							),
						),
						...queryStringPagination({
							page: currentPage,
							pageSize: 1000,
						}),
					],
				);

			const { data: _participants, pagination: _pagination } = response;

			if (_participants.length === 0) {
				break; // Exit the loop if no more participants are returned
			}

			allParticipants = [...allParticipants, ..._participants]; // Append the participants of the current page to the allParticipants array

			if (_pagination.totalCount <= _pagination.pageSize * currentPage) {
				break; // Exit the loop if we've fetched all the participants
			}

			currentPage++;
		}

		const distinctRoles = getDistinctRoles(allParticipants);

		setRoles(distinctRoles);
	};

	useEffect(() => {
		fetchData();
	}, []);

	if (loading) {
		return <MatrixSkeleton />;
	}

	if (!privileges) {
		return <ErrorTemplateOld code={404} />;
	}

	const CustomColumnMenuComponent = (props: any) => {
		const { hideMenu, currentColumn, ...other } = props;
		if (
			currentColumn.field !== "costCenter" &&
			currentColumn.field !== "Privilege"
		) {
			return (
				<GridColumnMenuContainer
					hideMenu={hideMenu}
					currentColumn={currentColumn}
					{...other}
				>
					<MenuItem
						onClick={(e) => {
							hideMenu(e);
							findDataOfEditedColumnAndSetRef(currentColumn.field);
							handleOpenModalBenefitForm(false);
						}}
					>
						{translations.buttons.edit}
					</MenuItem>
					<MenuItem
						onClick={(e) => {
							hideMenu(e);
							confirmationModalProps.current = {
								action: EFormAction.DELETE,
								handleAction: () => handleDeleteBenefit(currentColumn.id),
							};
							setOpenConfirmationModal(true);
						}}
					>
						{translations.buttons.delete}
					</MenuItem>
				</GridColumnMenuContainer>
			);
		}
		// disable menu options on pinned columns
		// because it is a double entry matrix
		return <></>;
	};

	return (
		<>
			{updateDatagrid()}
			{costCenters && rows && columns && (
				<Box style={{ width: "100%" }}>
					<DataGridPro
						rows={rows}
						columns={columns}
						processRowUpdate={processRowUpdate}
						experimentalFeatures={{
							newEditingApi: true,
						}}
						pinnedColumns={{
							left: ["__reorder__", "rank", "Privilege", "costCenter"],
						}}
						components={{
							ColumnMenu: CustomColumnMenuComponent,
							ColumnResizeIcon: DataGridHeaderCustomSperator,
						}}
						disableColumnMenu={isDisableColumnMenu}
						hideFooter
						onColumnOrderChange={handleColumnOrderChange}
						onRowOrderChange={handleRowOrderChange}
						sx={{
							...defaultDataGridStyle,
							"& .MuiDataGrid-cell:hover": {
								cursor: "pointer",
							},
							minHeight: "50vh",
							overflow: "auto",
						}}
						rowReordering={isRowOrdering}
					/>
					<Menu
						id="row-menu"
						anchorEl={anchorRowMenu}
						open={openRowMenu}
						onClose={handleCloseRowMenu}
						MenuListProps={{
							"aria-labelledby": "row-menu-button",
						}}
					>
						<MenuItem
							onClick={() => {
								handleOpenModalPrivilegeForm(false);
								handleCloseRowMenu?.();
							}}
						>
							{translations.buttons.edit}
						</MenuItem>
						<MenuItem
							onClick={() => {
								dataOfEditedRow.current = dataOfEditedRowFromParent.current;
								navigate(`${baseUrl}/${dataOfEditedRow.current.id}`);
							}}
						>
							{translations.buttons.details}
						</MenuItem>
						<MenuItem
							onClick={() => {
								handleCloseRowMenu?.();
								dataOfEditedRow.current = dataOfEditedRowFromParent.current;
								confirmationModalProps.current = {
									action: EFormAction.DELETE,
									handleAction: () =>
										handleDeletePrivilege(dataOfEditedRow.current.id),
								};
								setOpenConfirmationModal(true);
							}}
						>
							{translations.buttons.delete}
						</MenuItem>
					</Menu>
				</Box>
			)}

			<DefaultModal
				open={openBenefitModal}
				title={
					isNew ? translations.benefits.create : translations.benefits.edit
				}
				maxWidth={"sm"}
			>
				<BenefitEditionForm
					isNew={isNew}
					benefit={benefit}
					handleSaveBenefit={handleSaveBenefit}
					handleModaleClose={handleModaleClose}
				/>
			</DefaultModal>

			<DefaultModal
				open={openPrivilegeModal}
				title={
					isNew ? translations.privileges.create : translations.privileges.edit
				}
				maxWidth={"md"}
			>
				<PrivilegeEditionForm
					isNew={isNew}
					id={privilegeId}
					privilege={privilege}
					roles={roles}
					costCenters={costCenters}
					handleSavePrivilege={handleSavePrivilege}
					handleModaleClose={handleModaleClose}
				/>
			</DefaultModal>

			<ConfirmationModal
				open={openConfirmationModal}
				action={confirmationModalProps.current.action}
				handleModaleClose={handleModaleClose}
				handleAction={confirmationModalProps.current.handleAction}
				maxWidth={"sm"}
			/>
		</>
	);
};
