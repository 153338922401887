import { t } from "i18next";
import { useCallback, useEffect, useRef, useState } from "react";

import FooterAddCustomBenefit from "@application/Components/participants/details/benefits/FooterAddCustomBenefit";
import BenefitsController from "@application/Controllers/BenefitsController";
import CostCenterController from "@application/Controllers/CostCenterController";
import { EBenefitRow } from "@application/Enums/BenefitsRow.enum";
import PrivilegesMatrixFactory from "@application/factory/privileges/PrivilegesMatrixFactory";
import Loading from "@infrastructure/components/interface/loading/Loading";
import K4Matrix from "@infrastructure/components/interface/matrix/K4Matrix";
import { NoData, useContextModule } from "@key4-front-library/core";
import { Box } from "@mui/material";
import type {
	GridAlignment,
	GridColumns,
	GridRowsProp,
	GridValidRowModel,
} from "@mui/x-data-grid";

interface Props {
	participantId: string;
}
const ParticipantDetailsBenefitsMatrix = (props: Props) => {
	const { participantId } = props;

	const { client, event } = useContextModule();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [rowsBenefitsMatrix, setRowsBenefitsMatrix] = useState<
		GridRowsProp | undefined
	>();
	const [columnsBenefitsMatrix, setColumnsBenefitsMatrix] = useState<
		GridColumns | undefined
	>();
	const [isBenefitsMatrixOverrided, setIsBenefitsMatrixOverrided] =
		useState(false);
	const translations = {
		calculatedTotalBenefits: t(
			"old.registration.privileges.benefitsMatrix.calculatedTotalBenefits",
		),
		customTotalBenefits: t(
			"old.registration.privileges.benefitsMatrix.customTotalBenefits",
		),
	};

	const defaultStyleColumnsParams = {
		flex: 1,
		disableReorder: true,
		headerAlign: "center" as GridAlignment,
	};

	const pinnedToLeftColumns = useRef([
		{
			...defaultStyleColumnsParams,
			field: "calculatedTotalBenefits",
			minWidth: 200,
			cellClassName: "column-background-dark",
			renderHeader: (params: any) => {
				return (
					<Box
						fontWeight={500}
						className={"menuHiddenFix columnHeaderHiddenFix"}
					>
						{params.field}
					</Box>
				);
			},
		},
	]);

	const initAPI = useCallback(async () => {
		const costsCenters = await CostCenterController.getList(
			client.id,
			event.id,
			undefined,
			["name"],
			undefined,
		);

		const benefits = await BenefitsController.readParticipantPrivilegesMatrix(
			client.id,
			event.id,
			participantId,
		);

		const initialRows = [
			{
				id: EBenefitRow.CALCULATED_TOTAL_BENEFITS,
				calculatedTotalBenefits: translations.calculatedTotalBenefits,
			},
		];

		const rowCalculatedTotalBenefits: any =
			benefits.current &&
			PrivilegesMatrixFactory.formatParticipantBenefitsRows({
				initialRow: initialRows[0],
				benefits: benefits.current,
			});

		const rows: Array<GridValidRowModel> = [rowCalculatedTotalBenefits];

		if (benefits.overridden && benefits.overridden.length > 0) {
			setIsBenefitsMatrixOverrided(true);
			initialRows.push({
				id: EBenefitRow.CUSTOM_TOTAL_BENEFITS,
				calculatedTotalBenefits: translations.customTotalBenefits,
			});
			const rowCustomTotalBenefits =
				PrivilegesMatrixFactory.formatParticipantBenefitsRows({
					initialRow: initialRows[1],
					benefits: benefits.overridden,
				});
			rows.push(rowCustomTotalBenefits);
		} else {
			setIsBenefitsMatrixOverrided(false);
			if (initialRows.length === 2) {
				initialRows.pop();
			}
		}
		rows && setRowsBenefitsMatrix(rows);

		const initialColumns =
			benefits.current.length > 0 &&
			benefits.definition.length > 0 &&
			PrivilegesMatrixFactory.formatParticipantBenefitsColumns(
				benefits.current,
				benefits.definition,
				costsCenters.data,
				benefits.overridden,
			);
		initialColumns &&
			setColumnsBenefitsMatrix([
				...pinnedToLeftColumns.current,
				...initialColumns,
			]);

		setIsLoading(false);
	}, [
		event,
		participantId,
		translations.calculatedTotalBenefits,
		translations.customTotalBenefits,
	]);

	useEffect(() => {
		setIsLoading(true);
		initAPI();
	}, [initAPI, event]);

	if (isLoading) {
		return <Loading />;
	}

	if (!rowsBenefitsMatrix || !columnsBenefitsMatrix) {
		return <NoData />;
	}

	return (
		<>
			<K4Matrix rows={rowsBenefitsMatrix} columns={columnsBenefitsMatrix} />
			<FooterAddCustomBenefit
				isBenefitsMatrixOverrided={isBenefitsMatrixOverrided}
				onAddTotalBenefitButtonClick={() => {
					setIsLoading(true);
					initAPI();
				}}
			/>
		</>
	);
};

export default ParticipantDetailsBenefitsMatrix;
