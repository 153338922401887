import i18n from "i18next";

const Required = (fieldName: string) =>
	i18n.t("old.form.fields.error.required", {
		fieldName,
	});
const Invalid = (fieldName: string) =>
	i18n.t("old.form.fields.error.invalid", {
		fieldName,
	});

const Date = (fieldName: string) =>
	i18n.t("old.form.fields.error.date", {
		fieldName,
	});

const Number = (fieldName: string) =>
	i18n.t("old.form.fields.error.number", {
		fieldName,
	});

const DateComparaison = (
	fieldNameSource: string,
	fieldNameDestination: string,
) =>
	i18n.t("old.form.fields.error.date-comparaison", {
		fieldNameSource,
		fieldNameDestination,
	});

const Max = (maxValue: number, fieldName: string) =>
	i18n.t("old.form.fields.error.max", {
		maxValue,
		fieldName,
	});

const MinNumber = (minValue: number, fieldName: string) =>
	i18n.t("old.form.fields.error.minNumber", {
		minValue,
		fieldName,
	});

const Phone = (fieldName: string) =>
	i18n.t("old.form.fields.error.phone", {
		fieldName,
	});

const HexColor = (fieldName: string) =>
	i18n.t("old.form.fields.error.color", {
		fieldName,
	});

const FormFieldRule = {
	Date,
	DateComparaison,
	HexColor,
	Invalid,
	Max,
	MinNumber,
	Number,
	Phone,
	Required,
};

export default FormFieldRule;
