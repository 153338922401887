import { t, type TFunction } from "i18next";
import { forIn } from "lodash";

import ConfigurationsApp from "@application/Configurations";
import ParticipantConfiguration, {
	type ParticipantInvitationStatus,
} from "@application/Configurations/ParticipantConfiguration";
import {
	EnumParticipantFieldKey,
	EnumParticipantFormKey,
	EnumParticipantSectionKey,
} from "@application/Enums/ParticipantEnum";
import HelpersApp from "@application/Helpers";
import CustomFieldHelper from "@application/Helpers/customField.helper";
import type { TParticipantUseFormModal } from "@domain/interfaces/participant.interface";
import {
	EField,
	EnumParticipantStatusInvitation,
	ErrorAPI,
	getFileName,
	queryFilters,
	queryIncludeCustomFields,
	queryStringPagination,
	queryStringSorts,
	Services,
	sieveStringFiltersPageList,
	type TPagination,
	type TPickForm,
	type TUseFormBulkAction,
	useContextModule,
} from "@key4-front-library/core";
import type {
	DtoContact,
	DtoParticipantGet,
	DtoParticipantOverrideBenefit,
	DtoParticipantOverrideBenefitActivation,
	DtoParticipantProfileId,
	DtoParticipantStatusInvitation,
	DtoParticipantWrite,
	DtoProfile,
} from "@key4-front-library/core/Dto";
import {
	EnumApiPatchOperation,
	EnumCustomFieldKind,
} from "@key4-front-library/core/Enums";
import type {
	TypeApiResponsePagined,
	TypeApiResponsePost,
	TypeCustomFieldValue,
	TypeCustomFieldValues,
	TypeUseFormListForms,
} from "@key4-front-library/core/Types";
import type { GridValidRowModel } from "@mui/x-data-grid";

import ContactController from "./ContactController";

/**
 * Get a Participant
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param participantId ID of Participant
 * @param includeCustomFields Inclue custom fields
 * @returns DtoParticipantGet
 * @typedef DtoParticipantGet Dto of Participant
 */
const get = async (
	clientId: string,
	eventId: string,
	participantId: string,
	includeCustomFields = false,
): Promise<DtoParticipantGet> =>
	Services.Events.Registration.ParticipantsService.get(
		clientId,
		eventId,
		participantId,
		[...queryIncludeCustomFields(includeCustomFields)],
	);

/**
 * Get List Pagined of Participant
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param search Search word
 * @param sorts Sort fields and directions
 * @param pagination Pagination properties
 * @returns Pagined list of DtoParticipantGet
 * @typedef IContact Dto of DtoParticipantGet
 */
const getList = async (
	clientId: string,
	eventId: string,
	search: string,
	sorts: Array<string>,
	pagination: TPagination,
	filter: string,
	includeCustomFields?: boolean,
): Promise<TypeApiResponsePagined<Array<DtoParticipantGet>>> => {
	const filterString = sieveStringFiltersPageList(
		ConfigurationsApp.ParticipantConfiguration.sieveKeys,
		search,
		filter,
	);
	return Promise.resolve(
		await Services.Events.Registration.ParticipantsService.getListPagined(
			clientId,
			eventId,
			[
				...queryStringPagination(pagination),
				...queryStringSorts(sorts),
				...queryFilters(filterString),
				...queryIncludeCustomFields(!!includeCustomFields),
			],
		),
	);
};

/**
 * Create a new Participant
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param useFormvalues values
 * @typedef TParticipantUseFormModal values
 * @return TypeApiResponsePost
 * @typedef TypeApiResponsePost Post API response
 */
const create = async (
	clientId: string,
	eventId: string,
	useFormvalues: TParticipantUseFormModal,
): Promise<TypeApiResponsePost> => {
	// Remap custom field with new value
	const customFieldValues: TypeCustomFieldValues =
		CustomFieldHelper.useFormToDataAPI(useFormvalues.customFieldValues);

	const participantData: DtoParticipantWrite = {
		email: useFormvalues.email,
		firstname: useFormvalues.firstname,
		lastname: useFormvalues.lastname,
		profileId: useFormvalues.profile ? useFormvalues.profile.key : null,
		invitationStatus: useFormvalues.invitationStatus,
		roleIds: useFormvalues.rolesIds,
		customFieldValues,
	};

	return Services.Events.Registration.ParticipantsService.post(
		clientId,
		eventId,
		participantData,
	);
};

/**
 * Update a Participant
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param useFormvalues values
 * @typedef TParticipantUseFormModal values
 * @return Success boolean
 */
const update = async (
	clientId: string,
	eventId: string,
	participantId: string,
	useFormvalues: TParticipantUseFormModal,
): Promise<boolean> => {
	// Remap custom field with new value
	const customFieldValues: TypeCustomFieldValues =
		CustomFieldHelper.useFormToDataAPI(useFormvalues.customFieldValues);

	const participantData: DtoParticipantWrite = {
		email: useFormvalues.email,
		firstname: useFormvalues.firstname,
		lastname: useFormvalues.lastname,
		profileId: useFormvalues.profile ? useFormvalues.profile.key : null,
		invitationStatus: useFormvalues.invitationStatus,
		roleIds: useFormvalues.rolesIds,
		customFieldValues,
	};

	return Services.Events.Registration.ParticipantsService.put(
		clientId,
		eventId,
		participantId,
		participantData,
	);
};

/**
 * Get list of Profiles of Participants
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @returns List of Profiles
 * @typedef DtoProfile Dto of Profile
 */
const getProfilesList = async (
	clientId: string,
	eventId: string,
): Promise<Array<DtoProfile>> =>
	await Services.Events.ProfileService.getList(clientId, eventId, [
		...queryStringSorts(["name"]),
	]);

/**
 *  Assign Profile to a Participant
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param participantId Id of the Participant
 * @param profileId Id of the Profile or NULL if not defined
 * @returns Success boolean
 */
const assignProfile = async (
	clientId: string,
	eventId: string,
	participantId: string,
	profileId: string | null,
): Promise<boolean> =>
	await Services.Events.Registration.ParticipantsService.putProfile(
		clientId,
		eventId,
		participantId,
		{
			profileId,
		} as DtoParticipantProfileId,
	);

/**
 *  Assign Invitation Status to a Participant
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param participantId Id of the Participant
 * @param body InvitationStatus
 * @returns Success boolean
 */
const assignInvitationStatus = async (
	clientId: string,
	eventId: string,
	participantId: string,
	body: DtoParticipantStatusInvitation,
): Promise<boolean> =>
	await Services.Events.Registration.ParticipantsService.putInvitationStatus(
		clientId,
		eventId,
		participantId,
		body,
	);

/**
 *  Get Form informations of a Participant by ID
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param id Id of Participant
 * @param operationId Current operation ID
 * @param manualRoles manual Roles
 * @returns TParticipantUseFormModal
 * @typedef TParticipantUseFormModal Dto of Participant use for modal
 */
const getFormParticipantFromId = async (
	clientId: string,
	eventId: string,
	id: string,
	manualRoles: Array<string> | undefined,
): Promise<TParticipantUseFormModal> => {
	let participantForm: TParticipantUseFormModal;
	const participant: DtoParticipantGet =
		await Services.Events.Registration.ParticipantsService.get(
			clientId,
			eventId,
			id,
		);
	const participantRoleIds = participant.roles
		? participant.roles.flatMap((r) => r.id)
		: [];
	const participantManualRoleIds = manualRoles
		? manualRoles.filter((value) => participantRoleIds.includes(value))
		: [];

	participantForm = {
		email: participant.email ?? undefined,
		firstname: participant.firstname ?? undefined,
		lastname: participant.lastname ?? undefined,
		profile: participant.profile
			? {
					key: participant.profile.id,
					label: participant.profile.name ?? "",
				}
			: null,
		rolesIds: participantManualRoleIds,
		invitationStatus: participant.invitationStatus,
		customFieldValues: {},
	};

	let contacts: Array<DtoContact> = [];

	if (participant.email) {
		contacts = await ContactController.getContactsByEmail(
			clientId,
			eventId,
			participant.email,
		).then((response) => response.data);
	}

	if (contacts && contacts.length > 0 && contacts[0].customFieldValues) {
		participant.customFieldValues = {
			...participant.customFieldValues,
			...contacts[0].customFieldValues,
		};
	}

	// Add customFieldValues & keep legacy field
	if (participant.customFieldValues) {
		forIn(
			participant.customFieldValues,
			(customField: TypeCustomFieldValue, key: string) => {
				if (
					!participant.customFieldValues ||
					!participantForm.customFieldValues
				) {
					return;
				}
				participantForm.customFieldValues[key] = {
					kind: EnumCustomFieldKind.TEXT,
					customDefault: customField,
					value: customField.isCustomized
						? participant.customFieldValues[key].customizedValue
						: participant.customFieldValues[key].value,
				};
			},
		);
	} else {
		participantForm.customFieldValues = undefined;
	}

	return participantForm;
};

/**
 * Get export of Particpants
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param eventCode Current event Code
 * @param filename Name of the file
 * @returns Promise of unknown
 */
const getExport = async (
	clientId: string,
	eventId: string,
	eventCode: string,
	filename: string,
): Promise<unknown> =>
	await Services.Events.Registration.ParticipantsService.getExport(
		clientId,
		eventId,
		getFileName(eventCode, filename),
	);

/**
 * Get list of Participants for Bulk Action
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param search Search word
 * @param sorts Sort fields and directions
 * @param filter Filter
 * @returns List of Participants
 * */
const getParticipantsListBulkActionStepSelect = async (
	clientId: string,
	eventId: string,
	search: string,
	sorts: Array<string>,
	filter: string,
	t: TFunction,
): Promise<Array<GridValidRowModel> | number | ErrorAPI> => {
	const filterString = sieveStringFiltersPageList(
		ConfigurationsApp.ParticipantConfiguration.sieveKeys,
		search,
		filter,
	);

	const participants =
		await Services.Events.Registration.ParticipantsService.getListPagined(
			clientId,
			eventId,
			[
				...queryStringPagination({
					page: 0,
					pageSize: 100,
				}),
				...queryStringSorts(sorts),
				...queryFilters(filterString),
			],
		);

	if (
		!participants.pagination.totalCount ||
		participants.pagination.totalCount > 100
	) {
		if (!participants.pagination.totalCount) {
			return Promise.reject(new ErrorAPI(400, "No pagination set"));
		}
		return Promise.resolve(participants.pagination.totalCount ?? 0);
	}

	const result = Promise.resolve(
		participants.data.map((participant: DtoParticipantGet) => {
			const status =
				ParticipantConfiguration.statusObject[
					participant.invitationStatus as ParticipantInvitationStatus
				];
			return {
				key: participant.key,
				id: participant.id,
				firstname: participant.firstname,
				lastname: participant.lastname,
				email: participant.email,
				invitationStatus: {
					iconColor: status.bgColor,
					label: t(status.label),
				},
			};
		}),
	);

	return result;
};

/**
 *  Patch Invitation Status Transition of Participants
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param participantsIds Ids of the Participants
 * @param value InvitationStatus
 * @returns Success boolean
 */
const patchParticipantInvitationStatusTransitionBulkAction = async (
	clientId: string,
	eventId: string,
	participantsIds: Array<string>,
	value: string,
): Promise<void | ErrorAPI> => {
	return Promise.resolve(
		await Services.Events.Registration.ParticipantsService.patchBulkAction(
			clientId,
			eventId,
			{
				ids: participantsIds,
				operations: [
					{
						op: EnumApiPatchOperation.REPLACE,
						path: "invitationStatus",
						value,
					},
				],
			},
		),
	);
};

/**
 *  Patch Edit of Participants
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param participantsIds Ids of the Participants
 * @param useFormValues Form values
 * @returns Success boolean
 */
const patchParticipantEditBulkAction = async (
	clientId: string,
	eventId: string,
	participantsIds: Array<string>,
	useFormValues: TUseFormBulkAction,
) => {
	const operations = [];

	for (const key in useFormValues) {
		const typeOfField = useFormValues[key as keyof TUseFormBulkAction];
		for (const key in typeOfField) {
			if (typeOfField[key].isChecked) {
				if (key === EnumParticipantFieldKey.PROFILE_ID) {
					if (typeOfField[key].value?.key) {
						operations.push({
							op: EnumApiPatchOperation.REPLACE,
							path: EnumParticipantFieldKey.PROFILE_ID,
							value: typeOfField[key].value.key,
						});
					} else {
						operations.push({
							op: EnumApiPatchOperation.REMOVE,
							path: EnumParticipantFieldKey.PROFILE_ID,
							value: null,
						});
					}
				}

				if (key === EnumParticipantFieldKey.ROLES_IDS) {
					operations.push({
						op: typeOfField[key].operation,
						path: EnumParticipantFieldKey.ROLES_IDS,
						value: typeOfField[key].value,
					});
				}
			}
		}
	}

	return Promise.resolve(
		await Services.Events.Registration.ParticipantsService.patchBulkAction(
			clientId,
			eventId,
			{
				ids: participantsIds,
				operations,
			},
		),
	);
};

/**
 * Get Form informations of a Participant by ID
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param roles Roles
 * @returns TPickForm[]
 * @typedef TPickForm[] Form of Bulk Action Edition
 */
const getBulkActionEditionForm = async (
	clientId: string,
	eventId: string,
): Promise<Array<TPickForm>> => {
	const profilesList = await ParticipantController.getProfilesList(
		clientId,
		eventId,
	);

	return [
		{
			key: EnumParticipantFormKey.GENERAL,
			label: "",
			sections: [
				{
					key: EnumParticipantSectionKey.MAIN,
					label: "",
					fields: [
						{
							kind: EField.AUTO_COMPLETE,

							autoComplete: {
								label: t(
									"old.registration.participants.edition.form.profile.label",
								),
								items: profilesList.map((profile: any) => {
									return {
										key: profile.id,
										label: profile.name,
									};
								}),
							},
							key: EnumParticipantFieldKey.PROFILE_ID,
							isStatic: false,
						},
					],
				},
			],
		},
	];
};

/**
 * Get Entity
 * @returns Entity
 * @typedef Entity Entity
 */
const useEntity = () => {
	const { client, event } = useContextModule();

	const read = async (id: string): Promise<DtoParticipantGet> => {
		return await Services.Events.Registration.ParticipantsService.get(
			client.id,
			event.id,
			id,
			queryIncludeCustomFields(true),
		);
	};

	const create = async (
		useFormData: TypeUseFormListForms,
	): Promise<boolean> => {
		try {
			const participant: DtoParticipantWrite = {
				invitationStatus: EnumParticipantStatusInvitation.UNSENT,
			};
			HelpersApp.ParticipantHelper.mapUseFormToDtoParticipantWrite(
				useFormData,
				participant,
			);
			await Services.Events.Registration.ParticipantsService.post(
				client.id,
				event.id,
				participant,
			);
			return true;
		} catch {
			return false;
		}
	};

	const update = async (
		id: string,
		useFormData: TypeUseFormListForms,
	): Promise<boolean> => {
		const participant: DtoParticipantWrite = {};
		HelpersApp.ParticipantHelper.mapUseFormToDtoParticipantWrite(
			useFormData,
			participant,
		);
		await Services.Events.Registration.ParticipantsService.put(
			client.id,
			event.id,
			id,
			participant,
		);
		return true;
	};

	return { create, read, update };
};

/**
 * Update en Override Benefit
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param id Current Participant ID
 * @param benefitId Current Benefit ID
 * @param costCenterId Current CostCenter ID
 * @param value Value of the Overide Benefit
 * @returns Success boolean
 */
const updateOverridedBenefit = async (
	clientId: string,
	eventId: string,
	id: string,
	benefitId: string,
	body: DtoParticipantOverrideBenefit,
): Promise<boolean> =>
	Services.Events.Registration.ParticipantsService.putOverridedBenefit(
		clientId,
		eventId,
		id,
		benefitId,
		body,
	);

/**
 *  Override a Benefit
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param id Current Participant ID
 * @param body State of Override
 * @returns Success boolean
 */
const updateOverrideBenefitsActivation = async (
	clientId: string,
	eventId: string,
	id: string,
	body: DtoParticipantOverrideBenefitActivation,
): Promise<boolean> =>
	Services.Events.Registration.ParticipantsService.putOverrideBenefitsActivation(
		clientId,
		eventId,
		id,
		body,
	);

const ParticipantController = {
	assignInvitationStatus,
	assignProfile,
	create,
	get,
	getExport,
	getFormParticipantFromId,
	getList,
	getProfilesList,
	update,
	getParticipantsListBulkActionStepSelect,
	patchParticipantInvitationStatusTransitionBulkAction,
	patchParticipantEditBulkAction,
	getBulkActionEditionForm,
	useEntity,
	updateOverridedBenefit,
	updateOverrideBenefitsActivation,
};

export default ParticipantController;
