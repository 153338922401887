import { t } from "i18next";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { ApplicationColors } from "@application/Configurations/color.configuration";
import { yupResolver } from "@hookform/resolvers/yup";
import PopoverPicker from "@infrastructure/components/forms/ColorPicker/PopoverPicker";
import IconPicker from "@infrastructure/components/interface/icons/IconPicker";
import {
	ButtonCancel,
	ButtonSave,
} from "@infrastructure/components/interface/uikit/K4Button";
import FormFieldRule from "@infrastructure/services/i18n/i18nFormRulesParser";
import {
	type DataCypress,
	type DtoRole,
	getDataCypressID,
	HEXADECIMAL_COLOR_REGEX,
} from "@key4-front-library/core";
import { DialogActions, Grid, Stack, TextField } from "@mui/material";

type Props = DataCypress & {
	isNew: boolean;
	role: DtoRole | undefined;
	handleSaveRole: (isNew: boolean, role: DtoRole) => void;
	handleModaleClose: () => void;
};

const RoleEditionForm = (props: Props) => {
	const { isNew, role, handleSaveRole, handleModaleClose, dataCypressID } =
		props;
	const [color, setColor] = useState(
		role?.color ?? ApplicationColors.grey.light!,
	);

	const formfields = {
		name: t("old.registration.settings.roles.edition.form.name.label"),
		color: t("old.registration.settings.roles.edition.form.color.label"),
		icon: t("old.registration.settings.roles.edition.form.icon.label"),
	};

	const form = {
		name: {
			label: formfields.name,
		},
		color: {
			label: formfields.color,
		},
		icon: {
			label: formfields.icon,
		},
		buttons: {
			save: t("old.form.buttons.save"),
			cancel: t("old.form.buttons.cancel"),
		},
	};

	const Schema: any = yup.object({
		name: yup
			.string()
			.required(FormFieldRule.Required(formfields.name))
			.max(125, FormFieldRule.Max(125, formfields.name)),
		color: yup
			.string()
			.required(FormFieldRule.Required(formfields.color))
			.matches(
				HEXADECIMAL_COLOR_REGEX,
				FormFieldRule.HexColor(formfields.color),
			),
	});

	const {
		register,
		control,
		handleSubmit,
		formState: { isSubmitting, isDirty, isValid, errors },
	} = useForm<any>({
		resolver: yupResolver(Schema),
		defaultValues: role,
		mode: "onChange",
	});

	const onSubmit = (_role: DtoRole) => {
		handleSaveRole(isNew, _role);
	};

	return (
		<Stack spacing={3} mt={1}>
			<TextField
				id="name"
				{...register("name")}
				label={form.name.label}
				placeholder={form.name.label}
				helperText={<>{errors.name?.message}</>}
				error={!!errors.name}
				fullWidth
				inputProps={{
					"data-testid": "role-name",
					"data-cy": getDataCypressID(dataCypressID, ["name"]),
				}}
			/>
			<Grid item xs={12} display={"flex"} gap={2}>
				<PopoverPicker
					color={color}
					setColor={setColor}
					control={control}
					dataCypressID={dataCypressID ? dataCypressID : undefined}
				/>
				<TextField
					id="color"
					{...register("color")}
					label={form.color.label}
					value={color}
					inputProps={{
						maxLength: 7,
						"data-cy": getDataCypressID(dataCypressID, ["color"]),
					}}
					helperText={<>{errors.color?.message}</>}
					error={!!errors.color}
					fullWidth
					onChange={(e) => {
						setColor(e.target.value);
					}}
				/>
			</Grid>
			<IconPicker
				control={control}
				icon={role?.icon!}
				label={form.icon.label}
				dataCypressID={dataCypressID}
			/>
			<DialogActions>
				<ButtonCancel
					label={form.buttons.cancel}
					dataCypressID={getDataCypressID(dataCypressID, ["cancel", "button"])}
					onClick={handleModaleClose}
					sx={{ mr: 1 }}
				/>

				<ButtonSave
					label={form.buttons.save}
					disabled={!isDirty || !isValid || isSubmitting}
					dataCypressID={getDataCypressID(dataCypressID, ["save", "button"])}
					onClick={handleSubmit(onSubmit)}
				/>
			</DialogActions>
		</Stack>
	);
};

export default RoleEditionForm;
