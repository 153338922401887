import { t } from "i18next";

import { ApplicationColors } from "@application/Configurations/color.configuration";
import { alpha, Stack } from "@mui/material";
import {
	DataGridPro,
	type GridColumns,
	type GridRowsProp,
} from "@mui/x-data-grid-pro";

interface Props {
	rows: GridRowsProp;
	columns: GridColumns;
	setDefaultRowHeight?: boolean;
	noRowsMessage?: string;
	pinnedColumnsLeft?: Array<string>;
}

export const defaultDataGridStyle = {
	"& .MuiDataGrid-columnHeader:has(.menuHiddenFix) .MuiDataGrid-menuIcon": {
		display: "none",
	},
	"& .columnHeaderHiddenFix ": {
		display: "none",
	},

	"& .column-background-dark": {
		backgroundColor: alpha(ApplicationColors.darkBlue.main, 0.15),
		fontWeight: "500",
		borderBottomColor: ApplicationColors.white.dark!.toString(),
		borderBottomWidth: "1px",
		borderBottomStyle: "solid",
	},
	"& .column-background-light": {
		backgroundColor: alpha(ApplicationColors.darkBlue.light!, 0.15),
	},
	"& .row-italic-grey": {
		fontStyle: "italic",
		color: ApplicationColors.grey.main,
	},
	"& .MuiDataGrid-columnSeparator": {
		color: alpha(ApplicationColors.darkBlue.main, 0.15),
	},
	"& .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus": {
		outline: "none !important",
	},
	"& .MuiDataGrid-columnSeparator--sideRight": {
		width: "24px",
	},
	"& .MuiDataGrid-row:first-of-type .column-background-dark ": {
		borderTopColor: ApplicationColors.white.dark!.toString(),
		borderTopWidth: "1px",
		borderTopStyle: "solid",
	},
	"& .MuiDataGrid-columnHeaders": {
		borderBottom: 0,
	},
	"& .MuiDataGrid-cellContent": {
		pr: 1,
	},
};

export const DataGridHeaderCustomSperator = () => {
	return (
		<div
			style={{
				minHeight: "38px",
				borderColor: ApplicationColors.white.dark,
				borderLeftWidth: "1px",
				borderRightWidth: 0,
				borderStyle: "solid",
				margin: "0 auto",
			}}
		></div>
	);
};

const K4Matrix = (props: Props) => {
	const {
		rows,
		columns,
		setDefaultRowHeight,
		noRowsMessage,
		pinnedColumnsLeft,
	} = props;

	return (
		<DataGridPro
			rows={rows}
			columns={columns}
			hideFooter
			autoHeight
			getRowHeight={() => {
				const defaultRowHeight = 52;
				if (setDefaultRowHeight) {
					return defaultRowHeight;
				}
				return "auto";
			}}
			pinnedColumns={{ left: pinnedColumnsLeft }}
			components={{
				ColumnResizeIcon: DataGridHeaderCustomSperator,
				NoRowsOverlay: () => (
					<Stack height="100%" alignItems="center" justifyContent="center">
						{noRowsMessage ?? t("no-data")}
					</Stack>
				),
			}}
			disableColumnMenu={true}
			getRowClassName={(params) => {
				if (Number(params.id) % 2 === 0) {
					return "row-background-light";
				}
				// TODO make row styles more generic
				if (params.row.isPotential) {
					return "row-italic-grey";
				}
				if (params.row.id === "customTotalBenefits") {
					return "column-background-light";
				}
				return "";
			}}
			sx={defaultDataGridStyle}
		/>
	);
};

export default K4Matrix;
