import type { IconName } from "@fortawesome/fontawesome-svg-core";
import type { IColor } from "@infrastructure/model/interfaces/color.interface";
import { Box, Button, Link } from "@mui/material";

import { Icon } from "../icons/Icon";

interface Props {
	icon: string;
	label: string;
	redirectUrl?: string;
	color?: IColor;
	// startIcon and handleClick props are not used anymore in this project.
	// keep them in case
	startIcon?: boolean;
	handleClick?: () => void;
}

const ActionButton = (props: Props) => {
	const {
		label,
		redirectUrl,
		icon,
		color = "primary",
		startIcon = false,
		handleClick,
	} = props;
	const iconComponent = (
		<Icon prefix="far" iconName={icon as IconName} size="1x" color={color} />
	);
	const endIconProps = {
		endIcon: iconComponent,
	};
	const startIconProps = {
		startIcon: iconComponent,
	};
	const iconProps = startIcon ? startIconProps : endIconProps;

	return (
		<>
			{redirectUrl ? (
				<Button
					component={Link}
					href={redirectUrl}
					size="small"
					color={color}
					variant="contained"
					{...iconProps}
					aria-label={label}
					onClick={() => handleClick && handleClick()}
				>
					{label}
				</Button>
			) : (
				<Button
					component={Box}
					size="small"
					color={color}
					variant="contained"
					{...iconProps}
					aria-label={label}
					onClick={() => handleClick && handleClick()}
				>
					{label}
				</Button>
			)}
		</>
	);
};
export default ActionButton;
