import { getFileName, Services } from "@key4-front-library/core";
import type {
	DtoBenefitValue,
	DtoPrivilegeBenefit,
	DtoPrivilegeCost,
	DtoPrivilegeCostCenter,
	DtoPrivilegeGet,
	DtoPrivilegeList,
	DtoPrivilegeWrite,
	DtoReOrder,
} from "@key4-front-library/core/Dto";
import type {
	TypeApiQueryString,
	TypeApiResponsePagined,
} from "@key4-front-library/core/Types";

/**
 * Get Privilege by ID
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param id Current privilege ID
 * @returns Privilege
 */
const read = async (
	clientId: string,
	eventId: string,
	id: string,
): Promise<DtoPrivilegeGet> =>
	await Services.Events.Registration.PrivilegesService.get(
		clientId,
		eventId,
		id,
	);

/**
 * Get List Pagined of Privileges
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param queryStrings QueryStrings
 * @typedef TypeApiQueryString Type of QueryString
 * @returns List of Privilege
 */
const readList = async (
	clientId: string,
	eventId: string,
	queryStrings: Array<TypeApiQueryString> = [],
): Promise<TypeApiResponsePagined<Array<DtoPrivilegeList>>> =>
	await Services.Events.Registration.PrivilegesService.getListPagined(
		clientId,
		eventId,
		queryStrings,
	);

/**
 * Create new Privilege
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param privilege Privilege to create
 * @typedef DtoPrivilegeWrite Dto of Privilege for writing
 * @returns ID of Privilege created
 * @typedef TypeApiResponsePost Type of Post API Response
 */
const create = async (
	clientId: string,
	eventId: string,
	privilege: DtoPrivilegeWrite,
) =>
	await Services.Events.Registration.PrivilegesService.post(
		clientId,
		eventId,
		privilege,
	);

/**
 * Update a Privilege
 * @param clientId Current client ID
 * @param eventId ID of event
 * @param id Current Privilege ID
 * @param privilege Privilege to update
 * @typedef DtoPrivilegeWrite Dto of Privilege for writing
 * @returns Success boolean
 */
const update = async (
	clientId: string,
	eventId: string,
	id: string,
	privilege: DtoPrivilegeWrite,
) =>
	await Services.Events.Registration.PrivilegesService.put(
		clientId,
		eventId,
		id,
		privilege,
	);

/**
 * Delete a Privilege
 * @param clientId Current client ID
 * @param eventId  Current event ID
 * @param id Current Privilege ID
 * @returns Success boolean
 */
const deleteEntity = async (clientId: string, eventId: string, id: string) =>
	await Services.Events.Registration.PrivilegesService.deleteEntity(
		clientId,
		eventId,
		id,
	);

/**
 * Update Privileges Order
 * @param clientId Current Client Id
 * @param eventId Current Event Id
 * @param body List sorted of Privileges's ID
 * @returns Success boolean
 */
const updateOrder = async (
	clientId: string,
	eventId: string,
	body: DtoReOrder,
) =>
	await Services.Events.Registration.PrivilegesService.putReOrder(
		clientId,
		eventId,
		body,
	);

/**
 * Get the Privileges of a Participant
 * @param clientId Current Client Id
 * @param eventId Current Event Id
 * @param participantId Current Participant ID
 * @typedef DtoPrivilegeList Dto of Participant matrix privileges
 * @returns List of privileges
 */
const readListParticipantPrivileges = async (
	clientId: string,
	eventId: string,
	participantId: string,
): Promise<TypeApiResponsePagined<Array<DtoPrivilegeList>>> =>
	await Services.Events.Registration.PrivilegesService.getListParticipantMatrix(
		clientId,
		eventId,
		participantId,
	);

/**
 * Get Cost of Privileges
 * @param clientId Current Client Id
 * @param eventId Current Event Id
 * @param id Current Privilege ID
 * @typedef DtoPrivilegeCost Dto of Privilege Cost
 * @returns Cost of privilege
 */
const readPrivilegeCost = async (
	clientId: string,
	eventId: string,
	id: string,
): Promise<DtoPrivilegeCost> =>
	await Services.Events.Registration.PrivilegesService.getPrivilegeCost(
		clientId,
		eventId,
		id,
	);

/**
 * Get Cost of Privileges
 * @param clientId Current Client Id
 * @param eventId Current Event Id
 * @param id Current Privilege ID
 * @typedef DtoBenefitValue Dto of Privilege Cost
 * @returns List of Benefits
 */
const readPrivilegeBenefits = async (
	clientId: string,
	eventId: string,
	id: string,
): Promise<Array<DtoBenefitValue>> =>
	await Services.Events.Registration.PrivilegesService.getPrivilegeBenefits(
		clientId,
		eventId,
		id,
	);

/**
 * Export Privilege in an Excel document to download
 * @param clientId Current Client Id
 * @param eventId Current Event ID
 * @param eventCode Current Event Code
 * @param filename File name expected for the export
 * @returns An unknow Promise
 */
const getExport = async (
	clientId: string,
	eventId: string,
	eventCode: string,
	filename: string,
) =>
	await Services.Events.Registration.PrivilegesService.getExport(
		clientId,
		eventId,
		getFileName(eventCode, filename),
	);

/**
 * Update Privilege's CostCenter
 * @param clientId Current Client Id
 * @param eventId Current Event ID
 * @param id Current Privilege ID
 * @param costCenterId Current CostCenter ID
 * @returns Success boolean
 */
const updateCostCenterInPrivilege = async (
	clientId: string,
	eventId: string,
	id: string,
	body: DtoPrivilegeCostCenter,
) =>
	await Services.Events.Registration.PrivilegesService.putCostCenterInPrivilege(
		clientId,
		eventId,
		id,
		body,
	);

/**
 * Update Privilege's Benefit
 * @param clientId Current Client Id
 * @param eventId Current Event ID
 * @param id Current Privilege ID
 * @param benefitId Current Benefit ID
 * @param value Value of the benefit
 * @returns Success boolean
 */
const updateBenefitInPrivilege = async (
	clientId: string,
	eventId: string,
	id: string,
	benefitId: string,
	body: DtoPrivilegeBenefit,
) =>
	await Services.Events.Registration.PrivilegesService.putBenefitInPrivilege(
		clientId,
		eventId,
		id,
		benefitId,
		body,
	);

const PrivilegesController = {
	read,
	readList,
	create,
	update,
	deleteEntity,
	updateOrder,
	readListParticipantPrivileges,
	readPrivilegeCost,
	readPrivilegeBenefits,
	getExport,
	updateCostCenterInPrivilege,
	updateBenefitInPrivilege,
};

export default PrivilegesController;
