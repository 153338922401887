import {
	faArrowLeftToLine,
	faBolt,
	faBomb,
	faBriefcase,
	faDatabase,
	faEarthAmericas,
	faFaceMask,
	faFileLines,
	faFileXmark,
	faGrid,
	faHandPeace,
	faIdBadge,
	faMicrophone,
	faSave,
	faUserDoctor,
	faUserNurse,
} from "@fortawesome/pro-light-svg-icons";

const LightSet = {
	faArrowLeftToLine,
	faBolt,
	faBriefcase,
	faFileXmark,
	faFileLines,
	faGrid,
	faDatabase,
	faSave,
	faBomb,
	faEarthAmericas,
	faFaceMask,
	faHandPeace,
	faIdBadge,
	faMicrophone,
	faUserDoctor,
	faUserNurse,
};

export default LightSet;
