import { useAuth } from "react-oidc-context";

const useEntity = () => {
	const auth = useAuth();

	const readMode = (): string => {
		if (auth.user) {
			return `${auth.user.profile.darkmode}`.toLocaleLowerCase() === "true"
				? "dark"
				: "light";
		}
		return "light";
	};

	return { readMode };
};

const AuthHook = {
	useEntity,
};

export default AuthHook;
