import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import ListRoles from "@application/Components/dashboard/roles/components/ListRoles";
import Role from "@application/Components/role/Role";
import type { IconPrefix } from "@fortawesome/fontawesome-svg-core";
import Card from "@infrastructure/components/interface/card/Card";
import {
	type DataCypress,
	type DtoDashboardRole,
	type DtoRole,
	getDataCypressID,
} from "@key4-front-library/core";
import { EnumRoleScope } from "@key4-front-library/core/Enums";
import { Grid } from "@mui/material";

type Props = DataCypress & {
	roles: Array<DtoDashboardRole>;
	unpinnedRoles: Array<DtoRole>;
	isManage: boolean;
	setRoles: (roles: any) => void;
	setIsModaleOpen: (open: boolean) => void;
	setRoleToEdit: (role: DtoRole) => void;
	setPinnedRoleClicked: (role: DtoDashboardRole) => void;
};

const DashboardRoles = (props: Props) => {
	const {
		roles,
		unpinnedRoles,
		isManage,
		setRoles,
		setIsModaleOpen,
		setRoleToEdit,
		setPinnedRoleClicked,
		dataCypressID,
	} = props;

	return (
		<>
			<DndProvider backend={HTML5Backend}>
				<ListRoles
					roles={roles}
					setRoles={setRoles}
					isManage={isManage}
					setIsModaleOpen={setIsModaleOpen}
					setRoleToEdit={setRoleToEdit}
					setPinnedRoleClicked={setPinnedRoleClicked}
					xs={12}
					sm={12}
					md={6}
					lg={6}
					xl={3}
					dataCypressID={getDataCypressID(dataCypressID, [
						"pinned",
						"role",
						"card",
					])}
				/>
			</DndProvider>
			{isManage &&
				unpinnedRoles &&
				unpinnedRoles.map((_role: DtoRole) => {
					return (
						<Grid
							key={_role.id}
							item
							xs={12}
							sm={12}
							md={6}
							lg={6}
							xl={3}
							sx={{ opacity: 0.5 }}
						>
							<Card
								children={
									<Role
										id={_role.id}
										name={_role.name}
										value={0}
										color={_role.color}
										icon={_role.icon}
										scope={EnumRoleScope.PARTICIPANT}
										type={_role.type}
										dataCypressID={getDataCypressID(dataCypressID, [
											"unpinned",
											"role",
											"card",
											_role.id,
											"role",
										])}
									/>
								}
								pinIcon={{
									prefix: "far" as IconPrefix,
								}}
								setDataPinnedElementClicked={setPinnedRoleClicked}
								dataCypressID={getDataCypressID(dataCypressID, [
									"unpinned",
									"role",
									"card",
									_role.id,
								])}
							/>
						</Grid>
					);
				})}
		</>
	);
};

export default DashboardRoles;
