import { t } from "i18next";
import { Outlet } from "react-router-dom";

import { MenuItemReference } from "@application/Enums/MenuItemReference.enum";
import { ESettingsMenuItemReference } from "@application/Enums/SettingsMenuItemReference.enum";
import SettingsFactory from "@application/factory/SettingsFactory";
import PeriodContainer from "@infrastructure/components/interface/period/PeriodContainer";
import Title from "@infrastructure/components/interface/titles/Title";
import NavigationTabsWithIcons from "@infrastructure/components/layout/composition/navigation/NavigationTabsWithIcons";
import type { IMenuItems } from "@infrastructure/model/interfaces/navigation/menuItems.interface";
import { useContextModule } from "@key4-front-library/core";

const PageSettings = () => {
	const { client, event } = useContextModule();

	const title = t("old.registration.settings.index.title");

	// TODO Remove GetSettingsMenuConfiguration

	const GetSettingsMenuConfiguration = (eventSLUG: string): IMenuItems => {
		const baseUrl = `/${eventSLUG}/${MenuItemReference.SETTINGS}`;
		return [
			{
				index: 0,
				type: "entry",
				reference: ESettingsMenuItemReference.ROLES,
				name: "Roles",
				translationKey: "old.registration.settings.tabs.roles.label",
				icon: "tags",
				link: `${baseUrl}/roles`,
			},
			{
				index: 1,
				type: "entry",
				reference: ESettingsMenuItemReference.PROFILES,
				name: "Profiles",
				translationKey: "old.registration.settings.tabs.profiles.label",
				icon: "file-user",
				link: `${baseUrl}/tags`,
			},
			{
				index: 2,
				type: "entry",
				reference: ESettingsMenuItemReference.COST_CENTERS,
				name: "Cost Centers",
				translationKey: "old.registration.settings.tabs.cost_centers.label",
				icon: "landmark",
				link: `${baseUrl}/cost_centers`,
			},
			{
				index: 3,
				type: "entry",
				reference: ESettingsMenuItemReference.MAIL_TEMPLATES,
				name: "Mail templates",
				translationKey: "old.registration.settings.tabs.mailTemplates.label",
				icon: "envelope",
				link: `${baseUrl}/cost_centers`,
			},
		];
	};

	return (
		<>
			<Title
				title={title}
				reference={
					<PeriodContainer
						dateStartIso={event.startDate}
						dateEndIso={event.endDate}
					/>
				}
			/>

			<NavigationTabsWithIcons
				menuItems={SettingsFactory.GetMenuItemsTranslations({
					menuItems: GetSettingsMenuConfiguration(event.key),
					t,
				})}
				baseUrl={`/${client.key}/${event.key}/${MenuItemReference.SETTINGS}`}
				defaultTabsValue={ESettingsMenuItemReference.ROLES}
				routeParentLastPartPath={MenuItemReference.SETTINGS}
			/>
			<Outlet />
		</>
	);
};

export default PageSettings;
