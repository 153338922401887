import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import {
	Box,
	type Breakpoint,
	Dialog,
	DialogContent,
	DialogTitle,
} from "@mui/material";

interface Props {
	title?: string;
	children: JSX.Element;
	open: boolean;
	maxWidth?: Breakpoint;
	isFullWidth?: boolean;
}

const DefaultModal = (props: Props & dataCypressProp) => {
	const {
		title,
		children,
		open = false,
		maxWidth = "md",
		isFullWidth = true,
		dataCypressID,
	} = props;

	return (
		<Dialog open={open} maxWidth={maxWidth} fullWidth={isFullWidth}>
			{title && (
				<DialogTitle
					data-cy={dataCypressID ? `${dataCypressID}-title` : undefined}
				>
					{title}
				</DialogTitle>
			)}
			<DialogContent>
				<Box>{children}</Box>
			</DialogContent>
		</Dialog>
	);
};
export default DefaultModal;
