import { t } from "i18next";
import type React from "react";
import { useState } from "react";

import type { IconName } from "@fortawesome/fontawesome-svg-core";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import { pickTextColorBasedOnBgColor } from "@infrastructure/services/color/color.service";
import { getActiveStatus } from "@infrastructure/services/status/status.service";
import type { DataCypress, DtoParticipantGet } from "@key4-front-library/core";
import { Gravatar } from "@key4-front-library/core/Bo/Components";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
	Card,
	CardContent,
	Chip,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Stack,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";

type Props = DataCypress & {
	participant: DtoParticipantGet;
	handleParticipantClick: (participant: DtoParticipantGet) => void;
	handleParticipantDoubleClick: (participant: DtoParticipantGet) => void;
};

const ParticipantCard = (props: Props) => {
	const {
		participant,
		handleParticipantClick,
		handleParticipantDoubleClick,
		dataCypressID,
	} = props;

	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const status = getActiveStatus(participant.invitationStatus);

	const menuItems = [
		{
			label: t("old.form.buttons.details"),
			icon: "magnifying-glass",
			handleClick: (_event: any) => {
				handleParticipantClick(participant);
			},
		},
		{
			label: t("old.form.buttons.edit"),
			icon: "pencil",
			handleClick: (_event: any) => {
				handleParticipantDoubleClick(participant);
			},
		},
	];

	const styles = {
		labelEllipsis: {
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
		},
	};

	const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMenuItems = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setAnchorEl(null);
	};

	return (
		<Card
			onDoubleClick={() => {
				handleParticipantDoubleClick(participant);
			}}
			onClick={() => {
				handleParticipantClick(participant);
			}}
			sx={{
				minWidth: "250px",
				height: "170px",
				"&:hover": {
					opacity: ".6",
					cursor: "pointer",
				},
				boxShadow: `0 1px 4px ${theme.palette.grey[300]}`,
			}}
		>
			<CardContent>
				<Stack direction="row" spacing="1rem" width="100%" height="100%">
					<Stack pt="8px">
						<Gravatar
							email={participant.email ?? ""}
							firstname={participant.firstname ?? ""}
							lastname={participant.lastname ?? ""}
							size={40}
						/>
					</Stack>
					<Stack pt="8px" sx={{ minHeight: "140px" }} height="100%">
						<Stack
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							width="100%"
						>
							<Typography
								sx={{
									fontSize: ".8rem",
									textOverflow: "ellipsis",
									overflow: "hidden",
									display: "-webkit-box",
									WebkitLineClamp: "1",
									WebkitBoxOrient: "vertical",
								}}
								data-cy={dataCypressID ? `${dataCypressID}-id` : undefined}
							>
								{participant.id}
							</Typography>
						</Stack>
						<Stack>
							<Typography
								data-cy={
									dataCypressID ? `${dataCypressID}-fullname` : undefined
								}
							>
								{participant.firstname} {participant.lastname}
							</Typography>
						</Stack>
						<Stack mt={".3rem"}>
							<Typography
								color={theme.palette.grey[300]}
								data-cy={
									dataCypressID ? `${dataCypressID}-profile_name` : undefined
								}
							>
								{participant.profile?.name}
							</Typography>
						</Stack>
						<Stack
							mt="1rem"
							alignContent="center"
							sx={{
								textOverflow: "ellipsis",
								overflow: "hidden",
								display: "-webkit-box",
								WebkitLineClamp: "1",
								WebkitBoxOrient: "vertical",
								marginTop: "auto",
							}}
						>
							{participant.roles &&
								participant.roles.length > 0 &&
								participant.roles.map((role) => {
									return (
										<Tooltip key={role.id} title={role.name} placement="top">
											<Chip
												label={role.name}
												style={{
													backgroundColor: role.color ?? "",
												}}
												sx={{
													mr: "3px",
													mb: "3px",
													"& .MuiChip-label": {
														color: role.color
															? pickTextColorBasedOnBgColor(role.color)
																	.textColor
															: "",
														...styles.labelEllipsis,
													},
													maxWidth: 100,
												}}
												size="small"
												data-cy={
													dataCypressID
														? `${dataCypressID}-chip_role_${role.id}`
														: undefined
												}
											/>
										</Tooltip>
									);
								})}
						</Stack>
					</Stack>
					<Stack justifyContent={"space-between"}>
						<Stack
							direction="row"
							alignItems={"center"}
							justifyContent="flex-end"
							height="30px"
						>
							<Icon color={status.bgColor} prefix="fas" iconName="circle" />
							{menuItems && (
								<>
									<IconButton
										data-cy={
											dataCypressID
												? `${dataCypressID}-actions_menu_button`
												: undefined
										}
										aria-label="settings"
										aria-haspopup="true"
										aria-expanded={open ? "true" : undefined}
										onClick={handleSettingsClick}
									>
										<MoreVertIcon />
									</IconButton>
									<Menu
										id="settings-menu"
										data-cy="settings-menu"
										anchorEl={anchorEl}
										open={open}
										onClose={handleCloseMenuItems}
										MenuListProps={{
											"aria-labelledby": "button",
										}}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "left",
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: "right",
										}}
									>
										{menuItems.map((item, i) => {
											return (
												<MenuItem
													key={i}
													onClick={(event) => {
														event.stopPropagation();
														item.handleClick(event);
														setAnchorEl(null);
													}}
													data-cy={
														dataCypressID
															? `${dataCypressID}-actions_menu_item`
															: undefined
													}
												>
													<ListItemIcon>
														<Icon
															prefix="far"
															iconName={item.icon as IconName}
															size="1x"
															color={theme.palette.secondary.main}
														/>
													</ListItemIcon>
													<ListItemText>{item.label}</ListItemText>
												</MenuItem>
											);
										})}
									</Menu>
								</>
							)}
						</Stack>
						<Stack direction="row" justifyContent="flex-end" pr="10px">
							<Icon
								color={theme.palette.grey[300]}
								prefix="far"
								size="lg"
								iconName="circle-info"
							/>
						</Stack>
					</Stack>
				</Stack>
			</CardContent>
		</Card>
	);
};

export default ParticipantCard;
