import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import Tags from "@application/Components/participants/details/roleTags/Tags";
import { PrivilegesMatrix } from "@application/Components/privileges/PrivilegesMatrix";
import CostCenterController from "@application/Controllers/CostCenterController";
import PrivilegesController from "@application/Controllers/PrivilegesController";
import PrivilegeDetailsFactory from "@application/factory/privileges/PrivilegeDetailsFactory";
import AppBox from "@infrastructure/components/interface/box/AppBox";
import Card from "@infrastructure/components/interface/card/Card";
import K4Skeleton from "@infrastructure/components/interface/loading/K4Skeleton";
import Loading from "@infrastructure/components/interface/loading/Loading";
import K4Matrix from "@infrastructure/components/interface/matrix/K4Matrix";
import NumberThousandSeparator from "@infrastructure/components/interface/textFormat/NumberThousandSeparator";
import Title from "@infrastructure/components/interface/titles/Title";
import {
	type DtoCostCenterGet,
	type DtoPrivilege,
	ErrorTemplateOld,
	useContextModule,
} from "@key4-front-library/core";
import { Box, Grid, Stack, Tooltip, Typography } from "@mui/material";
import type {
	GridAlignment,
	GridColumns,
	GridRowsProp,
} from "@mui/x-data-grid-pro";

const GetTranslations = () => {
	return {
		privilegesMatrix: {
			costCenter: {
				label: t("old.registration.privileges.costCenter.label"),
			},
		},
		buttons: {
			edit: t("old.form.buttons.edit"),
		},
		state: {
			created: t("old.common.state:created"),
			updated: t("old.common.state:updated"),
		},
		benefitsMatrix: {
			potentialBenefits: t("privilegeDetails:benefitsMatrix:potentialBenefits"),
			currentBenefits: t("privilegeDetails:benefitsMatrix:currentBenefits"),
		},
		roles: {
			allocationRoles: t("privilegeDetails:roles:allocationRoles"),
			potentialRoles: t("privilegeDetails:roles:potentialRoles"),
		},
		totalCurrentCosts: t("privilegeDetails:totalCurrentCosts"),
		format: {
			thousandSeparator: t("old.common.format.number.thousand-separator"),
		},
	};
};

export const PagePrivilegeDetails = () => {
	const translations = GetTranslations();
	const { privilegeId } = useParams();
	const { client, event } = useContextModule();
	const [privilege, setPrivilege] = useState<DtoPrivilege>();
	const [isApiLoading, setIsApiLoading] = useState<boolean>(true);
	const [isApiReloading, setIsApiReloading] = useState<boolean>(false);
	const [costCenters, setCostCenters] = useState<Array<DtoCostCenterGet>>([]);
	const [
		isOpenModalPrivilegeFormCreation,
		setIsOpenModalPrivilegeFormCreation,
	] = useState(false);
	const dataOfEditedRowFromParent = useRef({ id: privilegeId });
	const [benefitsMatrixRows, setBenefitsMatrixRows] = useState<GridRowsProp>();
	const [benefitsMatrixColumns, setBenefitsMatrixColumns] =
		useState<GridColumns>();

	const defaultStyleColumnsParams = {
		flex: 1,
		disableReorder: true,
		headerAlign: "center" as GridAlignment,
	};
	const firstColumnMinWidth = {
		minWidth: 200,
	};
	const pinnedToLeftColumns: GridColumns = [
		{
			...defaultStyleColumnsParams,
			field: "costCenter",
			headerName: translations.privilegesMatrix.costCenter.label,
			...firstColumnMinWidth,
			cellClassName: "column-background-light",
			renderHeader: (params: any) => {
				return (
					<Tooltip title={params.colDef.headerName}>
						<Box fontWeight={500}>{params.colDef.headerName}</Box>
					</Tooltip>
				);
			},
			editable: true,
			type: "singleSelect",
			valueOptions: costCenters.map((costCenter) => costCenter.name),
		},
	];
	const totalCurrentCosts = useRef<number>(0);

	useEffect(() => {
		const firstColumnMinWidth = {
			minWidth: 200, // TODO Move this part into a Settings part
		};

		if (privilegeId) {
			const fetchData = async () => {
				return {
					privilege: await PrivilegesController.read(
						client.id,
						event.id,
						privilegeId,
					),
					costs: await PrivilegesController.readPrivilegeCost(
						client.id,
						event.id,
						privilegeId,
					),
					benefits: await PrivilegesController.readPrivilegeBenefits(
						client.id,
						event.id,
						privilegeId,
					),
				};
			};

			if (isApiLoading || isApiReloading) {
				fetchData().then((results) => {
					setPrivilege(results.privilege);
					totalCurrentCosts.current = results.costs.totalCurrentCosts;
					const formatedData = PrivilegeDetailsFactory.PopulateBenefitsMatrix({
						benefitsCosts: results.costs,
						benefitsValues: results.benefits,
						translations,
						firstColumnMinWidth,
						defaultStyleColumnsParams,
					});
					setBenefitsMatrixRows(formatedData.rows);
					setBenefitsMatrixColumns(formatedData.columns);
					setIsApiLoading(false);
					setIsApiReloading(false);
				});
			}
		}
	}, [
		event,
		privilegeId,
		isApiReloading,
		defaultStyleColumnsParams,
		isApiLoading,
		translations,
	]);

	useEffect(() => {
		const fetchCostCenters = async () => {
			const { data } = await CostCenterController.getList(
				client.id,
				event.id,
				undefined,
				["name"],
			);
			setCostCenters([{ id: "", name: "\u00a0" }, ...data]);
		};

		fetchCostCenters();
	}, [event]);

	const renderingTotalCurrentCost = () => {
		return (
			<Card title={translations.totalCurrentCosts}>
				{!isApiReloading ? (
					<Stack alignItems={"flex-end"}>
						<Typography sx={{ fontSize: "2rem" }}>
							<NumberThousandSeparator value={totalCurrentCosts.current} />€
						</Typography>
					</Stack>
				) : (
					<K4Skeleton />
				)}
			</Card>
		);
	};

	if (isApiLoading) {
		return <Loading />;
	}

	if (!privilege) {
		return <ErrorTemplateOld code={404} />;
	}

	return (
		<>
			<Title
				title={privilege.name}
				buttons={[
					{
						label: translations.buttons.edit,
						handleClick: () => {
							setIsOpenModalPrivilegeFormCreation(true);
						},
					},
				]}
			/>
			<Grid
				container
				columnSpacing={{ sm: 7.5 }}
				rowSpacing={7}
				alignItems="baseline"
			>
				<Grid container item rowSpacing={7} xs={12} md={9}>
					<Grid item xs={12} display={{ sm: "none" }}>
						{renderingTotalCurrentCost()}
					</Grid>

					<Grid item xs={12}>
						<AppBox>
							<PrivilegesMatrix
								costCenters={costCenters}
								pinnedToLeftColumns={pinnedToLeftColumns}
								isOpenModalPrivilegeFormCreation={
									isOpenModalPrivilegeFormCreation
								}
								setIsOpenModalPrivilegeFormCreation={
									setIsOpenModalPrivilegeFormCreation
								}
								dataOfEditedRowFromParent={dataOfEditedRowFromParent}
								isCreationMode={false}
								privilegeDetailsId={privilegeId}
								setIsRefreshRelatedMatrix={setIsApiReloading}
								isDisableColumnMenu
							/>
						</AppBox>
					</Grid>
					<Grid item xs={12}>
						{!isApiReloading ? (
							<AppBox>
								<K4Matrix
									rows={benefitsMatrixRows!}
									columns={benefitsMatrixColumns!}
									setDefaultRowHeight
								/>
							</AppBox>
						) : (
							<K4Skeleton />
						)}
					</Grid>
				</Grid>

				<Grid container item rowSpacing={7} xs={12} md={3}>
					<Grid item xs={true} display={{ xs: "none", sm: "block" }}>
						{renderingTotalCurrentCost()}
					</Grid>
					{privilege.currentRoles.length > 0 && (
						<Grid item xs={12}>
							<Card title={translations.roles.allocationRoles}>
								{!isApiReloading ? (
									<Tags roles={privilege.currentRoles} />
								) : (
									<K4Skeleton />
								)}
							</Card>
						</Grid>
					)}
					{privilege.potentialRoles.length > 0 && (
						<Grid item xs={12}>
							<Card title={translations.roles.potentialRoles}>
								{!isApiReloading ? (
									<Tags roles={privilege.potentialRoles} />
								) : (
									<K4Skeleton />
								)}
							</Card>
						</Grid>
					)}
				</Grid>
			</Grid>
		</>
	);
};
