import { Skeleton, Stack } from "@mui/material";

const K4Skeleton = () => {
	return (
		<Stack>
			{[...Array(3).keys()].map((item) => (
				<Skeleton key={item} animation="wave" width={"xl"} height={40} />
			))}
		</Stack>
	);
};

export default K4Skeleton;
