import type { OptionsObject } from "notistack";

const error = { variant: "error" } as OptionsObject;
const success = { variant: "success" } as OptionsObject;

const SnackbarHelper = {
	error,
	success,
};

export default SnackbarHelper;
