// TODO generic type instead of type any
const getSimilarityBetweenTwoArraysWithId = (
	array1: Array<any>,
	array2: Array<any>,
) => {
	if (array1.length === 0 || array2.length === 0) {
		return;
	}
	return array1.filter((object1: any) => {
		return array2.some((object2: any) => {
			return object1.id === object2.id;
		});
	});
};

const getSimilarityBetweenArrayOfObjectsAndArrayOfStrings = (
	objects: Array<any>,
	strings: Array<string>,
) => {
	return objects.filter((item) => strings.includes(item.id));
};

const arrayChangeOrder = (
	array: Array<any>,
	fromIndex: number,
	toIndex: number,
) => {
	const element = array[fromIndex];
	array.splice(fromIndex, 1);
	array.splice(toIndex, 0, element);
	return array;
};

export {
	arrayChangeOrder,
	getSimilarityBetweenArrayOfObjectsAndArrayOfStrings,
	getSimilarityBetweenTwoArraysWithId,
};
