import { t } from "i18next";

import { invitationStatus } from "@application/Configurations/status.configuration";
import StatsCircle from "@infrastructure/components/charts/Donut/StatsCircle";
import NumberThousandSeparator from "@infrastructure/components/interface/textFormat/NumberThousandSeparator";
import type { IChartSeriesItem } from "@infrastructure/model/interfaces/chart/chart.series.item.interface";
import { Grid, Stack } from "@mui/material";

interface SubmitedSessionExplainerProps {
	name: string;
	value: number;
	index: number;
}

const SubmitedSessionExplainer = (props: SubmitedSessionExplainerProps) => {
	const { name, value, index } = props;

	return (
		<Stack direction="row" spacing={3} justifyContent="space-between">
			<Stack direction="row" spacing={1} alignItems="center">
				<div
					style={{
						backgroundColor: invitationStatus[index].bgColor,
						borderRadius: "50%",
						width: "12px",
						height: "12px",
					}}
				/>
				<span>
					{t(
						`old.registration.participantDetails.invitationStatus.status.${name}`,
					)}
				</span>
			</Stack>
			<Stack justifyContent="flex-end">
				<NumberThousandSeparator value={value} />
			</Stack>
		</Stack>
	);
};

interface Props {
	statistics: Array<IChartSeriesItem>;
}

const DonutChart = (props: Props) => {
	const { statistics } = props;
	return (
		<>
			{statistics && (
				<Grid
					container
					justifyContent="space-around"
					alignItems="center"
					spacing={3}
				>
					<Grid item>
						<StatsCircle
							sqSize={100}
							strokeWidth={10}
							statistics={statistics}
						/>
					</Grid>
					<Grid item>
						<Stack spacing={1}>
							{statistics.map((item, index) => {
								return (
									<SubmitedSessionExplainer
										key={index}
										{...item}
										index={index}
									/>
								);
							})}
						</Stack>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default DonutChart;
