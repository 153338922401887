import ControllersApp from "@application/Controllers";
import HelpersApp from "@application/Helpers";
import {
	CustomFieldHelper,
	type DtoParticipantGet,
	type DtoRole,
	FormHelper,
	UseFormHelper,
} from "@key4-front-library/core";
import type { PropsFormTabs } from "@key4-front-library/core/Bo/Components/Form/FormTabs";
import ControllersBo from "@key4-front-library/core/Bo/Controllers";
import { EnumCustomFieldScope } from "@key4-front-library/core/Enums";
import type { TypeUseFormListForms } from "@key4-front-library/core/Types";
import { useQuery } from "@tanstack/react-query";

const useEntity = () => {
	const { readWholeFormByScope } =
		ControllersBo.CustomFieldController.useEntity();
	const { read } = ControllersApp.ParticipantController.useEntity();

	const get = (participantId?: string) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: ["participant", participantId],
			queryFn: async (): Promise<DtoParticipantGet> =>
				read(participantId ?? ""),
			enabled: !!participantId,
		});

		return { data, isLoading, refetch };
	};

	const readWholeForm = (
		participantId: string | undefined,
		manualsRoles: Array<DtoRole> | undefined,
	) => {
		const { data, isFetching, refetch } = useQuery({
			queryKey: [
				"participant",
				"form",
				manualsRoles,
				participantId ?? "create",
			],
			queryFn: async (): Promise<{
				componentData: PropsFormTabs;
				useFormData: TypeUseFormListForms;
			}> => {
				const formsData = await readWholeFormByScope(
					EnumCustomFieldScope.PARTICIPANT,
				);

				const useFormData: TypeUseFormListForms = {};

				const componentData: PropsFormTabs = {
					tabs: [],
				};

				// Add Forms / Sections useFormData
				UseFormHelper.mapFormTabsUseFormDefaultValue(formsData, useFormData);

				// Add Static fields useFormData
				HelpersApp.ParticipantHelper.mapFormTabsUseFormDefaultValue(
					formsData,
					useFormData,
				);

				// Add Custom fields useFormData
				CustomFieldHelper.mapFormTabsUseFormDefaultValue(
					formsData,
					useFormData,
				);
				// #endregion 'Useform default values'

				// Add tabs and sections props
				FormHelper.mapFormTabsProps(formsData, componentData);

				// Add static fields props
				// ! SPECIAL RULE FOR EDIT (remove contact selector)
				HelpersApp.ParticipantHelper.mapFormTabsPropsStaticFields(
					formsData,
					componentData,
					participantId,
				);

				// Add custom fields props
				CustomFieldHelper.mapFormTabsPropsFields(formsData, componentData);

				// EDIT PARTICIPANT
				if (participantId) {
					const participant = await read(participantId);
					participant.roles =
						participant.roles?.filter((role) =>
							manualsRoles
								?.map((manualRole) => manualRole.id)
								.includes(role.id),
						) ?? [];

					// Add Static fields useFormData
					HelpersApp.ParticipantHelper.mapFormTabsEditUseFormDefaultValue(
						useFormData,
						participant,
					);

					// Add Custom fields useFormData
					CustomFieldHelper.mapFormTabsEditUseFormDefaultValue(
						useFormData,
						participant.customFieldValues,
					);
				}

				return { componentData, useFormData };
			},
			enabled: !!manualsRoles,
		});
		return { data, isFetching, refetch };
	};

	return { get, readWholeForm };
};

const ParticipantHook = {
	useEntity,
};

export default ParticipantHook;
