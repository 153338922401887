import NumberThousandSeparator from "@infrastructure/components/interface/textFormat/NumberThousandSeparator";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import type { DtoKeyNumber } from "@key4-front-library/core";
import { Box, Stack } from "@mui/material";

interface Props {
	totalCosts: number;
	costCenterKey?: DtoKeyNumber;
}
const K4MatrixCustomCellListOfCosts = (props: Props & dataCypressProp) => {
	const { totalCosts, costCenterKey } = props;
	return (
		<Stack spacing={"0.5rem"} my={"1rem"} alignItems={"flex-end"}>
			<Box>
				<NumberThousandSeparator value={totalCosts} />€
			</Box>
			<Stack alignItems={"flex-end"}>
				{costCenterKey &&
					Object.keys(costCenterKey).map((key, i) => {
						return (
							<Box key={i} color={"text.disabled"}>
								{key}:&nbsp;
								<NumberThousandSeparator value={costCenterKey[key]} />€
							</Box>
						);
					})}
			</Stack>
		</Stack>
	);
};

export default K4MatrixCustomCellListOfCosts;
