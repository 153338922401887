import { Skeleton } from "@mui/material";
import Box from "@mui/material/Box";

const MatrixSkeleton = () => {
	return (
		<Skeleton variant="rectangular" width="100%">
			<Box style={{ paddingTop: "40%" }} />
		</Skeleton>
	);
};

export default MatrixSkeleton;
