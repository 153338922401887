import {
	type DtoDashboardParticipant,
	type DtoReOrder,
	Services,
} from "@key4-front-library/core";

/**
 * Get Dashboard informations
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @typedef DtoDashboardParticipant Dto of Dashboard Participants
 * @returns Dashboard informations
 */
const get = async (
	clientId: string,
	eventId: string,
): Promise<DtoDashboardParticipant> =>
	await Services.Events.Registration.DashboardService.get(clientId, eventId);
/**
 * Pin Role to Dashboard
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param roleId Current role ID
 * @returns Success boolean
 */
const pinRole = async (
	clientId: string,
	eventId: string,
	roleId: string,
): Promise<boolean> =>
	await Services.Events.Registration.DashboardService.putPinRole(
		clientId,
		eventId,
		{
			id: roleId,
			isPinned: true,
		},
	);

/**
 * Unpin Role to Dashboard
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param roleId Current role ID
 * @returns Success boolean
 */
const unPinRole = async (
	clientId: string,
	eventId: string,
	roleId: string,
): Promise<boolean> =>
	await Services.Events.Registration.DashboardService.putPinRole(
		clientId,
		eventId,
		{
			id: roleId,
			isPinned: false,
		},
	);
/**
 * Update Dashboard pined Roles Order
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param roleIds list sorted of Roles
 * @returns  Success boolean
 */
const updateRolesOrders = async (
	clientId: string,
	eventId: string,
	roleIds: DtoReOrder,
): Promise<boolean> =>
	await Services.Events.Registration.DashboardService.putUpdateRoleOrder(
		clientId,
		eventId,
		roleIds,
	);

/**
 * Dashboard Controller
 */
const DashboardController = {
	get,
	pinRole,
	unPinRole,
	updateRolesOrders,
};

export default DashboardController;
