import {
	type DtoContact,
	escapeSieveString,
	ESieveOperator,
	queryIncludeCustomFields,
	queryMultipleOperatorFilters,
	Services,
	type TypeApiQueryString,
	type TypeApiResponsePagined,
} from "@key4-front-library/core";

/**
 * Get List Pagined of Benefits
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param queryStrings QueryStrings
 * @typedef TypeApiQueryString Type of QueryString
 * @returns List of Benefit
 */
const readList = async (
	clientId: string,
	eventId: string,
	queryStrings: Array<TypeApiQueryString> = [],
): Promise<TypeApiResponsePagined<Array<DtoContact>>> =>
	await Services.Operations.Registration.ContactsService.getListPagined(
		clientId,
		eventId,
		queryStrings,
	);

/**
 * Search a contact by email
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param email email to search
 * @returns List of Contacts
 */
const getContactsByEmail = async (
	clientId: string,
	eventId: string,
	email: string,
) =>
	await Services.Operations.Registration.ContactsService.getListPagined(
		clientId,
		eventId,
		[
			...queryMultipleOperatorFilters([
				{
					key: "email",
					operator: ESieveOperator.EQUALS,
					value: escapeSieveString(email),
				},
			]),
			...queryIncludeCustomFields(true),
		],
	);

/**
 * Contact Controller
 */
const ContactController = {
	readList,
	getContactsByEmail,
};

export default ContactController;
