import { t } from "i18next";

import { Card } from "@key4-front-library/core/Bo/Components";
import type { DtoRole } from "@key4-front-library/core/Dto";

import Tags from "./details/roleTags/Tags";

interface PropsParticipantRoleCard {
	roles: Array<DtoRole>;
}

const ParticipantRolesCard = (props: PropsParticipantRoleCard) => {
	const { roles } = props;

	const translations = {
		roles: t("old.registration.participantDetails.roles"),
	};

	return (
		<Card title={translations.roles}>
			<Tags roles={roles} />
		</Card>
	);
};

export default ParticipantRolesCard;
