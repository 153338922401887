import {
	EnumParticipantFieldKey,
	EnumParticipantFormKey,
	EnumParticipantSectionKey,
} from "@application/Enums/ParticipantEnum";
import type { TypeParticipantStaticField } from "@application/Types/ParticipantType";
import {
	EBulkActionAction,
	EnumFormControlKind,
	EnumParticipantStatusInvitation,
	getCellBulletColorWithLabel,
} from "@key4-front-library/core";
import type { GridColDef } from "@mui/x-data-grid-pro";

import { ApplicationColors } from "./color.configuration";

export type ParticipantInvitationStatus =
	| EnumParticipantStatusInvitation.UNSENT
	| EnumParticipantStatusInvitation.WAITING_FOR_ANSWER
	| EnumParticipantStatusInvitation.ACCEPTED
	| EnumParticipantStatusInvitation.DECLINED
	| EnumParticipantStatusInvitation.CANCELLED;

const statusObject: {
	[key in ParticipantInvitationStatus]: {
		value: EnumParticipantStatusInvitation;
		label: string;
		bgColor: string;
		fontColor?: string;
	};
} = {
	[EnumParticipantStatusInvitation.UNSENT]: {
		value: EnumParticipantStatusInvitation.UNSENT,
		label: "old.registration.participantDetails.invitationStatus.status.unsent",
		bgColor: ApplicationColors.greyLight.main,
		fontColor: ApplicationColors.greyLight.contrastText,
	},
	[EnumParticipantStatusInvitation.WAITING_FOR_ANSWER]: {
		value: EnumParticipantStatusInvitation.WAITING_FOR_ANSWER,
		label:
			"old.registration.participantDetails.invitationStatus.status.waitingForAnswer",
		bgColor: ApplicationColors.blueLight.dark!,
		fontColor: ApplicationColors.blueLight.contrastText,
	},
	[EnumParticipantStatusInvitation.ACCEPTED]: {
		value: EnumParticipantStatusInvitation.ACCEPTED,
		label:
			"old.registration.participantDetails.invitationStatus.status.accepted",
		bgColor: ApplicationColors.green.main,
		fontColor: ApplicationColors.white.main,
	},
	[EnumParticipantStatusInvitation.DECLINED]: {
		value: EnumParticipantStatusInvitation.DECLINED,
		label:
			"old.registration.participantDetails.invitationStatus.status.declined",
		bgColor: ApplicationColors.red.main,
		fontColor: ApplicationColors.white.main,
	},
	[EnumParticipantStatusInvitation.CANCELLED]: {
		value: EnumParticipantStatusInvitation.CANCELLED,
		label:
			"old.registration.participantDetails.invitationStatus.status.cancelled",
		bgColor: ApplicationColors.veryDarkBlue.main,
		fontColor: ApplicationColors.veryDarkBlue.contrastText,
	},
};

const statusArray = [
	EnumParticipantStatusInvitation.UNSENT,
	EnumParticipantStatusInvitation.WAITING_FOR_ANSWER,
	EnumParticipantStatusInvitation.ACCEPTED,
	EnumParticipantStatusInvitation.DECLINED,
	EnumParticipantStatusInvitation.CANCELLED,
];

const staticListFormControlsObject: {
	[key in EnumParticipantFormKey]: {
		[sectionKey in EnumParticipantSectionKey]?: TypeParticipantStaticField;
	};
} = {
	[EnumParticipantFormKey.PROFILE_ROLES]: {
		[EnumParticipantSectionKey.MAIN]: {
			[EnumParticipantFieldKey.PROFILE_ID]: {
				value: null,
				component: {
					id: EnumParticipantFieldKey.PROFILE_ID,
					kind: EnumFormControlKind.CONTAINER_PROFILE_SELECT,
					propsComponent: {
						label:
							"old.registration.participants.edition.tabs.profile-and-roles.profile",
					},
					gridSize: { xs: 12, md: 6, lg: 6 },
				},
			},
			[EnumParticipantFieldKey.ROLES_IDS]: {
				value: [],
				component: {
					id: EnumParticipantFieldKey.ROLES_IDS,
					kind: EnumFormControlKind.CONTAINER_ROLES_AUTOCOMPLETE_MULTIPLE,
					propsComponent: {
						label:
							"old.registration.participants.edition.tabs.profile-and-roles.roles",
					},
					gridSize: { xs: 12, md: 6, lg: 6 },
				},
			},
		},
	},
	[EnumParticipantFormKey.INVITATION]: {
		[EnumParticipantSectionKey.MAIN]: {
			[EnumParticipantFieldKey.INVITATION_STATUS]: {
				value: EnumParticipantStatusInvitation.UNSENT,
				component: {
					id: EnumParticipantFieldKey.INVITATION_STATUS,
					kind: EnumFormControlKind.SELECT_BULLET_COLOR,
					propsComponent: {
						label:
							"old.registration.participants.model.invitation-status.label",
						items: statusArray.map(
							(statusEnum: EnumParticipantStatusInvitation) => {
								const s = statusEnum as ParticipantInvitationStatus;
								return {
									key: statusObject[s].value,
									label: statusObject[s].label,
									color: statusObject[s].bgColor,
								};
							},
						),
						isNullable: false,
					},
					gridSize: { xs: 12, md: 12, lg: 12 },
				},
			},
		},
	},
	[EnumParticipantFormKey.GENERAL]: {
		[EnumParticipantSectionKey.MAIN]: {
			[EnumParticipantFieldKey.PARTICIPANT_OR_CONTACT]: {
				value: null,
				component: {
					id: EnumParticipantFieldKey.PARTICIPANT_OR_CONTACT,
					kind: EnumFormControlKind.AUTOCOMPLETE_CONTACT_OR_PARTICIPANT,
					propsComponent: {
						required: true,
						areParticipantsActivated: false,
					},

					gridSize: { xs: 12, md: 12, lg: 12 },
				},
			},
			[EnumParticipantFieldKey.FIRSTNAME]: {
				value: null,
				component: {
					id: EnumParticipantFieldKey.FIRSTNAME,
					kind: EnumFormControlKind.TEXT_FIELD,
					propsComponent: {
						label: "old.registration.participants.model.firstname.label",
						disabled: true,
					},
					gridSize: { xs: 12, md: 6, lg: 6 },
				},
			},
			[EnumParticipantFieldKey.LASTNAME]: {
				value: null,
				component: {
					id: EnumParticipantFieldKey.LASTNAME,
					kind: EnumFormControlKind.TEXT_FIELD,
					propsComponent: {
						label: "old.registration.participants.model.lastname.label",
						disabled: true,
					},
					gridSize: { xs: 12, md: 6, lg: 6 },
				},
			},
			[EnumParticipantFieldKey.EMAIL]: {
				value: null,
				component: {
					id: EnumParticipantFieldKey.EMAIL,
					kind: EnumFormControlKind.TEXT_FIELD,
					propsComponent: {
						label: "old.registration.participants.model.email.label",
						disabled: true,
					},
					gridSize: { xs: 12, md: 12, lg: 12 },
				},
			},
		},
	},
};

const datagridHeaders: Array<GridColDef> = [
	{ field: "key", minWidth: 150 },
	{ field: "firstname", minWidth: 300 },
	{ field: "lastname", minWidth: 300 },
	{ field: "email", minWidth: 500 },
	{
		field: "invitationStatus",
		renderCell: getCellBulletColorWithLabel,
		minWidth: 200,
		flex: 4,
	},
];

const bulkActionActions: Array<EBulkActionAction> = [
	EBulkActionAction.EDIT,
	EBulkActionAction.TRANSITION,
];

const sieveKeys = [
	"key",
	"firstname",
	"lastname",
	"email",
	"profile.name",
	"participantroles.name",
];

const ParticipantConfiguration = {
	bulkActionActions,
	datagridHeaders,
	staticListFormControlsObject,
	statusArray,
	statusObject,
	sieveKeys,
};

export default ParticipantConfiguration;
