import { t } from "i18next";
import type { MouseEventHandler } from "react";

import K4ButtonExport from "../../../infrastructure/components/interface/button/K4ButtonExport";

interface Props {
	isDisabled?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement>;
}

const K4ButtonExportDecorated = (props: Props) => {
	const { isDisabled = false, onClick } = props;

	return (
		<K4ButtonExport
			label={t("old.common.button.export")}
			isDisabled={isDisabled}
			onClick={onClick}
		/>
	);
};

export default K4ButtonExportDecorated;
