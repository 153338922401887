import { t } from "i18next";
import { useEffect, useState } from "react";

import RoleEditionForm from "@application/Components/settings/roles/RoleEditionForm";
import Roles from "@application/Configurations/roles.configuration";
import RoleController from "@application/Controllers/RoleController";
import AppBox from "@infrastructure/components/interface/box/AppBox";
import DefaultModal from "@infrastructure/components/interface/modals/DefaultModal";
import SimpleTextSearchFilter from "@infrastructure/components/interface/search/SimpleTextSearchFilter";
import Title from "@infrastructure/components/interface/titles/Title";
import {
	ActionReference,
	ConfirmationModal,
	CustomPagination,
	DataGridOld,
	DefaultPage,
	type DtoRole,
	getColumns,
	getIcon,
	queryFilters,
	queryStringPagination,
	Services,
	useContextModule,
	usePaginationOld,
	useSnackBarHook,
} from "@key4-front-library/core";
import type { PropsDataGridCellButtons } from "@key4-front-library/core/Bo/Components/DataGrid/components/cell/DataGridCellButtons";
import {
	EnumActionsReference,
	EnumRoleScope,
	EnumRoleType,
	ESieveOperator,
} from "@key4-front-library/core/Enums";
import { EConfirmationModalAction } from "@key4-front-library/core/Enums/Modal";
import { useLoadFullData } from "@key4-front-library/core/Hooks";
import { Box, Grid } from "@mui/material";
import type { GridValidRowModel } from "@mui/x-data-grid";
import type { GridSortModel } from "@mui/x-data-grid-pro";

const PageSettingsRoles = () => {
	const { client, event } = useContextModule();
	const {
		page,
		pageSize,
		totalRowCount,
		handleChangePage,
		handleChangePageSize,
		setTotalRowCount,
	} = usePaginationOld();
	const { sendSuccess, sendError } = useSnackBarHook();

	const [search, setSearch] = useState<string>("");
	const [isOpenCreationAndEditionModal, setIsOpenCreationAndEditionModal] =
		useState<boolean>(false);
	const [isNew, setIsNew] = useState<boolean>(false);
	const [id, setId] = useState<string>();
	const [hasOpenDeleteModal, setHasOpenDeleteModal] = useState<boolean>(false);
	const [rows, setRows] = useState<Array<GridValidRowModel>>([]);
	const [currentRole, setCurrentRole] = useState<DtoRole | undefined>();

	const [gridSortModelList, setGridSortModelList] = useState<GridSortModel>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [
		externalRolesAffectedToParticipants,
		setExternalRolesAffectedToParticipants,
	] = useState<Array<DtoRole>>([]);
	const [nonExternalRoles, setNonExternalRoles] = useState<Array<DtoRole>>([]);
	const [rolesToDisplay, setRolesToDisplay] = useState<Array<DtoRole>>([]);

	const translations = {
		title: t("old.registration.settings.roles.index.title"),
		programme: t("old.registration.settings.roles.index.programme"),
		manual: t("old.registration.settings.roles.index.manual"),
		editionModal: {
			title: t(
				`old.registration.settings.roles.edition.title.${isNew ? "creation" : "modification"}`,
			),
			returnMessages: {
				success_creation: t(
					"old.registration.settings.roles.edition.returnMessages.success_creation",
				),
				success_modification: t(
					"old.registration.settings.roles.edition.returnMessages.success_modification",
				),
				success_deletion: t(
					"old.registration.settings.roles.edition.returnMessages.success_deletion",
				),
				error_generic: t("old.common.errors.generic"),
				error_role_already_exist: t(
					"old.registration.settings.roles.edition.returnMessages.error_role_already_exist",
				),
			},
		},
		buttons: {
			add: t("old.form.buttons.add"),
			edit: t("old.form.buttons.edit"),
			delete: t("old.form.buttons.delete"),
		},
		sort: {
			label: t("old.common.searchList.sort"),
			name: t("old.registration.settings.roles.sort.name"),
			origin: t("old.registration.settings.roles.sort.origin"),
		},
	};

	const getTranslatedColumnLabels = (): Array<string> => {
		const headersNames: Array<string> = [];
		Roles.datagridHeaders.forEach((role) =>
			headersNames.push(
				t(
					`old.registration.settings.roles.datagrid.headerLabels.${role.field}`,
				),
			),
		);
		return headersNames;
	};

	const handleFilterChange = (value: string) => {
		setSearch(value);
		handleChangePage(DefaultPage);
	};

	const getActionButtons = (data: DtoRole): PropsDataGridCellButtons => {
		if (data.type !== EnumRoleType.MANUAL) {
			return { listButtons: [] };
		}
		return {
			listButtons: [
				{
					...ActionReference[EnumActionsReference.EDIT],
					onClick: () => {
						handleUpdateRole(data);
					},
				},
				{
					...ActionReference[EnumActionsReference.DELETE],
					onClick: () => {
						deleteRole(data.id);
					},
				},
			],
		};
	};

	const deleteRole = (id: string) => {
		setHasOpenDeleteModal(true);
		setId(id);
	};

	const onDelete = (): void => {
		if (id) {
			handleDeleteRole(id);
		}
		setHasOpenDeleteModal(false);
		setId(undefined);
	};

	const handleChangeSortCriteria = (gridSortModelList: GridSortModel) => {
		setGridSortModelList(gridSortModelList);
	};

	const handleChangeGrid = (newPage: number) => {
		let filteredRoles = rolesToDisplay;

		// If there's a search term, apply the filter
		if (search) {
			filteredRoles = filteredRoles.filter((role) => {
				return role.name?.toLowerCase().includes(search.toLowerCase());
			});
			setTotalRowCount(filteredRoles.length);
		}

		if (gridSortModelList.length > 0) {
			filteredRoles.sort((a, b) => {
				for (const sortModel of gridSortModelList) {
					const { field, sort } = sortModel;

					// @ts-expect-error TODO TM Clean this
					if (a[field] < b[field]) {
						return sort === "asc" ? -1 : 1;
					}
					// @ts-expect-error TODO TM Clean this
					if (a[field] > b[field]) {
						return sort === "asc" ? 1 : -1;
					}
				}
				return 0;
			});
		}

		const data: Array<GridValidRowModel> = [];

		filteredRoles
			.slice(newPage * pageSize, newPage * pageSize + pageSize)
			.forEach((role: DtoRole) => {
				data.push({
					...role,
					iconImage: role.icon && getIcon(role.icon),
					actionButtons: getActionButtons(role),
				});
			});

		setRows(data);
	};

	const getParticipants = async (page: number, limit: number) => {
		return await Services.Events.Registration.ParticipantsService.getListPagined(
			client.id,
			event.id,
			[
				...queryFilters(
					["participantroles.name", ESieveOperator.CI_CONTAINS, search].join(
						"",
					),
				),
				...queryStringPagination({
					page,
					pageSize: limit,
				}),
			],
		);
	};

	const { data: participants } = useLoadFullData(getParticipants);

	const getExternalRolesAffectedToParticipants = async (): Promise<void> => {
		const externalRolesAffectedToParticipants: Array<DtoRole> =
			participants.reduce((accumulatedRoles: Array<DtoRole>, participant) => {
				if (participant.roles) {
					participant.roles.forEach((role) => {
						if (
							!accumulatedRoles.find(
								(accumulatedRole) => accumulatedRole.id === role.id,
							) &&
							role.type === EnumRoleType.EXTERNAL
						) {
							accumulatedRoles.push(role);
						}
					});
				}
				return accumulatedRoles;
			}, []);

		setExternalRolesAffectedToParticipants(externalRolesAffectedToParticipants);
	};

	const getNonExternalRolesList = async () => {
		const rolesList = await RoleController.getList(
			client.id,
			event.id,
			["type", ESieveOperator.CI_NOT_EQUALS, EnumRoleType.EXTERNAL].join(""),
			[],
		);
		setNonExternalRoles(rolesList);
	};

	const getRolesToDisplay = (): Array<DtoRole> => {
		const rolesToDisplay: Array<DtoRole> = [
			...externalRolesAffectedToParticipants,
			...nonExternalRoles,
		];
		setRolesToDisplay(rolesToDisplay);
		return rolesToDisplay;
	};

	const handleSaveRole = async (isNew: boolean, role: DtoRole) => {
		const data = { ...role, scope: EnumRoleScope.PARTICIPANT };

		if (isNew) {
			await RoleController.create(client.id, event.id, data)
				.then(() => {
					sendSuccess(
						translations.editionModal.returnMessages.success_creation,
					);
					setIsOpenCreationAndEditionModal(false);
				})
				.catch((responseError) => {
					displayErrorSnackbar(responseError.status);
				});
		} else {
			await RoleController.update(client.id, event.id, data)
				.then(() => {
					sendSuccess(
						translations.editionModal.returnMessages.success_modification,
					);
					setIsOpenCreationAndEditionModal(false);
				})
				.catch((responseError) => {
					displayErrorSnackbar(responseError.status);
				});
		}
		getNonExternalRolesList();
	};

	const handleUpdateRole = (role: DtoRole) => {
		handleOpenModalForm(false, role);
	};

	const handleDeleteRole = async (id: string) => {
		await RoleController.deleteEntity(client.id, event.id, id)
			.then(() => {
				sendSuccess(translations.editionModal.returnMessages.success_deletion);
			})
			.catch((responseError) => {
				displayErrorSnackbar(responseError.status);
			});

		getNonExternalRolesList();
	};

	const displayErrorSnackbar = (errorStatus: number) => {
		if (errorStatus === 422) {
			sendError(
				translations.editionModal.returnMessages.error_role_already_exist,
			);
		} else {
			sendError(translations.editionModal.returnMessages.error_generic);
		}
	};

	const handleOpenModalForm = (isNew: boolean, role?: DtoRole) => {
		setIsOpenCreationAndEditionModal(true);
		setIsNew(isNew);
		setCurrentRole(undefined);
		if (!isNew && role) {
			setCurrentRole(role);
		}
	};

	const handleModaleClose = () => {
		setIsOpenCreationAndEditionModal(false);
	};

	useEffect(() => {
		getExternalRolesAffectedToParticipants();
	}, [participants]);

	useEffect(() => {
		getExternalRolesAffectedToParticipants();
		getNonExternalRolesList();
	}, [event, search, isLoading]);

	useEffect(() => {
		const rolesToDisplay = getRolesToDisplay();
		setTotalRowCount(rolesToDisplay.length);
		setIsLoading(false);
	}, [externalRolesAffectedToParticipants, nonExternalRoles]);

	useEffect(() => {
		handleChangeGrid(page);
	}, [event, page, pageSize, search, gridSortModelList, rolesToDisplay]);

	return (
		<Box marginTop={"-130px"}>
			<Title
				title={""}
				buttons={[
					{
						label: translations.buttons.add,
						startIcon: "fa-plus",
						handleClick: () => {
							handleOpenModalForm(true);
						},
					},
				]}
			/>
			<AppBox>
				<Grid container spacing={2} sx={{ mb: 7 }}>
					<Grid item xs={12}>
						<SimpleTextSearchFilter handleChange={handleFilterChange} />
					</Grid>
					<Grid item xs={12}>
						{!isLoading && rows && (
							<DataGridOld
								columns={getColumns(
									getTranslatedColumnLabels(),
									Roles.datagridHeaders,
								)}
								rows={rows}
								isAutoHeight={true}
								handleChangeSortCriteria={handleChangeSortCriteria}
								pagination={{
									totalRowCount,
									page,
									pageSize,
									customPagination: () => {
										return CustomPagination({
											pageSize,
											totalRowCount,
											handleChangePage,
											handleChangePageSize,
										});
									},
								}}
							/>
						)}
					</Grid>
				</Grid>
			</AppBox>

			<DefaultModal
				open={isOpenCreationAndEditionModal}
				title={translations.editionModal.title}
				maxWidth={"sm"}
			>
				<RoleEditionForm
					isNew={isNew}
					role={currentRole}
					handleSaveRole={handleSaveRole}
					handleModaleClose={handleModaleClose}
				/>
			</DefaultModal>

			<ConfirmationModal
				open={hasOpenDeleteModal}
				text={t(
					"old.registration.settings.roles.modal.confirmation.delete.message",
				)}
				action={EConfirmationModalAction.DELETE}
				handleModaleClose={() => {
					setHasOpenDeleteModal(false);
					setId(undefined);
				}}
				handleAction={onDelete}
				maxWidth={"sm"}
			/>
		</Box>
	);
};

export default PageSettingsRoles;
