import {
	EnumParticipantFormKey,
	EnumParticipantSectionKey,
} from "@application/Enums/ParticipantEnum";
import {
	Card,
	CustomFieldsDisplayer,
} from "@key4-front-library/core/Bo/Components";
import type {
	DtoCustomFieldFormV01,
	DtoParticipantGet,
} from "@key4-front-library/core/Dto";
import { Stack } from "@mui/material";

import ParticipationParticipantFields from "./ParticipationParticipantFields";

interface PropsParticipationForm {
	form: DtoCustomFieldFormV01;
	formOrder: number;
	participant: DtoParticipantGet;
}

const ParticipationForm = (props: PropsParticipationForm) => {
	const { form, formOrder, participant } = props;
	const { customFieldValues } = participant;

	const displayParticipantFields = (sectionKey: string) =>
		form.key === EnumParticipantFormKey.GENERAL &&
		sectionKey === EnumParticipantSectionKey.MAIN;

	return (
		<Stack spacing={5}>
			{form.sections?.map((section) => {
				const sectionOrder = form.sections?.indexOf(section);
				return (
					sectionOrder !== undefined && (
						<Card key={section.key} title={section.label} sx={{ m: 0 }}>
							<Stack spacing={2}>
								{displayParticipantFields(section.key) && (
									<ParticipationParticipantFields participant={participant} />
								)}
								<CustomFieldsDisplayer
									formOrder={formOrder}
									sectionOrder={sectionOrder}
									values={customFieldValues}
								/>
							</Stack>
						</Card>
					)
				);
			})}
		</Stack>
	);
};

export default ParticipationForm;
