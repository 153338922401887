import { DateTime } from "luxon";

// TODO Pass culture to formatters
const defaultCultureCode = "en-gb";

interface DateType {
	date: DateTime;
	culture?: string;
}

const GetDateFromIsoString = (text: string): DateTime => {
	return DateTime.fromISO(text, {
		zone: "utc",
	});
};

interface GetDateFormatedToISOType {
	date: Date;
}
const GetDateFormatedToISO = (props: GetDateFormatedToISOType) => {
	const { date } = props;
	const { year, month, day } = DateTime.fromJSDate(date).toObject();
	return DateTime.utc(year!, month!, day!);
};

const ShortDate = (props: DateType): string => {
	const { date, culture = defaultCultureCode } = props;
	return date
		? date.toUTC().setLocale(culture).toLocaleString(DateTime.DATE_SHORT)
		: "(Invalid date)";
};

const IsoStringDate = (props: DateType) => {
	const { date } = props;
	const { year, month, day, hour, minute, second } = date.toUTC().toObject();
	return DateTime.utc(year!, month!, day!, hour!, minute!, second!).toISO();
};

const FullDate = (props: DateType): string => {
	const { date, culture = defaultCultureCode } = props;
	return date.toLocaleString(DateTime.DATE_FULL, { locale: culture });
};

interface GetDateFormatedFromDateObjectType {
	date: Date;
	culture?: string;
}
const GetDateFormatedFromDateObject = (
	props: GetDateFormatedFromDateObjectType,
) => {
	const { date, culture = defaultCultureCode } = props;
	return DateTime.fromJSDate(date).setLocale(culture).toLocaleString();
};

interface GetDateFormatedFromIsoType {
	date: string;
	culture?: string;
}
const GetDateFormatedFromIso = (props: GetDateFormatedFromIsoType) => {
	const { date, culture = defaultCultureCode } = props;
	return DateTime.fromISO(date).setLocale(culture).toLocaleString();
};

const DateTimeToyyyyMMdd = (date: DateTime): string => {
	return date.toFormat("yyyy-MM-dd");
};

const DateTimeToyyyyMMddhhmm = (date: DateTime): string => {
	return date.toFormat("yyyyMMddhhmm");
};

const IsValidDateNullableyyyyMMdd = (value: DateTime | null): boolean => {
	if (value === null) {
		return true;
	}
	return value.toFormat("yyyy-MM-dd") !== "Invalid DateTime";
};

const DateNow = DateTime.now();

const Convert = { GetDateFromIsoString, GetDateFormatedToISO };

const Format = {
	ShortDate,
	IsoStringDate,
	FullDate,
	GetDateFormatedFromDateObject,
	GetDateFormatedFromIso,
	DateTimeToyyyyMMdd,
	DateTimeToyyyyMMddhhmm,
};

const DateService = {
	Format,
	Convert,
	IsValidDateNullableyyyyMMdd,
	DateNow,
};

export default DateService;
