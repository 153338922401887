import { t } from "i18next";

import { invitationStatus } from "@application/Configurations/status.configuration";
import type { IStatus } from "@domain/model/status.interface";
import K4StatusBulletLegend from "@infrastructure/components/interface/icons/K4StatusBulletLegend";
import type { DtoParticipantGet } from "@key4-front-library/core/Dto";
import { Box } from "@mui/material";
import type {
	GridColDef,
	GridColumnHeaderParams,
	GridRenderCellParams,
	GridValueGetterParams,
} from "@mui/x-data-grid";
import type { GridInitialStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity";

import type { IGridModel } from "../@types/components/datagrid";

const GridInitialState: GridInitialStateCommunity = {
	columns: {
		columnVisibilityModel: {
			id: false,
		},
	},
};

const customeRenderCell = (params: GridRenderCellParams) => {
	const { value } = params;
	return (
		<Box
			className="MuiDataGrid-cellContent"
			data-cy={`grid-cell-${params.colDef.field}-${params.id}`}
		>
			{value}
		</Box>
	);
};

const customRenderHeader = (params: GridColumnHeaderParams) => {
	const { colDef } = params;
	return (
		<Box
			className="MuiDataGrid-columnHeaderTitle"
			data-cy={`grid-header-${colDef.field}`}
		>
			{`${colDef.headerName}`}
		</Box>
	);
};

const defaultGridColDef: GridColDef = {
	field: "",
	renderHeader: customRenderHeader,
	renderCell: customeRenderCell,
	minWidth: 150,
	editable: false,
	filterable: false,
	hideable: false,
	flex: 1,
};

const invitationStatusRenderCell = (params: GridRenderCellParams) => {
	const { row } = params;
	return (
		<K4StatusBulletLegend
			text={t(
				`old.registration.participantDetails.invitationStatus.status.${row.invitationStatus}`,
			).toString()}
			bullet={{
				color:
					invitationStatus.find(
						(e: IStatus) => e.value === row.invitationStatus,
					)?.bgColor ?? "red",
			}}
		/>
	);
};

const GetGridColDefinition = (
	modelDefinition: DtoParticipantGet,
): Array<GridColDef> => {
	return [
		{
			...defaultGridColDef,
			field: "id",
			headerName: modelDefinition.id,
			minWidth: 300,
			sortable: true,
			hideable: true,
		},
		{
			...defaultGridColDef,
			field: "key",
			headerName: modelDefinition.key,
		},
		{
			...defaultGridColDef,
			field: "firstname",
			headerName: modelDefinition.firstname ?? "firstname",
		},
		{
			...defaultGridColDef,
			field: "lastname",
			headerName: modelDefinition.lastname ?? "lastname",
		},
		{
			...defaultGridColDef,
			field: "email",
			headerName: modelDefinition.email ?? "email",
			minWidth: 250,
		},
		{
			...defaultGridColDef,
			field: "profile.name",
			headerName: "Profile",
			valueGetter: (params: GridValueGetterParams) =>
				params.row.profile?.name ?? "",
		},
		{
			...defaultGridColDef,
			field: "participantroles.name",
			headerName:
				t("old.registration.participants.model.roles.label") || "Roles",
			valueGetter: (params: GridValueGetterParams) => {
				if (params.row.roles) {
					return `${params.row.roles.map((role: any) => role.name).join(", ")} `;
				}
				return "";
			},
		},
		{
			...defaultGridColDef,
			field: "invitationStatus",
			headerName: modelDefinition.invitationStatus,
			minWidth: 250,
			renderCell: (params: GridRenderCellParams) =>
				invitationStatusRenderCell(params),
		},
	];
};

const GetParticipantsGridModel = (
	modelDefinition: DtoParticipantGet,
): IGridModel => ({
	initialState: GridInitialState,
	columnsDefinition: GetGridColDefinition(modelDefinition),
});

export default GetParticipantsGridModel;
