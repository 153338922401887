import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import { Grid, Stack, Typography } from "@mui/material";

interface IconCardItemProps {
	label: string;
	value: string;
}
const IconCardItem = (props: IconCardItemProps & dataCypressProp) => {
	const { label, value, dataCypressID } = props;
	return (
		<Grid item xs={12} md={6} lg={3}>
			<Stack>
				<Typography
					component={"span"}
					variant={"body2"}
					color={"text.secondary"}
					data-cy={dataCypressID ? `${dataCypressID}_label` : undefined}
				>
					{label}
				</Typography>
				<Typography
					component={"span"}
					variant={"body1"}
					data-cy={dataCypressID ? `${dataCypressID}_value` : undefined}
				>
					{value}
				</Typography>
			</Stack>
		</Grid>
	);
};

export default IconCardItem;
