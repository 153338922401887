import { t } from "i18next";

import IconCardItem from "@infrastructure/components/interface/card/IconCardItem";
import type { DtoParticipantGet } from "@key4-front-library/core/Dto";
import { Grid } from "@mui/material";

interface PropsParticipationParticipantFields {
	participant: DtoParticipantGet;
}

const ParticipationParticipantFields = (
	props: PropsParticipationParticipantFields,
) => {
	const { participant } = props;
	const { firstname, lastname, email } = participant;

	const translation = {
		firstname: t("old.registration.participants.model.firstname.label"),
		lastname: t("old.registration.participants.model.lastname.label"),
		email: t("old.registration.participants.model.email.label"),
	};

	return (
		<Grid container sx={{ marginTop: "10px" }} spacing={2}>
			<Grid item xs={4}>
				<IconCardItem label={translation.firstname} value={firstname ?? ""} />
			</Grid>
			<Grid item xs={4}>
				<IconCardItem label={translation.lastname} value={lastname ?? ""} />
			</Grid>
			<IconCardItem label={translation.email} value={email ?? ""} />
		</Grid>
	);
};

export default ParticipationParticipantFields;
