import {
	faArrowRight,
	faBadgeCheck,
	faBars,
	faBed,
	faBooks,
	faBrowser,
	faCalendarLinesPen,
	faCircle,
	faCircleInfo,
	faCirclePlay,
	faCircleQuestion,
	faEnvelope,
	faEnvelopeDot,
	faEnvelopeOpen,
	faEnvelopeCircleCheck,
	faFileChartColumn,
	faFileLines,
	faHandshake,
	faHouse,
	faIdCard,
	faIdCardClip,
	faLandmark,
	faMessages,
	faMobileButton,
	faPersonCircleCheck,
	faSignalStream,
	faStore,
	faThumbtack,
	faTriangleExclamation,
	faUserTie,
} from "@fortawesome/pro-solid-svg-icons";

const SolidSet = {
	faArrowRight,
	faBadgeCheck,
	faBars,
	faBed,
	faBooks,
	faBrowser,
	faCalendarLinesPen,
	faCircle,
	faCircleInfo,
	faCirclePlay,
	faCircleQuestion,
	faEnvelope,
	faEnvelopeDot,
	faEnvelopeOpen,
	faEnvelopeCircleCheck,
	faFileChartColumn,
	faFileLines,
	faHandshake,
	faHouse,
	faIdCard,
	faIdCardClip,
	faLandmark,
	faMessages,
	faMobileButton,
	faPersonCircleCheck,
	faSignalStream,
	faStore,
	faThumbtack,
	faTriangleExclamation,
	faUserTie,
};

export default SolidSet;
