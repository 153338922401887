import { IconOld } from "@key4-front-library/core";
import { Stack, Typography, useTheme } from "@mui/material";

export interface ParticipantMailListTargetProps {
	target: string;
}

export const ParticipantMailTarget = (
	props: ParticipantMailListTargetProps,
) => {
	const { target } = props;

	const theme = useTheme();

	let icon = "";
	switch (target) {
		case "event":
			icon = "user";
			break;
		case "contact":
			icon = "user";
			break;
		case "participant":
			icon = "id-card-clip";
			break;
		case "faculty":
			icon = "user-group";
			break;
		case "chair":
			icon = "user-crown";
			break;
		case "speaker":
			icon = "chalkboard-user";
			break;
		case "schedular":
			icon = "calendar-circle-plus";
			break;
		case "session":
			icon = "clipboard-list";
			break;
		case "presentation":
			icon = "podium";
			break;
		default:
			icon = "circle";
			break;
	}

	return (
		<Stack
			sx={{
				width: "50%",
				color: theme.palette.primary.light,
				textTransform: "capitalize",
			}}
			direction="row"
			gap="5px"
		>
			<IconOld prefix="fas" iconName={icon} />
			<Typography variant={"caption"}>{target}</Typography>
		</Stack>
	);
};
