import {
	FacultyHelper,
	getCellBulletColorWithLabel,
} from "@key4-front-library/core";
import type { GridColDef } from "@mui/x-data-grid-pro";

const invitationsDatagridHeaders: Array<GridColDef> = [
	{
		field: "session",
		minWidth: 450,
		flex: 3,
		sortable: false,
		disableColumnMenu: true,
		renderCell: FacultyHelper.renderSessionCell,
	},
	{
		field: "presentation",
		flex: 3,
		minWidth: 300,
		sortable: false,
		disableColumnMenu: true,
		renderCell: FacultyHelper.renderPresentationCell,
	},
	{ field: "invitationType", width: 150, flex: 1 },
	{
		field: "status",
		renderCell: getCellBulletColorWithLabel,
		minWidth: 150,
		flex: 1,
		sortComparator: (a, b) => {
			const labelA = a.label;
			const labelB = b.label;
			return labelA.localeCompare(labelB);
		},
	},
];

const ParticipantDetails = {
	invitationsDatagridHeaders,
};

export default ParticipantDetails;
