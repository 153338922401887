import { t } from "i18next";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useParams } from "react-router";

import ParticipantController from "@application/Controllers/ParticipantController";
import K4Button, {
	ButtonCancel,
	ButtonDelete,
	EButtonColor,
	EButtonVariant,
} from "@infrastructure/components/interface/uikit/K4Button";
import { useContextModule } from "@key4-front-library/core";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
	Typography,
} from "@mui/material";

interface Props {
	isBenefitsMatrixOverrided: boolean;
	onAddTotalBenefitButtonClick: () => void;
}
export default function FooterAddCustomBenefit(props: Props) {
	const { isBenefitsMatrixOverrided, onAddTotalBenefitButtonClick } = props;
	const { client, event } = useContextModule();
	const { participantId } = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

	const translations = {
		customTotalBenefits: t(
			"old.registration.privileges.benefitsMatrix.customTotalBenefits",
		),
		modal: {
			buttons: {
				delete: t("old.form.buttons.delete"),
				cancel: t("old.form.buttons.cancel"),
			},
			labels: {
				content: t(
					"old.registration.privileges.benefitsMatrix.confirmationModal.content",
				),
			},
		},
		returnMessages: {
			success__not_override: t(
				"old.registration.privileges.benefitsMatrix.returnMessages.success__not_override",
			),
			success_override: t(
				"old.registration.privileges.benefitsMatrix.returnMessages.success_override",
			),
		},
	};

	const handleOverrideBenefits = async () => {
		participantId &&
			(await ParticipantController.updateOverrideBenefitsActivation(
				client.id,
				event.id,
				participantId,
				{
					override: !isBenefitsMatrixOverrided,
				},
			)
				.then(() => {
					onAddTotalBenefitButtonClick();
					enqueueSnackbar(
						isBenefitsMatrixOverrided
							? translations.returnMessages.success__not_override
							: translations.returnMessages.success_override,
						{ variant: "success" },
					);
				})
				.catch((_error: any) => {
					enqueueSnackbar(_error.technicalMessage, { variant: "error" });
				}));
	};
	const handleModaleClose = () => {
		setOpenConfirmationModal(false);
	};
	const handleModaleOpen = () => {
		setOpenConfirmationModal(true);
	};

	return (
		<>
			<Stack m={1} alignItems={"flex-end"}>
				<K4Button
					label={translations.customTotalBenefits}
					startIcon={isBenefitsMatrixOverrided ? "fa-minus" : "fa-plus"}
					variant={
						isBenefitsMatrixOverrided
							? EButtonVariant.outlined
							: EButtonVariant.contained
					}
					handleClick={
						isBenefitsMatrixOverrided
							? handleModaleOpen
							: handleOverrideBenefits
					}
					color={
						isBenefitsMatrixOverrided
							? EButtonColor.error
							: EButtonColor.secondary
					}
				/>
			</Stack>

			<Dialog open={openConfirmationModal} maxWidth={"sm"} fullWidth>
				<DialogTitle id="modal-confirm-title">
					{translations.customTotalBenefits}
				</DialogTitle>
				<DialogContent>
					<Typography>{translations.modal.labels.content}</Typography>
				</DialogContent>
				<DialogActions>
					<Stack direction={"row"} mr="2rem" mb=".5rem" spacing=".8rem">
						<ButtonCancel
							label={translations.modal.buttons.cancel}
							onClick={handleModaleClose}
							sx={{ mr: 1 }}
						/>

						<ButtonDelete
							label={translations.modal.buttons.delete}
							onClick={handleOverrideBenefits}
						/>
					</Stack>
				</DialogActions>
			</Dialog>
		</>
	);
}
