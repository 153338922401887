import { alpha, Box as MuiBox, Grid, useTheme } from "@mui/material";

interface Props {
	children: JSX.Element;
	boxShadow?: boolean;
}

const AppBox = (props: Props) => {
	const theme = useTheme();
	const { children, boxShadow = true } = props;

	return (
		<MuiBox
			sx={{
				backgroundColor: "background.paper",
				boxShadow: boxShadow
					? `0 7px 26px ${alpha(theme.palette.primary.dark, 0.12)}`
					: "",
				borderRadius: 4,
				p: 2.5,
			}}
		>
			<Grid container>
				<Grid item xs={12}>
					{children}
				</Grid>
			</Grid>
		</MuiBox>
	);
};

export default AppBox;
