import update from "immutability-helper";
import React from "react";

import RoleCardDargContainer from "@application/Components/dashboard/roles/components/RoleCardDargContainer";
import Role from "@application/Components/role/Role";
import Card from "@infrastructure/components/interface/card/Card";
import type { IMenuItems } from "@infrastructure/model/interfaces/menuItems.interface";
import {
	type DataCypress,
	type DtoDashboardRole,
	type DtoRole,
	getDataCypressID,
} from "@key4-front-library/core";
import { EnumRoleScope, EnumRoleType } from "@key4-front-library/core/Enums";
import { Grid } from "@mui/material";

type Props = DataCypress & {
	role: DtoDashboardRole;
	index: number;
	isManage: boolean;
	setRoles: (roles: any) => void;
	setIsModaleOpen: (open: boolean) => void;
	setRoleToEdit: (role: DtoRole) => void;
	setPinnedRoleClicked: (role: DtoDashboardRole) => void;
	xs: number;
	sm: number;
	md: number;
	lg: number;
	xl: number;
};

const RoleCard = (props: Props) => {
	const {
		role,
		index,
		isManage,
		setRoles,
		setIsModaleOpen,
		setRoleToEdit,
		setPinnedRoleClicked,
		xs,
		sm,
		md,
		lg,
		xl,
		dataCypressID,
	} = props;

	const menuItems: IMenuItems = [
		{
			label: "Edit",
			icon: "pencil",
			dataCypressID: `${dataCypressID}-edit_button`,
			handleClick: (card: any) => {
				setRoleToEdit(card);
				setIsModaleOpen(true);
			},
		},
	];

	const displayMenuItems = isManage &&
		role.type === EnumRoleType.MANUAL && {
			menuItems,
		};

	const moveCard = React.useCallback(
		(dragIndex: number, hoverIndex: number) => {
			setRoles((prevCards: any) =>
				update(prevCards, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, prevCards[dragIndex]],
					],
				}),
			);
		},
		[setRoles],
	);

	return (
		<Grid key={role.id} item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
			{isManage ? (
				<RoleCardDargContainer
					id={role.id}
					index={index}
					role={role}
					moveCard={moveCard}
					{...displayMenuItems}
					setPinnedRoleClicked={setPinnedRoleClicked}
					dataCypressID={getDataCypressID(dataCypressID, [
						"draggable",
						"card",
						role.id,
					])}
				/>
			) : (
				<Card
					{...displayMenuItems}
					dataCypressID={getDataCypressID(dataCypressID, ["card", role.id])}
				>
					<Role
						id={role.id}
						name={role.name}
						value={role.value}
						color={role.color}
						icon={role.icon}
						scope={EnumRoleScope.PARTICIPANT}
						type={role.type}
						dataCypressID={getDataCypressID(dataCypressID, ["role", role.id])}
					/>
				</Card>
			)}
		</Grid>
	);
};

export default RoleCard;
