import { useEffect } from "react";

import ConfigurationsApp from "@application/Configurations";
import type { ParticipantInvitationStatus } from "@application/Configurations/ParticipantConfiguration";
import ParticipantController from "@application/Controllers/ParticipantController";
import {
	BulkAction,
	EBulkActionAction,
	type EBulkActionEntity,
	type EnumParticipantStatusInvitation,
	type ErrorAPI,
	type TPickForm,
	type TUseBulkActionProps,
	useBulkAction,
} from "@key4-front-library/core";
import type { GridValidRowModel } from "@mui/x-data-grid";

const dataCypressID = "bulk_action";

const BulkActionParticipant = () => {
	const cbDataGridRows = async (
		clientId: string,
		eventId: string,
	): Promise<number | ErrorAPI | Array<GridValidRowModel>> => {
		return await ParticipantController.getParticipantsListBulkActionStepSelect(
			clientId,
			eventId,
			filters?.search ?? "",
			[],
			filters?.queryBuilderString ?? "",
			t,
		);
	};

	const cbStepActionSetupEditGetForm = async (
		clientId: string,
		eventId: string,
		_activeEntity: EBulkActionEntity,
	): Promise<ErrorAPI | Array<TPickForm>> => {
		return ParticipantController.getBulkActionEditionForm(clientId, eventId);
	};

	const hookBulkAction: TUseBulkActionProps = {
		cbDataGridRows,
		cbStepActionSetupEditGetForm,
	};

	const {
		handleActiveStepChange,
		activeEntity,
		client,
		event,
		t,
		filters,
		action,
		activeStep,
		selectedItems,
		stepActionSetup,
		setStepConfirmationIsAPIProcessing,
		getStepSelectData,
		setupStepChooseAction,
		setupStepActionSetupEdit,
		setupStepActionSetupTransition,
		handleConfirmationClick,
		setupClearStepConfirmation,
	} = useBulkAction(hookBulkAction);

	// STEP 1
	useEffect(() => {
		getStepSelectData(
			client.id,
			event.id,
			ConfigurationsApp.ParticipantConfiguration.datagridHeaders,
			dataCypressID,
		);
	}, [event]);

	// STEP 2
	useEffect(() => {
		setupStepChooseAction(
			ConfigurationsApp.ParticipantConfiguration.bulkActionActions,
		);
	}, [activeEntity]);

	// STEP 3
	// STEP 3 EDIT
	useEffect(() => {
		setupStepActionSetupEdit(client.id, event.id);
	}, [activeStep, event]);

	// STEP 3 TRANSITION
	useEffect(() => {
		setupStepActionSetupTransition(
			ConfigurationsApp.ParticipantConfiguration.statusArray.map(
				(s: EnumParticipantStatusInvitation) => {
					return {
						...ConfigurationsApp.ParticipantConfiguration.statusObject[
							s as ParticipantInvitationStatus
						],
					};
				},
			),
		);
	}, [activeEntity, action, activeStep]);

	const cbConfirm = async (clientId: string, eventId: string) => {
		let response: any = null;
		switch (action) {
			case EBulkActionAction.TRANSITION:
				if (!stepActionSetup.transition.value) {
					return;
				}
				response =
					await ParticipantController.patchParticipantInvitationStatusTransitionBulkAction(
						clientId,
						eventId,
						selectedItems,
						stepActionSetup.transition.value,
					);
				break;

			case EBulkActionAction.EDIT:
			default:
				if (!stepActionSetup.edit.useFormValues) {
					return;
				}
				response = await ParticipantController.patchParticipantEditBulkAction(
					clientId,
					eventId,
					selectedItems,
					stepActionSetup.edit.useFormValues,
				);
		}
		return response;
	};
	// STEP 4
	const handleCallAPIClick = async () => {
		await handleConfirmationClick(cbConfirm);
	};

	// Clear stepConfirmation data from store when we leave step 4 page
	useEffect(() => {
		setupClearStepConfirmation();
	}, [activeStep]);

	return (
		<BulkAction
			changeActiveStep={handleActiveStepChange}
			onCallAPIClick={async () => {
				setStepConfirmationIsAPIProcessing(true);
				await handleCallAPIClick();
			}}
		/>
	);
};

export default BulkActionParticipant;
