import { t } from "i18next";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import K4ButtonExportDecorated from "@application/Components/button/K4ButtonExportDecorated";
import { PrivilegesMatrix } from "@application/Components/privileges/PrivilegesMatrix";
import CostCenterController from "@application/Controllers/CostCenterController";
import PrivilegesController from "@application/Controllers/PrivilegesController";
import AppBox from "@infrastructure/components/interface/box/AppBox";
import K4ButtonAdd from "@infrastructure/components/interface/button/K4ButtonAdd";
import { useContextModule } from "@key4-front-library/core";
import type { DtoCostCenterGet } from "@key4-front-library/core/Dto";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import type {
	GridAlignment,
	GridColumns,
	GridRenderCellParams,
} from "@mui/x-data-grid-pro";

const GetTranslations = () => {
	return {
		privilegesMatrix: {
			benefits: {
				label: t("old.registration.privileges.benefit.label"),
			},
			privileges: {
				label: t("old.registration.privileges.privilege.label"),
			},
			costCenter: {
				label: t("old.registration.privileges.costCenter.label"),
			},
			rank: {
				label: t("old.registration.privileges.rank.label"),
			},
		},
		buttons: {
			export: t("old.registration.privileges.buttons.export"),
		},
	};
};

const defaultStyleColumnsParams = {
	flex: 1,
	disableReorder: true,
	headerAlign: "center" as GridAlignment,
};

export const PagePrivilegesMatrix = () => {
	const navigate = useNavigate();
	const translations = GetTranslations();
	const { enqueueSnackbar } = useSnackbar();

	const { client, event } = useContextModule();

	const [costCenters, setCostCenters] = useState<Array<DtoCostCenterGet>>([]);
	const [
		isOpenModalPrivilegeFormCreation,
		setIsOpenModalPrivilegeFormCreation,
	] = useState(false);
	const [isOpenModalBenefitFormCreation, setIsOpenModalBenefitFormCreation] =
		useState(false);
	const [anchorRowMenu, setAnchorRowMenu] = useState<null | HTMLElement>(null);

	const dataOfEditedRowFromParent = useRef<any>(undefined);

	const pinnedToLeftColumns = useRef<GridColumns>([
		{
			...defaultStyleColumnsParams,
			field: "rank",
			headerName: translations.privilegesMatrix.rank.label,
			minWidth: 60,
			resizable: false,
			sortable: false,
			renderHeader: (params: any) => {
				return (
					<Tooltip title={params.colDef.headerName}>
						<Box fontWeight={500} className={"menuHiddenFix"}>
							{params.colDef.headerName}
						</Box>
					</Tooltip>
				);
			},
			align: "center",
			renderCell: (params: GridRenderCellParams) =>
				params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1,
			editable: false,
			type: "singleSelect",
		},
		{
			...defaultStyleColumnsParams,
			field: "Privilege",
			headerName: "Privilege",
			minWidth: 150,
			sortable: false,
			cellClassName: "column-background-dark",
			renderHeader: (params: any) => {
				return (
					<Tooltip title={params.colDef.headerName}>
						<Box fontWeight={500} className={"menuHiddenFix"}>
							{params.field}
						</Box>
					</Tooltip>
				);
			},
			renderCell(params: GridRenderCellParams) {
				return (
					<Box
						display={"flex"}
						justifyContent={"space-between"}
						alignItems={"center"}
						width={"100%"}
						title={params.value}
					>
						<Box
							onClick={() => {
								navigate(`./${params.id}`);
							}}
							sx={{
								overflow: "hidden",
								textOverflow: "ellipsis",
							}}
						>
							{params.value}
						</Box>
						<IconButton
							onClick={(_event: React.MouseEvent<HTMLButtonElement>) => {
								handleOpenRowMenu(_event);
								dataOfEditedRowFromParent.current = params;
							}}
							size={"small"}
						>
							<MoreVertIcon />
						</IconButton>
					</Box>
				);
			},
		},
		{
			...defaultStyleColumnsParams,
			field: "costCenter",
			headerName: translations.privilegesMatrix.costCenter.label,
			minWidth: 150,
			sortable: false,
			cellClassName: "column-background-light",
			renderHeader: (params: any) => {
				return (
					<Tooltip title={params.colDef.headerName}>
						<Box fontWeight={500} className={"menuHiddenFix"}>
							{params.colDef.headerName}
						</Box>
					</Tooltip>
				);
			},
			editable: false,
			type: "singleSelect",
			valueOptions: costCenters.map((costCenter) => costCenter.name),
		},
	]);

	const buttons = [
		{
			label: translations.privilegesMatrix.privileges.label,
			startIcon: "fa-plus",
			handleClick: () => {
				setIsOpenModalPrivilegeFormCreation(true);
			},
		},
		{
			label: translations.privilegesMatrix.benefits.label,
			startIcon: "fa-plus",
			handleClick: () => {
				setIsOpenModalBenefitFormCreation(true);
			},
		},
	];

	useEffect(() => {
		async function fetchCostCenters() {
			const { data } = await CostCenterController.getList(
				client.id,
				event.id,
				undefined,
				["name"],
			);
			setCostCenters([{ id: "", name: "\u00a0" }, ...data]);
		}

		fetchCostCenters();
	}, [event]);

	const handleOpenRowMenu = (_event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorRowMenu(_event.currentTarget);
	};

	const handleCloseRowMenu = () => {
		setAnchorRowMenu(null);
	};

	const handleExportPrivileges = () => {
		PrivilegesController.getExport(
			client.id,
			event.id,
			event.key,
			translations.buttons.export,
		).catch((error) => {
			enqueueSnackbar(error.message, { variant: "error" });
		});
	};

	return (
		<>
			<AppBox>
				<Stack spacing={2}>
					<Stack justifyContent={"space-between"} direction={"row"}>
						<Stack></Stack>
						<Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
							{buttons.map((button, index) => {
								return (
									<K4ButtonAdd
										key={index}
										label={button.label}
										onClick={button.handleClick}
									/>
								);
							})}
						</Stack>
					</Stack>
					<PrivilegesMatrix
						costCenters={costCenters}
						pinnedToLeftColumns={pinnedToLeftColumns.current}
						isOpenModalPrivilegeFormCreation={isOpenModalPrivilegeFormCreation}
						setIsOpenModalPrivilegeFormCreation={
							setIsOpenModalPrivilegeFormCreation
						}
						isOpenModalBenefitFormCreation={isOpenModalBenefitFormCreation}
						setIsOpenModalBenefitFormCreation={
							setIsOpenModalBenefitFormCreation
						}
						anchorRowMenu={anchorRowMenu}
						handleCloseRowMenu={handleCloseRowMenu}
						dataOfEditedRowFromParent={dataOfEditedRowFromParent}
						isRowOrdering
						isCreationMode
					/>
				</Stack>
			</AppBox>
			<Stack mt={2} direction={"row"}>
				<K4ButtonExportDecorated onClick={handleExportPrivileges} />
			</Stack>
		</>
	);
};
