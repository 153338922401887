import AuthHook from "./AuthHook";
import ParticipantHook from "./ParticipantHook";
import ProfileHook from "./ProfileHook";
import RoleHook from "./RoleHook";

const HooksApp = {
	AuthHook,
	ParticipantHook,
	ProfileHook,
	RoleHook,
};

export default HooksApp;
