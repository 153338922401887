import { t } from "i18next";
import { toPairs } from "lodash";
import * as yup from "yup";

import ConfigurationsApp from "@application/Configurations";
import {
	EnumParticipantFieldKey,
	EnumParticipantFormKey,
	EnumParticipantSectionKey,
} from "@application/Enums/ParticipantEnum";
import type { TypeParticipantStaticFieldData } from "@application/Types/ParticipantType";
import { yupResolver } from "@hookform/resolvers/yup";
import {
	CustomFieldHelper,
	type DtoParticipantGet,
	type DtoParticipantWrite,
	EnumFormControlKind,
	type EnumParticipantStatusInvitation,
} from "@key4-front-library/core";
import type { PropsFormTabs } from "@key4-front-library/core/Bo/Components/Form/FormTabs";
import type {
	TypeCustomFieldForm,
	TypeUseFormFieldValues,
	TypeUseFormListForms,
} from "@key4-front-library/core/Types";

const getResolver: any = (
	useFormData: TypeUseFormListForms | undefined,
	isEdit: boolean,
) => {
	const resolver: any = {};
	if (!useFormData) {
		return yupResolver(yup.object({}));
	}

	toPairs(useFormData).forEach((form) => {
		const formId = form[0];
		if (form[1] === undefined) {
			return;
		}
		const formResolver: any = {};
		toPairs(form[1]).forEach((section) => {
			const sectionId = section[0];
			if (section[1] === undefined) {
				return;
			}
			const sectionResolver: any = {};
			toPairs(section[1]).forEach((field) => {
				const fieldId = field[0];
				const fieldValue = field[1];
				if (EnumParticipantFieldKey.CONTACT_CONTAINER === fieldId && !isEdit) {
					sectionResolver[fieldId] = yup
						.object()
						.nullable()
						.required(t("formControl.error.required") ?? "");
				}

				CustomFieldHelper.setupResolver(
					t,
					sectionResolver,
					section[1],
					fieldId,
					fieldValue,
				);
			});

			if (Object.keys(sectionResolver).length > 0) {
				formResolver[sectionId] = yup.object(sectionResolver);
			}
		});
		if (Object.keys(formResolver).length > 0) {
			resolver[formId] = yup.object(formResolver);
		}
	});

	return yupResolver(yup.object(resolver));
};

const mapFormTabsPropsStaticFields = (
	formsData: Array<TypeCustomFieldForm>,
	componentData: PropsFormTabs,
	id?: string,
) => {
	for (let i = 0; formsData.length > i; i++) {
		const form = formsData[i];
		const sections = form.sections;

		for (let j = 0; sections.length > j; j++) {
			const section = sections[j];

			// Add static fields
			if (
				(Object.values(EnumParticipantFormKey) as Array<string>).includes(
					form.data.key,
				) &&
				(Object.values(EnumParticipantSectionKey) as Array<string>).includes(
					section.data.key,
				)
			) {
				toPairs(
					ConfigurationsApp.ParticipantConfiguration
						.staticListFormControlsObject[
						form.data.key as EnumParticipantFormKey
					][section.data.key as EnumParticipantSectionKey],
				).forEach((val: [string, TypeParticipantStaticFieldData]) => {
					let formControl = val[1].component;
					const name = [form.data.id, section.data.id, formControl.id].join(
						".",
					);
					formControl.propsComponent.label = t(
						formControl.propsComponent.label,
					);

					switch (formControl.kind) {
						case EnumFormControlKind.AUTOCOMPLETE_CONTACT_OR_PARTICIPANT:
							id && (formControl = undefined!);
							break;
						case EnumFormControlKind.SELECT_BULLET_COLOR:
							formControl.propsComponent.items =
								formControl.propsComponent.items.map((item) => {
									return {
										...item,
										label: t(item.label),
									};
								});
							break;
					}

					componentData.tabs[i].content.sections[j].formControls.push({
						...formControl,
						name,
					});
				});
			}
		}
	}
};

const mapFormTabsUseFormDefaultValue = (
	formsData: Array<TypeCustomFieldForm>,
	useFormData: TypeUseFormListForms,
) => {
	for (let i = 0; formsData.length > i; i++) {
		const form = formsData[i];
		const sections = form.sections;

		for (let j = 0; sections.length > j; j++) {
			const section = sections[j];
			if (
				(Object.values(EnumParticipantFormKey) as Array<string>).includes(
					form.data.key,
				) &&
				(Object.values(EnumParticipantSectionKey) as Array<string>).includes(
					section.data.key,
				)
			) {
				toPairs(
					ConfigurationsApp.ParticipantConfiguration
						.staticListFormControlsObject[
						form.data.key as EnumParticipantFormKey
					][section.data.key as EnumParticipantSectionKey],
				).forEach((val: [string, TypeParticipantStaticFieldData]) => {
					if (val[0] === EnumParticipantFieldKey.CONTACT_CONTAINER) {
						useFormData[form.data.id][section.data.id][
							EnumParticipantFieldKey.CONTACT_CONTAINER
						] = null;
						useFormData[form.data.id][section.data.id][
							EnumParticipantFieldKey.EMAIL
						] = null;
						useFormData[form.data.id][section.data.id][
							EnumParticipantFieldKey.LASTNAME
						] = null;
						useFormData[form.data.id][section.data.id][
							EnumParticipantFieldKey.FIRSTNAME
						] = null;
					} else {
						useFormData[form.data.id][section.data.id][val[0]] = val[1].value;
					}

					useFormData[form.data.id][section.data.id].metadata![
						val[0] as EnumParticipantFieldKey
					] = {
						visibility: true,
					};
				});
			}
		}
	}
};

const mapUseFormToDtoParticipantWrite = (
	useFormData: TypeUseFormListForms,
	participantWrite: DtoParticipantWrite,
) => {
	toPairs(useFormData).forEach((formObject) => {
		if (formObject[1] === undefined) return;
		toPairs(formObject[1]).forEach((sectionObject) => {
			if (sectionObject[1] === undefined) return;
			toPairs(sectionObject[1]).forEach((fieldObject) => {
				const fieldId = fieldObject[0];
				const fieldValue = fieldObject[1];
				// STATIC FIELDS
				switch (fieldId) {
					case EnumParticipantFieldKey.EMAIL:
						participantWrite.email = fieldValue as string | null;
						break;
					case EnumParticipantFieldKey.FIRSTNAME:
						participantWrite.firstname = fieldValue as string | null;
						break;
					case EnumParticipantFieldKey.LASTNAME:
						participantWrite.lastname = fieldValue as string | null;
						break;
					case EnumParticipantFieldKey.INVITATION_STATUS:
						participantWrite.invitationStatus =
							fieldValue as EnumParticipantStatusInvitation;
						break;
					case EnumParticipantFieldKey.PROFILE_ID:
						participantWrite.profileId = fieldValue as string | null;
						break;
					case EnumParticipantFieldKey.ROLES_IDS:
						participantWrite.roleIds = fieldValue as Array<string>;
						break;
				}

				// CUSTOM FIELDS
				if (fieldId === "customFieldValues") {
					if (!fieldValue) return;
					const newCustomFieldValues =
						CustomFieldHelper.mapUseFormToCustomfieldValues(
							fieldValue as TypeUseFormFieldValues,
							sectionObject,
						);
					// Check if the result is not null
					if (newCustomFieldValues) {
						// Initialize customFieldValues if it's null
						if (!participantWrite.customFieldValues) {
							participantWrite.customFieldValues = {};
						}

						// Merge newCustomFieldValues into customFieldValues
						Object.entries(newCustomFieldValues).forEach(([key, value]) => {
							if (value.value) {
								participantWrite.customFieldValues![key] = value;
							}
						});
					}
				}
			});
		});
	});
};

const mapFormTabsEditUseFormDefaultValue = (
	useFormData: TypeUseFormListForms,
	participant: DtoParticipantGet,
) => {
	toPairs(useFormData).forEach((formData) => {
		const formId = formData[0];
		const form = formData[1];
		toPairs(form).forEach((sectionData) => {
			const sectionId = sectionData[0];
			const section = sectionData[1];

			toPairs(section).forEach((fieldData) => {
				const fieldId = fieldData[0];
				switch (fieldId) {
					case EnumParticipantFieldKey.EMAIL:
						useFormData[formId][sectionId][fieldId] = participant.email ?? null;
						break;
					case EnumParticipantFieldKey.FIRSTNAME:
						useFormData[formId][sectionId][fieldId] =
							participant.firstname ?? null;
						break;
					case EnumParticipantFieldKey.INVITATION_STATUS:
						useFormData[formId][sectionId][fieldId] =
							participant.invitationStatus;
						break;
					case EnumParticipantFieldKey.LASTNAME:
						useFormData[formId][sectionId][fieldId] =
							participant.lastname ?? null;
						break;
					case EnumParticipantFieldKey.PROFILE_ID:
						useFormData[formId][sectionId][fieldId] = participant.profile
							? participant.profile.id
							: null;
						break;
					case EnumParticipantFieldKey.ROLES_IDS:
						useFormData[formId][sectionId][fieldId] = participant.roles
							? participant.roles.map((r) => r.id)
							: [];
						break;
				}
			});
		});
	});
};

const ParticipantHelper = {
	getResolver,
	mapFormTabsEditUseFormDefaultValue,
	mapFormTabsPropsStaticFields,
	mapFormTabsUseFormDefaultValue,
	mapUseFormToDtoParticipantWrite,
};

export default ParticipantHelper;
